import React from "react";

import NewProjPage from "../../Shared/Util/newProjPage";
import Holder from "../../Shared/Util/Holder";
import CsVer from "./Descriptions/Csharp/CsVer";
import PyVer from "./Descriptions/Python/PyVer";
import Pui from "./Descriptions/Python/Pui";
import Compa from "./Descriptions/Comparison/Compa";
import WGui from "./Descriptions/Comparison/WGui";
import WoGui from "./Descriptions/Comparison/WoGui";
import Game2048 from "./Game2048";
import GameSpec from "./GameSpec";
import CBoard from "./Descriptions/Csharp/CBoard";
import CGui from "./Descriptions/Csharp/CGui";
import GamGal from "./GameGal";
import Pgame from "./Descriptions/Python/Pgame";
import GameOver from "./GameOver";
import GamNav from "./GamNav";


class StatsPage extends React.Component{

    constructor(props){
        super(props);
        this.state ={
            color: "white",
            bg: "rgb(20,20,20)"
        }
    }

    flip(){
        if(this.state.color == "white"){
            this.setState({
                color: "rgb(20,20,20)", bg:"white"
            })
        } else {
            this.setState({
                color: "white", bg:"rgb(20,20,20)"
            })
        }

    }

    render(){
        let x = {color:this.state.color, bg:this.state.bg}
        return <NewProjPage 
            func={this.flip.bind(this)}
            childs={
                [
                    {Title: "Overview", body:new Holder(<GameOver/>,<GameOver color={"white"} bg={"rgb(20,20,20)"}/>), childs:[]},
                    {Title: "Specification", body:new Holder(<GameSpec/>,<GameSpec color={"white"} bg={"rgb(20,20,20)"}/>), childs:[]},
                    {Title: "Description", body:new Holder(<Game2048/>,<Game2048 color={"white"} bg={"rgb(20,20,20)"}/>), childs:[
                        {Title: "C# Version", body:new Holder(<CsVer/>,<CsVer color={"white"} bg={"rgb(20,20,20)"}/>), childs:[
                            {Title: "Board", body:new Holder(<CBoard/>,<CBoard color={"white"} bg={"rgb(20,20,20)"}/>), childs:[]},
                            {Title: "GUI", body:new Holder(<CGui/>,<CGui color={"white"} bg={"rgb(20,20,20)"}/>), childs:[]},
                        ]},
                        {Title: "Python Version", body:new Holder(<PyVer/>,<PyVer color={"white"} bg={"rgb(20,20,20)"}/>), childs:[
                            {Title: "Board", body:new Holder(<Pgame />,<Pgame color={"white"} bg={"rgb(20,20,20)"}/>), childs:[]},
                            {Title: "GUI", body:new Holder(<Pui/>,<Pui color={"white"} bg={"rgb(20,20,20)"}/>), childs:[]},
                        ]},
                        {Title: "Comparison", body:new Holder(<Compa/>,<Compa color={"white"} bg={"rgb(20,20,20)"}/>), childs:[
                            {Title: "With Gui", body:new Holder(<WGui/>,<WGui color={"white"} bg={"rgb(20,20,20)"}/>), childs:[]},
                            {Title: "Without Gui", body:new Holder(<WoGui/>,<WoGui color={"white"} bg={"rgb(20,20,20)"}/>), childs:[]},
                        ]},
                    ]},
                    {Title: "Gallary", body:new Holder(<GamGal/>,<GamGal color={"white"} bg={"rgb(20,20,20)"}/>), childs:[]},
                    {Title: "Links", body:new Holder(<GamNav/>,<GamNav color={"white"} bg={"rgb(20,20,20)"}/>),childs:[]}
                ]
            }
        />
    }
} export default StatsPage
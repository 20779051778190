import React from "react";
import HeadC from "../../Shared/Text/HeadC";
import HeadZ from "../../Shared/Text/HeadZ";
import TextB from "../../Shared/Text/textB";
import webB from "../../Shared/Photos/WEB/TN.png"


class WebOver extends React.Component{

    constructor(props){
        super(props);
        this.state ={
            color: props.color ? props.color: "black",
            bg: props.bg ? props.bg : "white"
        }
    }

    render(){
        return <div className="mx-3" style={{color:this.state.color, background:this.state.bg}}>
            <HeadZ color={this.state.color} bg={this.state.bg}> Website Builder CLI</HeadZ>
            <div style={{display:"grid", justifyContent:"center", alignContent:"center", color:this.state.color, background:this.state.bg}}>
            <img src={webB} style={{width:"30vw"}}/>
            </div>
            <HeadC color={this.state.color} bg={this.state.bg}> Project Goal </HeadC>
            <TextB color={this.state.color} bg={this.state.bg}>
                The goal of this project was to a CLI tool that could create custom webpages without writing any code and only interacting with intuitive commands. 
            </TextB>
            <HeadC color={this.state.color} bg={this.state.bg}> Project Outcome </HeadC>
            <TextB color={this.state.color} bg={this.state.bg}>
                The outcome of this project was I created a tool that could create and edit simple websites however the Functional of the website was quite limited due 
                to lack of javascript support and the interfaces become quite complex when dealing with css.
            </TextB>
        </div>
    }
} export default WebOver
import React from "react";
import HeadC from "../../../Shared/Text/HeadC";
import HeadZ from "../../../Shared/Text/HeadZ";
import TextB from "../../../Shared/Text/textB";
import webM from "../../../Shared/Photos/WAM/Calc.png"


class WamOver extends React.Component{

    constructor(props){
        super(props);
        this.state ={
            color: props.color ? props.color: "black",
            bg: props.bg ? props.bg : "white"
        }
    }

    render(){
        return <div className="mx-3" style={{color:this.state.color, background:this.state.bg}}>
            <HeadZ color={this.state.color} bg={this.state.bg}> Web and Mobile Applications</HeadZ>
            <div style={{display:"grid", justifyContent:"center", alignContent:"center", color:this.state.color, background:this.state.bg}}>
            <img src={webM} style={{width:"40vw"}}/>
            </div>
            <HeadC color={this.state.color} bg={this.state.bg}> Project Goal </HeadC>
            <TextB color={this.state.color} bg={this.state.bg}>
                This was a group of classes, so there wasn't really a goal other than achieving good grades. 
            </TextB>
            <HeadC color={this.state.color} bg={this.state.bg}> Project Outcome </HeadC>
            <TextB color={this.state.color} bg={this.state.bg}>
            84/100 in mobile apps and 89/100 in web applications are both high grades in their respective classes, so I am happy with both. 
            The marks that I did lose were due to careless mistakes rather than being unable to complete all work, so I have no complaints about these projects.
            </TextB>
        </div>
    }
} export default WamOver
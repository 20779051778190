import React from "react";
import HeadB from "../../../../Shared/Text/HeadB";
import HeadC from "../../../../Shared/Text/HeadC";
import TextB from "../../../../Shared/Text/textB";

class GetP extends React.Component{

  constructor(props){
    super(props);
    this.state = {
        body: "",
        color: props.color ? props.color: "black",
        bg: props.bg ? props.bg : "white"
    } 
}

    render(){
      let x = {color:this.state.color, background:this.state.bg}
      return <div style={x} className="mx-3"><HeadC color={this.state.color} bg={this.state.bg}> Get Route</HeadC><TextB color={this.state.color} bg={this.state.bg}>
        The Functions for Getting info are in the following files <a style={x} href="https://github.com/NineSixFourteen/Lol-Personal-Stat-Checker/blob/main/Lol-Personal-Stats/src/main/java/Stats/FrontEnd/GetController.java">Get</a>, <a style={x} href="https://github.com/NineSixFourteen/Lol-Personal-Stat-Checker/blob/main/Lol-Personal-Stats/src/main/java/Stats/FrontEnd/AverController.java">Average</a>, <a style={x} href="https://github.com/NineSixFourteen/Lol-Personal-Stat-Checker/blob/main/Lol-Personal-Stats/src/main/java/Stats/FrontEnd/PreController.java">PreCalculate</a>
        <HeadB color={this.state.color} bg={this.state.bg}> GET</HeadB>
        This is for retrieving information straight from the database 
        <HeadB color={this.state.color} bg={this.state.bg}> Player Matches</HeadB>
        Takes Name(String), filterType(String), filter(String) <br/>
        This function returns a list of MatchRecords(All records about a match including Match History, Match Overall and Match Intervals).
        <br/> It does this by
        <ul style={x}>
          <li style={x}> Finds Every match you are in via Match Overall 1</li>
          <li style={x}> Switch Statement upon filter type</li>
          <li style={x}> Apply Filter to list of matches</li> 
          <li style={x}> For every remaining record 
            <ul style={x}>
              <li style={x}> Get The Match ID</li>
              <li style={x}> Search all tables for every record where MatchID is the same
                <ul style={x}>
                  <li style={x}> Add all records to the MatchRecord object</li>
                </ul>
              </li>
              <li style={x}> Add Match Record to return list</li>
            </ul>
          </li>
        </ul> 
      <HeadB color={this.state.color} bg={this.state.bg}> Top played Champions </HeadB>
      Takes Name(String) <br/>
      This function returns the players top played champions
      <br/> It does this by
      <ul style={x}>
        <li style={x}> Find all Match Overall 1 records with said player</li>
        <li style={x}> Loop through all records and keep a tally of what champion they played</li> 
        <li style={x}> Find ten highest played champions and return their names</li>
      </ul>
      <HeadB color={this.state.color} bg={this.state.bg}> Champs Played</HeadB>
      Takes Name(String) <br/>
      This function returns how much a person has played any champion
      <br/> It does this by
      <ul style={x}>
        <li style={x}> Find all Match Overall 1 records with said player</li>
        <li style={x}> Loop through all records and keep a tally of what champion they played</li> 
        <li style={x}> Returm the tally </li>
      </ul>
      <HeadB color={this.state.color} bg={this.state.bg}> Match</HeadB>
      Takes MatchID(String) <br/>
      This function returns all records about a given match in the form of a MatchRecord.
      <br/>It does this by
      <ul style={x}>
        <li style={x}> Get The Match ID</li>
        <li style={x}> Search all tables for every record where MatchID is the same
          <ul style={x}>
            <li style={x}> Add all records to the MatchRecord object</li>
          </ul>
        </li>
        <li style={x}> Add Match Record to return list</li>
      </ul>
      <HeadB color={this.state.color} bg={this.state.bg}> Average </HeadB>
      This is for retrieving information that has been averaged from the many records
      <HeadB color={this.state.color} bg={this.state.bg}> Champion Games</HeadB>
      Takes PlayerName(String), Champ(String) <br/>
      This function returns a list of the average stats of a player using a champion across all games, SR games and ARAM games.
      <br/>It does this by
      <ul style={x}>
        <li style={x}> Find all Match Overall 1 and 2 Records where player name is the same </li>
        <li style={x}> For Every Record 
          <ul style={x}>
            <li style={x}> Check if the champion was playeed
              <ul style={x}>
                <li style={x}> If it then add it to the first record</li>
                <li style={x}> Check if there is a position played is blank
                  <ul style={x}> 
                    <li style={x}>If it is add to third record</li>
                    <li style={x}>If not add to second record</li>
                  </ul>
                </li>
              </ul>            
            </li>
          </ul>  
        </li> 
        <li style={x}> Return All three records</li>
      </ul>
      <HeadB color={this.state.color} bg={this.state.bg}> Player Position</HeadB>
      Takes PlayerName(String), Champ(String) <br/>
      This function returns a list of the average stats of a player for overall, SR, ARAM, and the 5 positions Top, Jungle, Mid, Adc, Sup.
      <br/>It does this by
      <ul style={x}>
        <li style={x}> Find all Match Overall 1 and 2 Records where player name is the same </li>
          <li style={x}> For Every Record 
            <ul style={x}>
              <li style={x}> Add to first Record</li>
              <li style={x}> Check if position is blank add to 1 if it is and 2 if is not</li>
              <li style={x}> Switch statement for the 5 positions that adds to correct records</li> 
            </ul>
          </li>
          <li style={x}>Return Records</li> 
        </ul>
        <HeadB color={this.state.color} bg={this.state.bg}>Player Team </HeadB>
        Takes PlayerName(String) <br/>
        This function returns a list of the average stats of a player a players Teamate for overall, SR, ARAM, and the 5 positions Top, Jungle, Mid, Adc, Sup.
        <ul style={x}> 
          <li style={x}> Find all Match Overall 1 and 2 Records where player name is the same </li>
          <li style={x}> For all records
            <ul style={x}>
              <li style={x}> Find what team they are on</li>
              <li style={x}> Find the Match Overall for every player in the game</li>
              <li style={x}> If name != {"{PlayerName}"} and team == player's team 
                <ul style={x}>
                  <li style={x}> Add to first Record</li>
                  <li style={x}> Check if position is blank add to 1 if it is and 2 if is not</li>
                  <li style={x}> Switch statement for the 5 positions that adds to correct records</li> 
                </ul>
              </li>
            </ul>
          </li>
          <li style={x}> Find </li>
        </ul>
      <HeadB color={this.state.color} bg={this.state.bg}> Stats Search</HeadB>
      Takes PlayerName(String), Champ(String), Pos(String), Gamemodes(String) <br/>
      This function returns a list of the average stats of the records that remain after the filters.
      <br/>It does this by
      <ul style={x}>
        <li style={x}> Find All Match Overall records </li>
        <li style={x}> Apply Filters </li>
        <li style={x}> Added remaing records to average Record</li>
        <li style={x}> Returned record</li> 
      </ul>
      <HeadB color={this.state.color} bg={this.state.bg}> Stats Search More </HeadB>
      Takes PlayerName(String), Champ(String), Pos(String), Gamemodes(String), Team(String),  OtherPlayerName(String), OtheChamp(String), OtherPos(String), OtherGamemodes(String),<br/>
      This function returns a list of the average stats of the records that remain after the filters.
      <ul style={x}>
        <li style={x}> Find All Match Overall records </li>
        <li style={x}> Apply Filters </li>
        <li style={x}> Extract to a list of MatchIDs from remaing records</li>
        <li style={x}> Find all Match Overall Records except ones for {"{PlayerName}"} </li>
        <li style={x}> Find every Match 
          <ul style={x}>
            <li style={x}> Apply Filters</li>
            <li style={x}> Added remaing records to average Record</li>
          </ul>
          </li> 
          <li style={x}> Return Record</li>
      </ul>
      <HeadB color={this.state.color} bg={this.state.bg}> PreCalculate</HeadB>
      This is for retrieving information that is precalucated on server start up 
      <HeadB color={this.state.color} bg={this.state.bg}> Total Stats for all records</HeadB>
      <ul style={x}>
        <li style={x}> Find all Match Overall 1 and 2 Records</li>
          <li style={x}> For Every Record 
            <ul style={x}>
              <li style={x}> Add to first Record</li>
              <li style={x}> Check if position is blank add to third record if it is and second record if is not</li>
              <li style={x}> Switch statement for the 5 positions that adds to correct records</li> 
            </ul>
          </li>
          <li style={x}>Return Records</li> 
        </ul>
      <HeadB color={this.state.color} bg={this.state.bg}> Average Stats for all records</HeadB>
      <ul style={x}>
        <li style={x}> Find all Match Overall 1 and 2 Records</li>
          <li style={x}> For Every Record 
            <ul style={x}>
              <li style={x}> Add to first Record</li>
              <li style={x}> Check if position is blank add to third average record if it is and second average record if is not</li>
              <li style={x}> Switch statement for the 5 positions that adds to correct average record</li> 
            </ul>
          </li>
          <li style={x}>Return Records</li> 
        </ul>
      </TextB>
      </div>
    }
  } export default GetP
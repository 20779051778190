import React from "react";

class HeadZ extends React.Component{

    constructor(props){
        super(props);
        this.state ={
            color: props.color ? props.color: "white",
            bg: props.bg ? props.bg: "black"
        }
    }

    render(){
    return <div className="py-3" style={{textAlign:"center",color:this.state.color, background: this.state.bg}}>
            <span style={{fontSize:"350%", fontWeight:"800", color:this.state.color, background: this.state.bg}}>
                {this.props.children}
                <br/>
            </span>
        </div>
    }

}export default HeadZ
import React from "react";
import HeadC from "../../Shared/Text/HeadC";
import TextB from "../../Shared/Text/textB";

class StatsPlan extends React.Component{

    constructor(props){
        super(props);
        this.state ={
            color: props.color ? props.color: "black",
            bg: props.bg ? props.bg : "white"
        }
    }

    render(){
        return <div className="mx-3">
            <HeadC color={this.state.color} bg={this.state.bg}> Additional Goals</HeadC>
            <TextB color={this.state.color} bg={this.state.bg}>
                Any additional goals for this project would be to improve the tooling for retrive information and to improve the some of the 
                UI to display more information.
            </TextB>
        </div>
    }
} export default StatsPlan
import React from "react";
import { Row, Col} from "react-bootstrap";
import { CopyBlock, dracula } from "react-code-blocks";
import HeadB from "../../../../Shared/Text/HeadB";
import HeadC from "../../../../Shared/Text/HeadC";
import TextB from "../../../../Shared/Text/textB";
class ProgS extends React.Component{

    constructor(props){
        super(props);
        this.state = {
            body: "",
            color: props.color ? props.color: "black",
            bg: props.bg ? props.bg : "white"
        } 
    }

    render(){
        let x = {color:this.state.color, background: this.state.bg}

        return <div style={x} className="mx-3"><TextB color={this.state.color} bg={this.state.bg}>
            <HeadB color={this.state.color} bg={this.state.bg}> Program </HeadB>
            The program structure will be what the parser returns at the end of its process; it is meant to represent a complete program once it is defined. 
            <CopyBlock
            text={
                "struct Program {\n" + 
                "   pub main    : Function,\n" + 
                "   pub methods : Vec<Function>\n" + 
                "}"
            }
            language={"java"}
            showLineNumbers={false}
            theme={dracula}
            wrapLines
            />
            <HeadB color={this.state.color} bg={this.state.bg}> Function </HeadB>
            A function structure is the representation of a function.
            All functions should have a name, return type, body of code, and parameters.            <br/>It is defined as 
            <CopyBlock
            text={
                "struct Function {\n" + 
                "   pub name   : String,\n" + 
                "   pub ty     : Primitive,\n" + 
                "   pub body   : Vec<Line>,\n" + 
                "   pub params : HashMap<String,Primitive>\n" +
                "}"
            }
            language={"java"}
            showLineNumbers={false}
            theme={dracula}
            wrapLines
            />
            <HeadB color={this.state.color} bg={this.state.bg}> Line</HeadB>
            Line is an enum that contains all the different types of lines and commands that are supported in my language.
            <br/> It is defined as 
            <CopyBlock
            text={
                "enum Line {\n" + 
                "   Print(NodeTy),\n" + 
                "   FCall(Node),\n" + 
                "   InitVar(Primitive,String,NodeTy),\n" + 
                "   OverVar(String, NodeTy),\n" +
                "   OverArray(String,Node,NodeTy),\n" + 
                "   For(Box<Line> , BoolNode, Box<Line>, Vec<Line>),\n" + 
                "   If(BoolNode, Vec<Line>,Vec<Line>),\n" + 
                "   Return(NodeTy)\n" +
                "}"
            }
            language={"java"}
            showLineNumbers={false}
            theme={dracula}
            wrapLines
            />
            The lines Listed above are 
            <ul style={x}> 
                <li style={x}> Print: Write a value to the console.</li>
                <li style={x}> Function Call: Call a function and then pop the result.</li>
                <li style={x}> Initiate Variable: Create a new variable.</li>
                <li style={x}> Overwrite Variable: - Modify the value of a variable.</li>
                <li style={x}> Overwrite Array: - Modify the value of one of an array's elements.</li>
                <li style={x}> For Loop: - Repeat the body of code within the loop until the condition is false; in the same line, you can also declare a new variable.</li>
                <li style={x}> Return: - End an function and return a value</li>
            </ul>
            <HeadB color={this.state.color} bg={this.state.bg}> Primitive</HeadB>
            Primitive is the enum representation of all of the built-in types for the language.
            <br/> It is defined as 
            <CopyBlock
            text={
                "enum Primitive  {\n" + 
                "   Int,\n" + 
                "   String,\n" + 
                "   Boolean,\n" + 
                "   Float,\n" +
                "   Char,\n" + 
                "   Char,\n" + 
                "   Array(Box<Primitive>),\n" + 
                "}"
            }
            language={"java"}
            showLineNumbers={false}
            theme={dracula}
            wrapLines
            />
            <HeadB color={this.state.color} bg={this.state.bg} > Node </HeadB>
            The node enum is for all of the different expressions that can be used in the language.
            They are divided into two categories: Boolean and Non-Boolean. 
            <br/> It is defined as 
            <CopyBlock
            text={
                "enum NodeTy{\n" + 
                "   Node(Node),\n" + 
                "   BoolNode(BoolNode)\n" +
                "}"
            }
            language={"java"}
            showLineNumbers={false}
            theme={dracula}
            wrapLines
            />
            <br/> Node is defined as 
            <CopyBlock
            text={
                "enum Node{\n" + 
                "   Add(Box<Node> , Box<Node>),\n" + 
                "   Sub(Box<Node> , Box<Node>),\n" +
                "   Mul(Box<Node> , Box<Node>),\n" + 
                "   Div(Box<Node> , Box<Node>),\n" +
                "   Leaf(String),\n" + 
                "   FCall(String, Vec<NodeTy>),\n" +
                "   GetElem(String, Box<Node>),\n" +
                "   ArrayDec(Vec<NodeTy>),\n" + 
                "   Nothing\n" +
                "}"
            }
            language={"java"}
            showLineNumbers={false}
            theme={dracula}
            wrapLines
            />
            FCall = Function Call Leaf = just (ex. Leaf(9) is just the value 9) ArrayDec = Array Declartion<br/>
            <br/> Boolean node is defined as 
            <CopyBlock
            text={
                "enum BoolNode{\n" + 
                "   LThan(Node,Node),\n" + 
                "   GThan(Node,Node),\n" +
                "   GThanEq(Node,Node),\n" + 
                "   LThanEq(Node,Node),\n" +
                "   Eq(Node,Node),\n" + 
                "   NEq(Node,Node),\n" +
                "   And(Box<BoolNode>,Box<BoolNode>),\n" +
                "   Or(Box<BoolNode>,Box<BoolNode>),\n" + 
                "   Not(Box<BoolNode>),\n" + 
                "   TFVar(String)\n" +
                "}"
            }
            language={"java"}
            showLineNumbers={false}
            theme={dracula}
            wrapLines
            />
            Neq = Not Equal 
            TFVar = True or False variable
        </TextB>
        </div>
    }
} export default ProgS
import React from "react";
import { Col, Row } from "react-bootstrap";
import HeadB from "../../../../Shared/Text/HeadB";
import HeadC from "../../../../Shared/Text/HeadC";
import TextB from "../../../../Shared/Text/textB";
class MatchHistory extends React.Component{

    constructor(props){
        super(props);
        this.state = {
            body: "",
            color: props.color ? props.color: "black",
            bg: props.bg ? props.bg : "white"
        } 
    }

    render(){
        let x = {color:this.state.color, background:this.state.bg}
        return <div className="mx-3" style={x}>
            <HeadC color={this.state.color} bg={this.state.bg}> Match History Table</HeadC>
            <TextB color={this.state.color} bg={this.state.bg}>
            The Match History Table is the table that stores information about the game that is not specific to any player, so it will create one record for every match.<br/>
            The fields in Match History are 
            <Row style={x} >
                <Col style={x}>
                <ul style={x}>
                    <li style={x}>Match ID</li>
                    <li style={x}>Game Mode</li>
                </ul>
                </Col>
                <Col style={x}>
                <ul style={x}>
                    <li style={x}>Game Length</li>
                    <li style={x}>Game Winner</li>
                </ul>
                </Col>
                <Col style={x}>
                <ul style={x}>
                    <li style={x}>Game Date</li>
                </ul>
                </Col>
            </Row>
            <br/>
            <br/>
        </TextB>
        </div>
    }
} export default MatchHistory

import React from "react";
import HeadC from "../../../Shared/Text/HeadC";
import TextB from "../../../Shared/Text/textB";
class FrontE extends React.Component{

    constructor(props){
        super(props);
        this.state = {
            body: "",
            color: props.color ? props.color: "black",
            bg: props.bg ? props.bg : "white"
        } 
    }

    render(){
        let x = {color:this.state.color, background: this.state.bg}
        return  <div style={x} className="mx-3">
        <HeadC color={this.state.color} bg={this.state.bg}> Back End</HeadC>
        <TextB color={this.state.color} bg={this.state.bg}>
            The front end of the system was required to 
            <ul style={x}>
                <li style={x}> Allow you to choose between any two metro stations.</li>
                <li style={x}> Upon selection, display the shortest path to take and show any line crossings if necessary.</li>
            </ul>
        </TextB>
        </div>
    }
} export default FrontE
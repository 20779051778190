import HeadB from "../../../Shared/Text/HeadB"
import TextB from "../../../Shared/Text/textB"
import React from "react"

class AgdaP extends React.Component{

    constructor(props){
        super(props)
        this.state = {
            body: "",
            color: props.color ? props.color: "black",
            bg: props.bg ? props.bg : "white"
        }
    }

    render(){
        let x = {color: this.state.color, background:this.state.bg}
        return <div style={x} className="mx-3">
            <HeadB color={this.state.color} bg={this.state.bg} > Agda </HeadB>
            <TextB color={this.state.color} bg={this.state.bg} >
                The notable projects I did in Agda were
                <ul style={x}>
                    <li style={x}> Building n simple image editor </li>
                    <li style={x}> Building a simple programming language
                        <ul style={x} > 
                            <li style={x}>Building a compiler to compile simple expressions to instructions sets</li>
                            <li style={x}>Building an Optimizer to Optimize Instructions sets</li>
                            <li style={x}>Building an interpreter to interpret the instructions sets</li>
                        </ul>
                    </li>
                </ul>
                <HeadB color={this.state.color} bg={this.state.bg} > Image Editor </HeadB>
                The Image Editor features were
                <ul style={x}>
                    <li style={x}> Edits PGM images </li> 
                    <li style={x}> posterization </li>
                    <li style={x}> Rotate the image </li>
                    <li style={x}> Reflect </li>
                    <li style={x}> Invert </li>
                </ul>
                <HeadB color={this.state.color} bg={this.state.bg}> Simple Programming Language </HeadB>
                The Features of the Compiler was 
                <ul style={x}>
                    <li style={x}>Types: Number, Boolean </li>
                    <li style={x}>Variables</li>
                    <li style={x}>Functions: Add, Multiply, Less Than, If Then Else</li>
                    <li style={x}>Converts the above to instructions for interpreter</li>
                </ul>
                The Features of the Interpreter
                <ul style={x}>
                    <li style={x}> Stack: Push, Pop</li>
                    <li style={x}> Memmory: Save, Load</li>
                    <li style={x}> Functions: Add, Multiply, and Compare</li>
                    <li style={x}> Other: Branch , NOOP</li> 
                    <li style={x}> Evaluation to run program and get result</li>
                </ul>
                The Features of the Optimizer
                <ul style={x}>
                    <li style={x}> Functions with non-memory-related parameters were replaced with an answer.
                        <ul style={x}>
                            <li style={x}> Add, Multiply</li>
                            <li style={x}> Less Than</li>
                            <li style={x}> If Then Else</li>
                        </ul>
                    </li>
                    <li style={x}> Remove NOOP operation </li>
                    <li style={x}> If the sequence is Save, Load, Save, then remove the first Save and Load and replace it with just the value.</li>
                    <li style={x}> If you push, then pop, then remove both instructions, as nothing would happen.</li>
                    <li style={x}> Remove the second save if you Double Save a value.</li>
                    <li style={x}> Other small optimizations</li>
                </ul>
            </TextB>
        </div>
    }
} export default AgdaP
import React from "react";
import { Card, Col, Row, Image } from "react-bootstrap";
import HonsImg from "../../Shared/Photos/Hons/HonsPhoto.png";
import code from "../../Shared/Photos/Hons/code1.png";
import out from "../../Shared/Photos/Hons/out.png";
import ImageViewer from 'react-simple-image-viewer';
import bench from "../../Shared/Photos/Hons/bench.png";

class HonsGal extends React.Component{


    constructor(props){
        super(props)
        this.state = {
            body: "", 
            color: props.color ? props.color: "black",
            bg: props.bg ? props.bg : "white",
        }
    }

    onClose(){
        this.setState({body:""})
    }

    show(num){
        console.log(this.state.ind)
        let x =             
            <ImageViewer
                src={[code,HonsImg, bench, out ] }
                currentIndex={ num}
                disableScroll={ false }
                closeOnClickOutside={ true }
                onClose={ () => this.onClose()}
            />
        this.setState({body:x})
    }

    render(){
        let b = this.state.ind;
        let x = {color:this.state.color, background:this.state.bg}
        return <>
            <Row className="py-3 px-3" style={x}>
                <Col style={x}>
                    <div 
                    style={{height:"40vh", width:"34vw",
                        backgroundImage:"url(" + code + ")", 
                        backgroundRepeat: 'no-repeat',backgroundSize:"cover"}}
                        onClick={() => this.show(0)}
                        />
                </Col>
                <Col style={x}>
                    <div 
                    style={{height:"40vh", width:"34vw",
                        backgroundImage:"url(" + HonsImg + ")", 
                        backgroundRepeat: 'no-repeat',backgroundSize:"cover"}}
                        onClick={() => this.show(1)}
                    />
                </Col>
            </Row>
            <Row style={x}>
                <Col style={x}>
                    <div 
                        style={{height:"40vh", width:"34vw",
                            backgroundImage:"url(" + bench + ")", 
                            backgroundRepeat: 'no-repeat',backgroundSize:"cover"}}
                        onClick={() => this.show(2)}
                    />
                </Col >
                <Col style={x} >
                    <div 
                        style={{height:"100vh", width:"34vw",
                            backgroundImage:"url(" + out + ")", 
                            backgroundRepeat: 'no-repeat',backgroundSize:"cover"}}
                        onClick={() => this.show(3)}
                    />
                </Col>
            </Row>
            <br/><br/><br/>
            {this.state.body}
        </>
    }

} export default HonsGal 
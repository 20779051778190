import React from "react";
import { Form, Row } from "react-bootstrap";
import Navibar from "../Shared/Util/NavBar";
import Filters from "./Filters";
import mul from "../Shared/Photos/multiDis.png" 
import sing from "../Shared/Photos/singleDis.png" 
import Plus from "../Shared/Photos/plus.png"
import { motion } from "framer-motion";
import ProjectMaker from "./ProjectMaker";

import tn from "../Shared/Photos/Hons/Thumbnail.png"
import statsW from "../Shared/Photos/Stats/Home.png"
import disB from "../Shared/Photos/Discord/kills.png"
import funcP from "../Shared/Photos/Func/TN.png"
import toyL from "../Shared/Photos/Toy/TN.png"
import webM from "../Shared/Photos/WAM/Calc.png"
import webB from "../Shared/Photos/WEB/TN.png"
import g2048 from "../Shared/Photos/2048/GameC.png"
import boston from "../Shared/Photos/Boston/Map.png"
import conN from "../Shared/Photos/ConANet/TN.png"
import home from "../Shared/Photos/Home.png"
import game from "../Shared/Photos/GameServer/LoginPage.png"



class NeProjects extends React.Component{

    constructor(props){
        super(props);
        this.state ={
            projects: [],
            projectsList: [],
            projectsDisplay: [],
            body:"",
            filters:[],
            filtersDisplay:[],
            displayType:false,
            Images:{
                "Honours Project":tn,
                "Stats Website":statsW,
                "Discord Bot":disB,
                "Functional Programming":funcP,
                "Toy Langauge":toyL,
                "Web and Mobile Development":webM,
                "WebPage Builder CLI ":webB,
                "2048(GUI, AI)":g2048,
                "Boston Metro System Path Finder":boston,
                "Concurrency and Networking":conN,
                "This":home,
                "Game Server":game,
            }, 
            categories: [
                [0,1,2,10,11],
                [1,5,6,7,10,11],
                [1,11],
                [7,8,11],
                [11],
                [0,3,4,6,9],
                [2],
                [0,4],
                [2,7]
            ]
        }
    }

    async componentDidMount(){
        let x = await this.getProjects();
        this.setState({projects:x, projectsList:x, projectsDisplay:x})
        this.draw(x);
    }

    changeCategory(num){
        let list;
        let projs; 
        if (num == 0) {
            projs = this.state.projects;
        } else {
            list = this.state.categories[num - 1];
            projs = this.getList(list);
        }
        this.setState({projectsList: projs});
        this.filterProjs(projs, this.state.filters);

    }

    getList(nums){
        let list = [];
        for(let key in this.state.Images){
            list.push(key);
        }
        let projs = [];
        nums.forEach(
            n => {
            this.state.projects.forEach(
                proj => {
                    if(proj.Title === list[n]){
                        projs.push(proj);
                    }
                })})
        return projs;
    }

    filterProjs(projs, filters){
        let display = [];
        filters = this.makeFilters(filters);
        for(let i = 0; i < projs.length;i++){
            if(this.accept(projs[i], filters)){
                display.push(projs[i]);
            }
        }
        this.setState({projectsDisplay: display})
        this.draw(display);
    }

    makeFilters(filters){
        console.log(filters)
        let ret = [[],[],[],[],[],[]];
        filters.forEach(
            filter => {
                filter.filters.forEach(
                    filt => {
                        let x = ret[filter.type].includes(filt)
                        if(!x){
                            ret[filter.type].push(filt);
                        }
                    }
                )
            }
        )
        return ret;
    }

    accept(proj, filters){ 
        if(!this.acceptH(proj.Language, filters[0])) return false
        if(!this.acceptH(proj.Type, filters[1])) return false
        if(!this.acceptH(proj.TeamSize, filters[2])) return false
        if(!this.acceptH(proj.Tools, filters[3])) return false
        if(!this.acceptH(proj.Other, filters[4])) return false
        return true
    }
    
    acceptH(actual, lookingFor){
        if(lookingFor.length == 0){
            return true;
        }
        actual = actual.split(", ");
        for(let i =0; i < actual.length; i++){
            for(let l = 0; l < lookingFor.length;l++){
                if(actual[i] == lookingFor[l]){
                    return true;
                }
            }
        }
        return false;
    }

    draw(x,z){
        if (z == undefined) {
            z = this.state.displayType; 
        }
        if(z){
            this.drawBig(x);
        } else {
            this.drawSmall(x);
        }
    }

    drawSmall(z){
        let x = [];
        z.forEach(
            proj => {
                x.push(ProjectMaker.makeSmallProject(proj, this.state.Images[proj.Title], 1.1))
            }
        )
        this.setState({body: <Row className="g-0" style={{justifyContent:"center", alignContent:"center", position:"relative" }}>{x}</Row>})
    }

    drawBig(z){
        let x = [];
        z.forEach(
            proj => {
                 x.push(ProjectMaker.makeProjectCard(proj, this.state.Images[proj.Title], 1.01))
            }
        )
        this.setState({body: <Row className="g-0" style={{position:"relative"}}>{x}</Row>})
    }

    async getProjects(){
        let list = [];
        let i = 1; 
        while(i < 13){
            let z = await this.getProject(i);
            list.push(z);
            i++;
        }
        this.setState({projectsDisplay: list});
        return list;
    }

    async getProject(x){
        let temp = await fetch('../jsons/prog' + x + '.json',
        {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        });
        let tem = await temp.json();
        return tem;
    }

    updateFilter(mode, filter, ind){
        let filt;
        let filters = this.state.filters;
        switch(mode){
            //C - Clear, A - Append, R- Remove, U - Update
            case 'C':
                filt = [];
                filters[ind] = {type:filters[ind].type, filters:[]};
                this.setState({filters: filters});
                break;
            case 'A':
                filt = this.state.filters[ind];
                filt.filters.push(filter);
                filters[ind] = filt;
                this.setState({filters: filters})
                break;
            case 'R':
                filt = this.state.filters[ind];
                filt.filters = filt.filters.filter(word => word != filter);
                filters[ind] = filt;
                this.setState({filters: filters});
                break;
            case 'U': 
                filt = {type:parseInt(filter), filters:[]}
                filters[ind] = filt;
                this.setState({filters: filters});
                break;
        }
        this.filterProjs(this.state.projectsList,filters )
    }

    deleteFilter(key){
        let fil = this.state.filters;
        let disp = this.state.filtersDisplay;
        fil = this.removeNth(key, fil);
        disp = this.removeNth(key, disp);
        this.setState({filters:fil, filtersDisplay:disp});
    }

    removeNth(ind, array){
        let ret = [];
        let i = 0;
        while(i < array.length){
            if(i != ind){
                ret.push(array[i]);
            } 
            i++;
        }
        return ret;
    }

    addFilter(){
        let filts = this.state.filters;
        filts.push({type:0, filters:[]});
        let disp = this.state.filtersDisplay;
        disp.push(<Filters ind={disp.length} delete={this.deleteFilter.bind(this)} sendUpdate={this.updateFilter.bind(this)}/>)
        this.setState({filters:filts, filtersDisplay:disp});
    }

    flipDisplay(x){
        if(this.state.displayType != x){
            this.setState({displayType: x}, this.draw(this.state.projectsDisplay, x))
        }
    }

    render(){
        let x = window.screen.height > window.screen.width;
        let color = "rgb(20,20,20)";
        let size = x ? "8vh" : "4vw";
        return <motion.div exit={{width:"0", transition:1.2, opacity:0}} >
            <Navibar/>
            <div className="m-3 mx-4 p-3" style={{border:"1px solid rgb(212, 122, 122)", flexWrap:"nowrap",position:"relative", top:"14vh"}}>
                <div style={{justifyContent:"center",display:"flex", color:"rgb(212, 122, 122)", fontSize:"5vh", border:"3px solid rgb(212,122,122)"}}>
                    <span> Filters</span>
                </div>
                    <Row className="g-0">
                        <Row>
                            {this.state.filtersDisplay.concat(
                                <motion.button 
                                    onClick={() => { 
                                        this.addFilter()
                                    }}
                                    whileHover={{scale:1.01, border:"5px solid darkblue"}}
                                    whileTap={{scale:0.95, border:"1px solid darkblue"}}
                                    className="m-1 mx-2 my-4 p-3" style={{background:color, width: x ? "80vw" : "18vw"}} >
                                    <div style={{background:color}}>
                                        <img 
                                            style={{background:color}}
                                            src={Plus}
                                            width={"70%"}
                                            height={"70%"}
                                            alt="plus"
                                        />
                                        <br/>
                                        <span style={{
                                            background:color,
                                            color:"white",
                                            fontSize:"4vh"
                                        }}> Add New Filters </span>
                                </div>
                            </motion.button>
                        )}
                        </Row>
                </Row>
            </div>
            <div className="m-3 mx-4 p-3" style={{border:"1px solid rgb(212, 122, 122)", flexWrap:"nowrap",position:"relative", top:"14vh"}}>
                <div style={{justifyContent:"center",display:"flex", color:"rgb(212, 122, 122)", fontSize:"5vh", border:"3px solid rgb(212,122,122)"}}>
                    <span> Projects</span>
                </div>
                <Row className="g-0 py-3" style={{position:"static", fontSize:"3.2vh", color:"white"}}>
                    Category: 
                    <Form.Select onChange={e => this.changeCategory(e.currentTarget.value)} className="mx-4" style={{background:color, color:"white", fontSize:"3vh", width: x ? "37vw": "15vw"}}>
                        <option value={0} style={{fontSize:"2.7vh", background:color, color:"white"}}> All</option>
                        <option value={1} style={{fontSize:"2.7vh", background:color, color:"white"}}> Favorites</option>
                        <option value={2} style={{fontSize:"2.7vh", background:color, color:"white"}}> Website</option>
                        <option value={3} style={{fontSize:"2.7vh", background:color, color:"white"}}> Web-API</option>
                        <option value={4} style={{fontSize:"2.7vh", background:color, color:"white"}}> Desktop</option>
                        <option value={5} style={{fontSize:"2.7vh", background:color, color:"white"}}> Mobile</option>
                        <option value={6} style={{fontSize:"2.7vh", background:color, color:"white"}}> CLI</option>
                        <option value={7} style={{fontSize:"2.7vh", background:color, color:"white"}}> Other Displays</option>
                        <option value={8} style={{fontSize:"2.7vh", background:color, color:"white"}}> Compilers</option>
                        <option value={9} style={{fontSize:"2.7vh", background:color, color:"white"}}> Bots</option>
                    </Form.Select>
                    { !x 
                        ? <>
                                Display: 
                                <div className="mx-2" style={{width:size, height:size}}>
                                    <img src={mul} style={{width:size, height:size, background: this.state.displayType ? "white" : "blue", border:"5px solid black", cursor:"pointer"}}
                                        onClick={() => {this.flipDisplay(false)}}
                                    />
                                </div>
                                <div className="mx-2" style={{width:size, height:size}}>
                                    <img src={sing} style={{width:size, height:size, background: this.state.displayType ? "blue" : "white", border:"5px solid black", cursor:"pointer"}}
                                        onClick={() => {this.flipDisplay(true)}}
                                    />
                                </div>
                            </>
                        :   <></>
                    }
                </Row>
                    {this.state.body}
                </div>
        </motion.div>
    }

} export default NeProjects

import React from "react";
import { Row, Container} from "react-bootstrap";
import HeadC from "../../Shared/Text/HeadC";
import TextB from "../../Shared/Text/textB";

class ToyLang extends React.Component{

    constructor(props){
        super(props);
        this.state = {
            body: "",
            color: props.color ? props.color: "black",
            bg: props.bg ? props.bg : "white"
        } 
    }

    render(){
        let x = {color:this.state.color, background: this.state.bg}
        return <div className="mx-3" style={x}>
                <Row style={x}> 
                    <HeadC color={this.state.color} bg={this.state.bg}> Description</HeadC>
                </Row>
                <Row style={x}>
                    <TextB color={this.state.color} bg={this.state.bg}>
                    This project was to create a small programming language in Rust. I chose to build the project in Rust because of its ENUM types, which allow you to store other types inside enums and then unpack them in switch statements.
                    The Enum type in Rust lends itself effectively to writing tree-like structures in a clear and easy-to-understand manner,
                    which makes using patterns such as the visitor pattern or traversing the tree easier, as you can have a "pattern match" 
                    like pattern for the different enum types in a switch statement.
                    There are other benefits to using Rust when writing a programming language,
                    such as the Result Type, which allows you to write parsers in a much more clean style thanks to syntax such as the ? syntax 
                    for the result type.
                    </TextB>
                </Row>
        </div>
    }
} export default ToyLang
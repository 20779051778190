import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Checklist from "../../Shared/Util/CheckList";
import HeadC from "../../Shared/Text/HeadC";
import HeadS from "../../Shared/Text/HeadS";
import Tags from "../../Shared/Util/Tags";
import TextB from "../../Shared/Text/textB";
import TagFactory from "../../Shared/Util/TagFactory";

class GameSpec extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            body: "",
            color: props.color ? props.color: "black",
            bg: props.bg ? props.bg : "white"
        } 
    }
    
    render(){
        let x = {color:this.state.color, background:this.state.bg}
        return <div className="mx-3">
                <TextB color={this.state.color} bg={this.state.bg}>
                    <HeadC color={this.state.color} bg={this.state.bg}> Specifiation</HeadC>
                    <HeadS color={this.state.color} bg={this.state.bg}> Final Goal</HeadS>
                    To build a version of 2048 with an AI and GUI.
                    <HeadS color={this.state.color} bg={this.state.bg}> Technoloogy Used</HeadS>
                </TextB> 
                <Row style={x}> 
                    <Col style={x} sm={2}><TextB color={this.state.color} bg={this.state.bg}> Project Type : </TextB></Col>
                    <Col style={x}><Row style={x}>{TagFactory.makeTags("t", "University, Personal").concat(TagFactory.makeTags("ts", "Solo"))} </Row> </Col>
                </Row>
                <Row style={x}> 
                    <Col style={x} sm={2}><TextB color={this.state.color} bg={this.state.bg}> Language : </TextB></Col>
                    <Col style={x}><Row style={x}>{TagFactory.makeTags("l", "C#, Python")} </Row></Col>
                </Row>
                <Row style={x}> 
                    <Col style={x} sm={2}><TextB color={this.state.color} bg={this.state.bg}> Technoloogy used : </TextB></Col>
                    <Col><Row style={x}>{TagFactory.makeTags("to","Git, Tkinter, Razor")} </Row></Col>
                </Row>
                    <TextB color={this.state.color} bg={this.state.bg}>
                    <HeadS color={this.state.color} bg={this.state.bg}> Goals</HeadS>
                    <Checklist 
                    name = "Project Goals"
                    items={
                        [
                            {done:true, message:"Build the 2048 Game."},
                            {done:true, message:"Build an AI to play the game." },
                            {done:true, message:"Build a GUI for the game."},
                            {done:true, message:"Remake project in C# to compare them."},
                        ]
                    }/>
                    <br/>
                    <Checklist 
                    name = "Game"
                    items={
                        [
                            {done:true, message:"Allows for movement in all directions."},
                            {done:true, message:"Title collision."},
                            {done:true, message:"System for keeping score, detecting movement, and determining when the game is over."},
                        ]
                    }/>
                    <br/>
                    <Checklist 
                    name = "AI"
                    items={
                        [
                            {done:true, message:"Implement an Expectimax algorithm for the game."},
                            {done:true, message:"Create a function to score each board."},
                            {done:true, message:"Optimise."},
                        ]
                    }/><br/>
                    <Checklist 
                    name = "GUI"
                    items={
                        [
                            {done:true, message:"Display Board."},
                            {done:true, message:"Allow for screen resizing."},
                            {done:true, message:"Add movement, undo/redo, and AI controls to the GUI."},
                            {done:true, message:"Add Scoreboard."},
                        ]
                    }/>
                    <br/>
                    <Checklist 
                    name = "Remaking in C#"
                    items={
                        [
                            {done:true, message:"Build the 2048 Game."},
                            {done:true, message:"Build an AI to play the game." },
                            {done:true, message:"Add concurrency to the program." },
                            {done:true, message:"Build a GUI using Razor."},
                        ]
                    }/>
                    <br/>
                </TextB>
        </div>
    }
} export default GameSpec 
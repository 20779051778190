import React from "react"
import HeadB from "../../../Shared/Text/HeadB"
import TextB from "../../../Shared/Text/textB"

class HaskellP extends React.Component{

    constructor(props){
        super(props)
        this.state = {
            body: "",
            color: props.color ? props.color: "black",
            bg: props.bg ? props.bg : "white"
        }
    }

    render(){
        let x = {color: this.state.color, background:this.state.bg}
        return <div style={x} className="mx-3">
            <HeadB color={this.state.color} bg={this.state.bg} > Haskell </HeadB>
            <TextB color={this.state.color} bg={this.state.bg} >
            My most notable Haskell projects were
            <ul style={x}>
                <li style={x}> Building a simple text editor </li>
                <li style={x}> Building a simple image Generator</li>
                <li style={x}> Building a Database-like Program
                    <ul style={x}> 
                        <li style={x}>Building a parser to parse a CSV file</li>
                        <li style={x}>Building a query language that could be applied parsed data</li>
                        <li style={x}>Building a CLI for the program to make using the parser and query language easier</li>
                    </ul>
                </li>
            </ul>
            <br/>
            <HeadB color={this.state.color} bg={this.state.bg} > Simple Text Editor</HeadB>
            The Text Editor was a simple version of Vim where you entered commands to navigate and edit the text. 
            The commands we implemented were
            <ul style={x}>
                <li style={x}> Move Left and Right</li>
                <li style={x}> Insert    </li>
                <li style={x}> Overide   </li>
                <li style={x}> Delete    </li>
                <li style={x}> Backspace </li>
                <li style={x}> Quit      </li>
            </ul>
            These commands were mapped to keys.
            <HeadB color={this.state.color} bg={this.state.bg} > Simple Image Generator</HeadB>
            The features of the graphic library are
            <ul style={x}>
                <li style={x}> Create a BMP image </li>
                <li style={x}> Represent each colour as RGBA</li>
                <li style={x}> Shapes are drawn by 
                    <ul style={x}>
                        <li style={x}> Creating "Boolean Masks"</li>
                        <li style={x}> Cuts Images out based on boolean values</li> 
                        <li style={x}> Has ability to generate boolean masks for shapes 
                            <ul style={x}>
                                <li style={x}> Circle</li>
                                <li style={x}> Square</li>
                                <li style={x}> Rectangle</li>
                                <li style={x}> Everywhere</li>
                            </ul>
                        </li>
                        <li style={x}> Combine images by using And, Or and Not on Masks</li>
                    </ul>
                </li>
                <li style={x}> Photos can be edited using 
                    <ul style={x}>
                        <li style={x}> Rotate</li>
                        <li style={x}> Scale</li>
                        <li style={x}> Fade</li>
                    </ul>
                </li> 
            </ul>
            <HeadB color={this.state.color} bg={this.state.bg} > Database </HeadB>
            The parser's features where 
            <ul style={x}>
                <li style={x}> Create a Table from a CSV file</li>
            </ul>
            The Query Language includes features such as
            <ul style={x}>
                <li style={x}> A parser to parse strings to queries</li>
                <li style={x}> Basic Arthemetic: +,-,*,/</li>
                <li style={x}> Basic Logic: ==,{"<,>"}</li>
                <li style={x}> Basic Functions: IntToString, StringToInt and PrefixIs</li>
                <li style={x}> Abilty to look up records</li>
                <li style={x}> Filter the records based on above features</li>
            </ul>
            The command line interface's features included
            <ul style={x}>
                <li style={x}> File Selection from the Command Line
                    <ul style={x}> 
                        <li style={x}> Abilty to traverse and view files on the computer to find the one you want to parse</li>
                    </ul>
                </li>
                <li style={x}> Interfaces to make querying the Tables easier
                    <ul style={x}>
                        <li style={x}> Abilty to view and select fields</li>
                        <li style={x}> Select the logic you want to use to filter records</li>
                        <li style={x}> Informing the user if the value they are attempting to compare contains errors</li>
                    </ul>
                </li>
                <li style={x}> A built-in user manual with instructions on how to use the query language and its available features.</li>
                <li style={x}> There are several ways to view the data, whether in a table or as result data.
                    <ul style={x}>
                        <li style={x}> Data Dumb</li>
                        <li style={x}> Formatted table display</li>
                    </ul>
                </li>
                <li style={x}> Ability to write results from the query to their own CSV file</li>
            </ul>
        </TextB>
        </div>
    }
} export default HaskellP
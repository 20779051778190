import React from "react";

import NewProjPage from "../../Shared/Util/newProjPage";
import Holder from "../../Shared/Util/Holder";
import BMSpec from "./BMSpec";
import BMS from "./BMS";
import BackE from "./Descriptions/BackE";
import FrontE from "./Descriptions/FrontE";
import BMGal from "./BMGal";
import BMOver from "./BMOver";

class BMPage extends React.Component{

    constructor(props){
        super(props);
        this.state ={
            color: "white",
            bg: "rgb(20,20,20)"
        }
    }

    flip(){
        if(this.state.color == "white"){
            this.setState({
                color: "rgb(20,20,20)", bg:"white"
            })
        } else {
            this.setState({
                color: "white", bg:"rgb(20,20,20)"
            })
        }

    }

    render(){
        return <NewProjPage 
            func={this.flip.bind(this)}
            childs={
                [
                    {Title: "Overview", body:new Holder(<BMOver/>,<BMOver color={"white"} bg={"rgb(20,20,20)"}/>), childs:[]},
                    {Title: "Specification", body:new Holder(<BMSpec/>,<BMSpec color={"white"} bg={"rgb(20,20,20)"}/>), childs:[]},
                    {Title: "Description", body:new Holder(<BMS/>,<BMS color={"white"} bg={"rgb(20,20,20)"}/>), childs:[
                        {Title: "Back End", body:new Holder(<BackE/>,<BackE color={"white"} bg={"rgb(20,20,20)"}/>), childs:[]},
                        {Title: "Front End", body:new Holder(<FrontE/>,<FrontE color={"white"} bg={"rgb(20,20,20)"}/>), childs:[]},
                    ]},
                    {Title: "Gallary", body:new Holder(<BMGal/>,<BMGal color={"white"} bg={"rgb(20,20,20)"}/>), childs:[]},
                ]
            }
        />
    }
} export default BMPage
import React from "react"
import { Col, Row } from "react-bootstrap";
import TextB from "../../../../Shared/Text/textB";
import HeadB from "../../../../Shared/Text/HeadB";
import HeadC from "../../../../Shared/Text/HeadC";

class MatchOverall extends React.Component{

    constructor(props){
        super(props);
        this.state = {
            body: "",
            color: props.color ? props.color: "black",
            bg: props.bg ? props.bg : "white"
        } 
    }

    render(){
        let x = {color:this.state.color, background:this.state.bg}
        return <div style={x} className="mx-3">
            <HeadC color={this.state.color} bg={this.state.bg}> Match Overall Table</HeadC>
            <TextB color={this.state.color} bg={this.state.bg}>
            The Match Overall Tables contain information about each player in a match; they store the information about a player at the end of a game, 
            so it will create ten of each record for every match. As there are a lot of fields, the overall table is split into two tables, 
            with the first having the more important stats and the second having the stats that are more for curiosity.<br/><br/>
                The fields in first Match Overall Table are <br/>
                <Row style={x}>
                    <Col style={{marginLeft:"11%"}}>
                    <ul style={x}>
                        <li style={x}>Match Id</li>
                        <li style={x}>Position</li>
                        <li style={x}>Kills</li>
                        <li style={x}>Gold</li>
                        <li style={x}>Damage Dealt</li>
                        <li style={x}>Turret Damage</li>
                        <li style={x}>Objective Steals</li>
                    </ul>
                    </Col>
                    <Col style={x}>
                    <ul style={x}>
                        <li style={x}>Name</li>
                        <li style={x}> Team</li>
                        <li style={x}>Deaths</li>
                        <li style={x}>Wards Placed</li>
                        <li style={x}>Damage Taken</li>
                        <li style={x}>Dragons Taken</li>
                        <li style={x}>Vision Score</li>
                    </ul>
                    </Col>
                    <Col style={x}>
                    <ul style={x}>
                        <li style={x}>Champion</li>
                        <li style={x}>CS</li>
                        <li style={x}>Assists</li>
                        <li style={x}>Wards Destroyed</li>
                        <li style={x}>Turrets Taken</li>
                        <li style={x}>Barons Taken</li>
                    </ul>
                    </Col>
                </Row>
                <br/>
                The fields in second Match Overall Table are <br/>
                <Row style={x}>
                    <Col style={{marginLeft:"11%"}}>
                    <ul style={x}>
                        <li style={x}>Match ID</li>
                        <li style={x}>Bounty Level</li>
                        <li style={x}>Control Wards Placed</li>
                        <li style={x}>Hit Skill Shots</li>
                        <li style={x}>Number of times CC'd an enemy </li>
                        <li style={x}>Peak CS Lead</li>
                        <li style={x}>Solo Kills</li>
                        <li style={x}>First Blood Kill</li>
                        <li style={x}>Heal Teamates</li>
                    </ul>
                    </Col>
                    <Col style={x}>
                    <ul style={x}>
                        <li style={x}>Name</li>
                        <li style={x}>Abilities Used</li>
                        <li style={x}>Control Wards Bought</li>
                        <li style={x}>Jungle Monsters Killed</li>
                        <li style={x}>Skill Shots Hit Early Game</li>
                        <li style={x}>Peak Kill Lead</li>
                        <li style={x}>Stealth Wards Placed</li>
                        <li style={x}>First Blood Assist</li>
                        <li style={x}>Sheild Teamates</li>
                    </ul>
                    </Col>
                    <Col style={x}>
                    <ul style={x}>
                        <li style={x}>Pings</li>
                        <li style={x}>Stolen Buffs</li>
                        <li style={x}>Dodged Skill Shots</li>
                        <li style={x}>Enemy Jungle Monsters Killed</li>
                        <li style={x}>Lane Minions Killed at 10 minutes</li>
                        <li style={x}>Number of Pick Kills with an ally</li>
                        <li style={x}>Turret Plates Taken</li>
                        <li style={x}>First Blood Tower</li>
                    </ul>
                    </Col>
                </Row>
                <br/>
            </TextB>
        </div>
    }
} export default MatchOverall

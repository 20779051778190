import React from "react";
import Tag from "./Tag";
class TagFactory {

    static type(message){
        let colour;
        let bgColour;
        switch(message){
            case "University":
                bgColour = "rgba(30, 6, 87)";
                colour = "white";
                break;
            case "Personal":
                bgColour = "rgb(171, 87, 3)";
                colour = "white";
                break;
            case "undefined":
                return;
            default:
        }
        return <Tag message={message} colour={colour} bgColour={bgColour} key={bgColour}/>
    }

    static category(message){
        let colour = "white";
        let bgColour;
        switch(message){
            case "Website":
                bgColour = "rgba(22, 14, 31)";
                break;
            case "Hosted":
                bgColour = "rgb(84, 19, 63)";
                break;
            case "Desktop":
                bgColour = "rgb(31, 14, 14)";
                break;
            case "Web-API":
                bgColour = "rgb(31, 14, 22)";
                break;
            case "Mobile":
                bgColour = "rgb(171, 87, 3)";
                break;
            case "CLI":
                bgColour = "rgb(171, 87, 3)";
                break;
            case "Bot":
                bgColour = "rgb(84, 107, 104 )";
                break;
            case "Favorite":
                bgColour = "rgb(63, 186, 108)";
                break;
            case "Compiler":
                bgColour = "rgb(14, 18, 31)";
                break;
            case "Concurrency":
                bgColour = "rgb(200, 88, 31)";
                break;
            case "undefined":
                return;
            default:
        }
        return <Tag message={message} colour={colour} bgColour={bgColour} key={bgColour}/>
    }

    static teamsize(message){
        let colour;
        let bgColour;
        switch(message){
            case "Solo":
                bgColour = "rgb(12, 135, 104)";
                colour = "white";
                break;
            case "Medium":
                bgColour = "rgb(16, 38, 54)";
                colour = "white";
                break;
            case "undefined":
                return;
            default:
        }
        return <Tag message={message} colour={colour} bgColour={bgColour} key={bgColour}  />
    }

    static language(message){
        console.log("{P} + " + message)
        let colour = "white";;
        let bgColour;
        switch(message){
            case "Java":
                bgColour = "rgb(237, 121, 5)";
                break;
            case "Rust":
                bgColour = "rgb(61, 51, 45)";
                break;
            case "JavaScript":
                bgColour = "rgb(212, 174, 51)";
                break;
            case "Python":
                bgColour = "rgb(6, 24, 156)";
                break;
            case "Haskell":
                bgColour = "rgb(77, 71, 66)";
                break;
            case "Agda":
                bgColour = "rgb(3, 9, 43)";
                break;
            case "C#":
                bgColour = "rgb(38, 204, 8";
                break;
            case "Dart":
                bgColour = "rgb(0, 0, 0)";
                break;
            case "undefined":
                return;
            default:
        }
        return <Tag message={message} colour={colour} bgColour={bgColour} key={bgColour} />
    }

    static tool(message){
        let colour  = "white";;
        let bgColour;
        switch(message){
            case "MySql":
                bgColour = "rgb(66, 135, 245)";
                break;
            case "Git":
                bgColour = "rgb(74, 232, 2)";
                break;
            case "Maven":
                bgColour = "rgb(237, 24, 5)";
                break;
            case "LaTeX":
                bgColour = "rgb(154, 179, 252)";
                break;
            case "Spring Boot":
                bgColour = "rgb(132, 7, 173)";
                break;
            case "React":
                bgColour = "rgb(219, 4, 105)";
                break;
            case "ASM":
                bgColour = "rgb(232, 116, 139)";
                break;
            case "Ubuntu":
                bgColour = "rgb(117, 3, 128)";
                break;
            case "JDA":
                bgColour = "rgb(77, 2, 29)";
                break;
            case "Riot API":
                bgColour = "rgb(7, 46, 6)";
                break;
            case "Tkinter":
                bgColour = "rgb(56, 3, 22)";
                break;
            case "Swing":
                bgColour = "rgb(46, 33, 4)";
                break;
            case "Flutter":
                bgColour = "rgb(85, 131, 212)";
                break;
            case "AWS":
                bgColour = "rgb(204, 112, 41)";
                break;
            case "Nginx":
                bgColour = "rgb(36, 42, 48)";
                break;
            case "Linode":
                bgColour = "rgb(0, 135, 23)";
                break;
            case "Razor":
                bgColour = "rgb(219, 11, 146)";
                break;
            default:
                break;
                case "undefined":
            case "None":
                return;
           
        }
        return <Tag message={message} colour={colour} bgColour={bgColour} key={bgColour} />
    }

    static makeTags(type,message){
        let ret = [];
        let messages = message.split(", ");
        switch(type.toLowerCase()){
            case "type":
            case "t":
                messages.forEach(
                    message => {
                        ret.push(this.type(message));
                    }
                )
                break;
            case "category":
            case "c":
                messages.forEach(
                    message => {
                        ret.push(this.category(message));
                    }
                )
                break;
            case "tools":
            case "to":
                messages.forEach(
                    message => {
                        ret.push(this.tool(message));
                    }
                )
                break;
            case "team size":
            case "ts":
                messages.forEach(
                    message => {
                        ret.push(this.teamsize(message));
                    }
                )
                break;
            case "language":
            case "l":
                messages.forEach(
                    message => {
                        ret.push(this.language(message));
                    }
                )
                break;
            default:
        }
        let rett = [];
        let i =0;
        while(i < ret.length){
            if(ret[i] != undefined){
                rett.push(ret[i]);
            }
            i++;
        }
        return rett;
    }

} export default TagFactory
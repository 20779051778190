import React from "react";

import NewProjPage from "../../Shared/Util/newProjPage";
import Holder from "../../Shared/Util/Holder";
import RPars from "./Descriptions/Parser/RPars";
import RComp from "./Descriptions/Compiler/RComp";
import RStack from "./Descriptions/StackMachine/RStack";
import ProgS from "./Descriptions/Parser/ProgS";
import Tokens from "./Descriptions/Parser/Token";
import Errors from "./Descriptions/Parser/ProgE";
import RInstr from "./Descriptions/StackMachine/RInstr";
import REval from "./Descriptions/StackMachine/REval";
import RTypes from "./Descriptions/StackMachine/RTypes";
import ToySpec from "./ToySpec";
import ToyGal from "./ToyGal";
import ToyLang from "./ToyLang";
import ToyOver from "./ToyOver";
import ToyPlan from "./ToyPlan";
import ToyNav from "./ToyNav";


class ToyPage extends React.Component{

    constructor(props){
        super(props);
        this.state ={
            color: "white",
            bg: "rgb(20,20,20)"
        }
    }

    flip(){
        if(this.state.color == "white"){
            this.setState({
                color: "rgb(20,20,20)", bg:"white"
            })
        } else {
            this.setState({
                color: "white", bg:"rgb(20,20,20)"
            })
        }

    }

    render(){
        let x = {color:this.state.color, background: this.state.bg}
        return <NewProjPage 
            func={this.flip.bind(this)}
            childs={
                [
                    {Title: "Overview", body:new Holder(<ToyOver/>,<ToyOver color={"white"} bg={"rgb(20,20,20)"}/>), childs:[
                        {Title: "Future Goals",body:new Holder(<ToyPlan/>,<ToyPlan color={"white"} bg={"rgb(20,20,20)"}/>), childs:[]},
                    ]},
                    {Title: "Specification", body:new Holder(<ToySpec/>,<ToySpec color={"white"} bg={"rgb(20,20,20)"}/>), childs:[]},
                    {Title: "Description", body:new Holder(<ToyLang/>,<ToyLang color={"white"} bg={"rgb(20,20,20)"}/>), childs:[
                        {Title: "Parser", body:new Holder(<RPars/>,<RPars color={"white"} bg={"rgb(20,20,20)"}/>), childs:[
                            {Title: "Program Structure", body:new Holder(<ProgS/>,<ProgS color={"white"} bg={"rgb(20,20,20)"}/>), childs:[]},
                            {Title: "Tokens", body:new Holder(<Tokens/>,<Tokens color={"white"} bg={"rgb(20,20,20)"}/>), childs:[]},
                            {Title: "Errors", body:new Holder(<Errors/>,<Errors color={"white"} bg={"rgb(20,20,20)"}/>), childs:[]},
                        ]},
                        {Title: "Compiler", body:new Holder(<RComp/>,<RComp color={"white"} bg={"rgb(20,20,20)"}/>), childs:[]},
                        {Title: "Stack Machine", body:new Holder(<RStack/>,<RStack color={"white"} bg={"rgb(20,20,20)"}/>), childs:[
                            {Title: "Types and Errors", body:new Holder(<RTypes/>,<RTypes color={"white"} bg={"rgb(20,20,20)"}/>), childs:[]},
                            {Title: "Instruction Set", body:new Holder(<RInstr/>,<RInstr color={"white"} bg={"rgb(20,20,20)"}/>), childs:[]},
                            {Title: "Evaluator", body:new Holder(<REval/>,<REval color={"white"} bg={"rgb(20,20,20)"}/>), childs:[]},
                        ]},
                    ]},
                    {Title: "Gallary", body:new Holder(<ToyGal/>,<ToyGal color={"white"} bg={"rgb(20,20,20)"}/>), childs:[]},
                    {Title: "Links", body:new Holder(<ToyNav/>,<ToyNav color={"white"} bg={"rgb(20,20,20)"}/>),childs:[]}
                ]
            }
        />
    }
} export default ToyPage
import React from "react";
import TextB from "../../../../Shared/Text/textB";
import { Player } from "video-react";
import Vis from "../../../../Shared/Videos/2048/Vis.mp4" 
import "../../../../../node_modules/video-react/dist/video-react.css";
import HeadC from "../../../../Shared/Text/HeadC";
<link rel="stylesheet" href="/css/video-react.css" />

class WGui extends React.Component{

    constructor(props){
        super(props);
        this.state = {
            body: "",
            color: props.color ? props.color: "black",
            bg: props.bg ? props.bg : "white"
        } 
    }
    
    render(){
        let x = {color:this.state.color, background:this.state.bg}
        return <div style={x} className="mx-3"><HeadC color={this.state.color} bg={this.state.bg}> With GUI</HeadC><TextB color={this.state.color} bg={this.state.bg}>
            The GUI test is more of a visual test, so I won't compare the numbers,
            as I don't believe that my skill level in developing the Python GUI is comparable to building a website version. 
            Below is a video showing the difference between the Python and C# GUI.
            <br/><br/>
            <Player
                playsInline
                src={Vis}
            />
            Completion Time <br/>
            C# : 3 Minutes 17 Seconds<br/>
            Python : 9 Minutes 25 Seconds  <br/>
            <br/>
            <br/>
        </TextB>
        </div>
    }
} export default WGui
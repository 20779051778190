import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Checklist from "../../Shared/Util/CheckList";
import HeadB from "../../Shared/Text/HeadB";
import HeadC from "../../Shared/Text/HeadC";
import HeadS from "../../Shared/Text/HeadS";
import Tags from "../../Shared/Util/Tags";
import TextB from "../../Shared/Text/textB";
import TagFactory from "../../Shared/Util/TagFactory";


class HonSpec extends React.Component{

    constructor(props){
        super(props);
        this.state ={
            color: props.color ? props.color: "black",
            bg: props.bg ? props.bg : "white",
        }
    }
    render(){
        let x = {color:this.state.color, background: this.state.bg}
        return <div className="px-3" style={x}>
                <TextB color={this.state.color} bg={this.state.bg}>
                    <HeadC color={this.state.color} bg={this.state.bg}> Specifiation</HeadC>
                    <HeadS color={this.state.color} bg={this.state.bg}> Final Goal</HeadS>
                    To build a new programming language that would compile down to Java bytecode and write my dissertation about it.
                    <HeadS color={this.state.color} bg={this.state.bg}> Technoloogy Used</HeadS>
                </TextB> 
                <Row style={x}> 
                    <Col style={x} sm={2}><TextB color={this.state.color} bg={this.state.bg}> Project Type : </TextB></Col>
                    <Col style={x}><Row style={x}>{TagFactory.makeTags("t", "University").concat(TagFactory.makeTags("ts", "Solo"))} </Row> </Col>
                </Row>
                <Row style={x}> 
                    <Col style={x} sm={2}><TextB color={this.state.color} bg={this.state.bg}> Language : </TextB></Col>
                    <Col style={x}><Row style={x}>{TagFactory.makeTags("l", "Java")} </Row></Col>
                </Row>
                <Row style={x}> 
                    <Col style={x} sm={2}><TextB color={this.state.color} bg={this.state.bg}> Technoloogy used : </TextB></Col>
                    <Col><Row style={x}>{TagFactory.makeTags("to", "ASM, Maven, Git ")} </Row></Col>
                </Row>
                    <TextB color={this.state.color} bg={this.state.bg}>
                    <HeadS color={this.state.color} bg={this.state.bg}> Goals</HeadS>
                    <Checklist 
                    name = "Project Goals"
                    items={
                        [
                            {done:true, message:"Create a programming language."},
                            {done:true, message:"Write dissertation."},
                        ]
                    }/>
                    <br/>
                    <Checklist 
                    name = "Programming Language"
                    items={
                        [
                            {done:true, message:"Create a parser."},
                            {done:true, message:"Create a complier."},
                        ]
                    }/>
                    <br/>
                    <Checklist 
                    name = "Parser"
                    items={
                        [
                            {done:true, message:"Text to tree structure parsing."},
                            {done:true, message:"Parse Expressions."},
                            {done:true, message:"Parse Lines."},
                            {done:true, message:"Parse Functions."},
                            {done:true, message:"Parse Classes."},
                        ]
                    }/>
                    <br/>
                    <Checklist 
                    name = "Compiler"
                    items={
                        [
                            {done:true, message:"Compile parsed objects into Java bytecode."},
                            {done:true, message:"Compile Expressions."},
                            {done:true, message:"Compile Lines."},
                            {done:true, message:"Compile Functions."},
                            {done:true, message:"Compile Classes."},
                        ]
                    }/>
                    <br/>
                    <Checklist 
                    name = "Adding Unique Features"
                    items={
                        [
                            {done:true, message:"Define Syntax: ConstExp."},
                            {done:true, message:"New Array Type: Flat Arrays."}
                        ]
                    }/>
                    <br/>
                </TextB>
        </div>
    }
} export default HonSpec 
import React from "react";
import { Card, Collapse, Row} from "react-bootstrap";
import Arrow from "../Photos/Arrow.png"


class GalleryFolder extends React.Component{

    constructor(props){
        super(props);
        this.state = {
            isDark: props.isDark,
            child: props.child,
            Title: props.Title,
            show: false,
            rot: "90deg"
        }
    }

    flipShow(){
        let x = !this.state.show
        let rot = "90deg";
        if(x){
            rot = "180deg";
        }
        this.setState({show:x,rot:rot})
    }

    render(){
        let bg = "rgba(50,50,50,0.7)";
        return <div className="mx-3" style={{background:bg}}> 
            <Card className="my-3" style={{background:bg,color:"white"}}>
                <Card.Header onClick={()=>this.flipShow()} style={{background:bg, color:"white",fontSize:"190%", fontWeight:"700"}}>
                    <img src={Arrow} width="2%" style={{rotate: this.state.rot, background:bg}} alt="An Arrow" />{this.state.Title}
                </Card.Header>
                 <Collapse in={this.state.show}>
                    <Row className="py-2 mx-2" style={{background:bg,color:"white"}}>
                    {this.state.child}
                    </Row>
                </Collapse>
            </Card>
        </div>
        
    }
} export default GalleryFolder
import React from "react";
import { Col, Row } from "react-bootstrap";
import HeadS from "../../Shared/Text/HeadS";
import TextB from "../../Shared/Text/textB";

class SerNav extends React.Component{
    constructor(props){
        super(props);
        this.state ={
            color: props.color ? props.color: "black",
            bg: props.bg ? props.bg : "white"
        }
    }


    render(){
        let x = {color:this.state.color, background:this.state.bg}
        return <div style={{textAlign:"center"}}>
        <TextB color={this.state.color} bg={this.state.bg}>
            <HeadS color={this.state.color} bg={this.state.bg}> Project on <a style={x} href="https://github.com/NineSixFourteen/Game-Server">Github</a></HeadS>
            <Row style={x}>
                <Col style={x}>
                    <HeadS color={this.state.color} bg={this.state.bg}> API </HeadS>
                        <a style={x} href="https://github.com/NineSixFourteen/Game-Server/tree/main/src/backend/GameApi/Controllers"> Controllers </a> <br/>
                        <a style={x} href="https://github.com/NineSixFourteen/Game-Server/tree/main/src/backend/GameApi/Models"> Models  </a> <br/>
                        <a style={x} href="https://github.com/NineSixFourteen/Game-Server/tree/main/src/backend/GameApi/Services"> Services</a> <br/>
                    </Col>
                    <Col style={x}>
                        <HeadS color={this.state.color} bg={this.state.bg}> Games</HeadS>
                            <a style={x} href="https://github.com/NineSixFourteen/Game-Server/blob/main/src/backend/GamePlayer/Game.cs"> Game(Database Class) </a> <br/>
                            <a style={x} href="https://github.com/NineSixFourteen/Game-Server/blob/main/src/backend/GamePlayer/PlayableGame.cs"> Playable Game (Base Class)</a> <br/>
                            <a style={x} href="https://github.com/NineSixFourteen/Game-Server/blob/main/src/backend/GamePlayer/Games/TicTacToe.cs"> TicTacToe </a> <br/>
                            <a style={x} href="https://github.com/NineSixFourteen/Game-Server/blob/main/src/backend/GamePlayer/Games/FourInARow.cs"> Connect4 </a> <br/>
                    </Col>
                    <Col style={x}>
                    <HeadS color={this.state.color} bg={this.state.bg}> Front End </HeadS>
                        <a style={x} href="https://github.com/NineSixFourteen/Game-Server/blob/main/src/frontend/front/lib/Home/LoginScreen.dart"> Login Page </a> <br/>
                        <a style={x} href="https://github.com/NineSixFourteen/Game-Server/blob/main/src/frontend/front/lib/Home/DisplayScreen.dart"> Display Page </a> <br/>
                        <a style={x} href="https://github.com/NineSixFourteen/Game-Server/tree/main/src/frontend/front/lib/TicTac"> TicTacToe </a> <br/>
                        <a style={x} href="https://github.com/NineSixFourteen/Game-Server/tree/main/src/frontend/front/lib/Connect4"> Connect4 </a> <br/>
                    </Col>
            </Row>
            </TextB>
        
        </div>
    }
} export default SerNav

import React from "react";
import { Container, Navbar, Nav, NavDropdown, Row} from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css"
import logo from "../Photos/logo.png"

class Navibar extends React.Component{

    render() {
        let color = "rgb(20, 20, 20)"
        let fcolor = "white"
        if(window.screen.availHeight < window.screen.availWidth){
        return (
            <div style={{overflow:"none", scrollbarWidth: "none", backgroundColor:color}}>
                <Navbar style={{ backgroundColor:color}} fixed="top">
                    <Container fluid style={{ backgroundColor:color}}>
                        <Nav.Link className="px-2" href="/home" style={{background:"rgb(20,20,20)", width:"7%", justifyContent:"center", alignItems:"center", position:"relative", display:"flex"}}>
                            <img src={logo} height="65vh" style={{borderRadius:"2%"}} />
                        </Nav.Link>
                            <Nav style={{ backgroundColor:color}}>
                                <Nav.Link className="justify-content-end"  href="/about" style={{ backgroundColor:color}}>
                                    <span style={{fontSize:"4vh", fontWeight:"500",backgroundColor:color, color:fcolor}}> About </span>
                                </Nav.Link>
                                <Nav.Link className="justify-content-end"  href="/cv" style={{ backgroundColor:color}}>
                                    <span style={{fontSize:"4vh", fontWeight:"500",backgroundColor:color, color:fcolor}}> CV </span>
                                </Nav.Link>
                                <Nav.Link className="justify-content-end"  href="https://github.com/NineSixFourteen" style={{ backgroundColor:color}}>
                                    <span style={{fontSize:"4vh", fontWeight:"500",backgroundColor:color , color:fcolor}}> GitHub </span>
                                </Nav.Link>
                                <Nav.Link className="justify-content-end"  href="/projects/all" style={{ backgroundColor:color}}>
                                    <span style={{fontSize:"4vh", fontWeight:"500",backgroundColor:color, color:fcolor}}> Projects Finder </span>
                                </Nav.Link>
                                <NavDropdown className="ass" style={{fontSize:"4vh", fontWeight:"500", backgroundColor:color, }} title="Uni Projects" id="navbarScrollingDropdown">
                                    <div style={{background:"white", fontSize:"3vh"}}>
                                    <NavDropdown.Item style={{background:color,color:fcolor, fontSize:"3vh"}} href="/projects/Honours"> Honours Project </NavDropdown.Item>
                                    <NavDropdown.Item style={{background:color,color:fcolor, fontSize:"3vh"}} href="/projects/Functional"> Functional Programming </NavDropdown.Item>
                                    <NavDropdown.Item style={{background:color,color:fcolor, fontSize:"3vh"}}href="/projects/Web"> Web and Mobile </NavDropdown.Item>
                                    <NavDropdown.Item style={{background:color,color:fcolor, fontSize:"3vh"}}href="/projects/BMS"> Boston Metro </NavDropdown.Item>
                                    <NavDropdown.Item style={{background:color,color:fcolor, fontSize:"3vh"}}href="/projects/Conc"> Concurrency and Network </NavDropdown.Item>
                                    </div>
                                </NavDropdown>
                                <NavDropdown title="Personal Projects" id="navbarScrollingDropdown" style={{fontSize:"4vh", fontWeight:"500", backgroundColor:color}} >
                                    <div style={{background:"white", fontSize:"3vh"}}>
                                    <NavDropdown.Item style={{background:color,color:fcolor, fontSize:"3vh"}} href="/projects/Stats" > Stats Website </NavDropdown.Item>
                                    <NavDropdown.Item style={{background:color,color:fcolor, fontSize:"3vh"}} href="/projects/Discord" > Discord Bot </NavDropdown.Item>
                                    <NavDropdown.Item style={{background:color,color:fcolor, fontSize:"3vh"}} href="/projects/Toy"> Toy Language </NavDropdown.Item>
                                    <NavDropdown.Item style={{background:color,color:fcolor, fontSize:"3vh"}} href="/projects/Webpage"> WebPage Builder CLI </NavDropdown.Item>
                                    <NavDropdown.Item style={{background:color,color:fcolor, fontSize:"3vh"}} href="/projects/GameWAI"> 2048 Game W/AI  </NavDropdown.Item>
                                    <NavDropdown.Item style={{background:color,color:fcolor, fontSize:"3vh"}} href="/projects/GameServer"> Game Server </NavDropdown.Item>
                                    </div>
                                </NavDropdown>
                            </Nav>
                    </Container>
                </Navbar>
            </div>
        );
        } else {
            return <div style={{overflow:"none", scrollbarWidth: "none", backgroundColor:color}}>
                <Navbar style={{ backgroundColor:color}} fixed="top">
                    <Container fluid style={{ backgroundColor:color}}>
                        <Nav.Link className="px-2" href="/home" style={{ backgroundColor:"rgb(20,20,20)",}}>
                            <img src={logo} height="50vh" style={{borderRadius:"2%"}} />
                        </Nav.Link>
                            <Nav style={{ backgroundColor:color}}>
                                <NavDropdown style={{fontSize:"4vh", fontWeight:"500", backgroundColor:color}} title="_______________" id="navbarScrollingDropdown">
                                    <div style={{background:"white", fontSize:"3vh"}}>
                                        <NavDropdown.Header style={{background:"rgb(20,20,20)", fontSize:"4vh", color:"white", fontWeight:"800", border:"2px solid black", paddingLeft:0}} > Pages</NavDropdown.Header>
                                        <NavDropdown.Item href="/about"> About Me </NavDropdown.Item>
                                        <NavDropdown.Item href="/cv"> CV </NavDropdown.Item>
                                        <NavDropdown.Item href="/projects/all"> Project Finder </NavDropdown.Item>
                                        <NavDropdown.Header style={{background:"rgb(20,20,20)", fontSize:"3.5vh", color:"white", fontWeight:"800", border:"2px solid black", paddingLeft:0}}> Favorite Projects</NavDropdown.Header>
                                        <NavDropdown.Item href="/projects/Honours"> Honours Project </NavDropdown.Item>
                                        <NavDropdown.Item href="/projects/Stats"> Stats Webstite </NavDropdown.Item>
                                        <NavDropdown.Item href="/projects/Discord"> Discord Bot </NavDropdown.Item>
                                        <NavDropdown.Item href="/"> Personal Website </NavDropdown.Item>
                                        <NavDropdown.Item href="/projects/GameServer"> Game Server </NavDropdown.Item>
                                    </div>
                                </NavDropdown>
                            </Nav>
                    </Container>
                </Navbar>
            </div>
        }
      }
}export default Navibar;

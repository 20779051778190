import React from "react";
import { Col, Row } from "react-bootstrap";
import HeadC from "../../Shared/Text/HeadC";
import HeadS from "../../Shared/Text/HeadS";
import TextB from "../../Shared/Text/textB";

class ToyNav extends React.Component{

    constructor(props){
        super(props);
        this.state = {
            body: "",
            color: props.color ? props.color: "black",
            bg: props.bg ? props.bg : "white"
        } 
    }

    render(){
        let x = {color:this.state.color, background: this.state.bg}
        return <div style={{textAlign:"center"}}>
        <TextB color={this.state.color} bg={this.state.bg} >
            <HeadS color={this.state.color} bg={this.state.bg}> Project on <a  style={x} href="https://github.com/NineSixFourteen/toy_language">Github</a></HeadS>
            <Row style={x}>
                <Col style={x}>
                    <HeadS color={this.state.color} bg={this.state.bg}> Parser</HeadS>
                        <a style={x} href="https://github.com/NineSixFourteen/toy_language/blob/main/src/parser/tokenizer/mod.rs"> Tokenizer </a> <br/>
                        <a style={x} href="https://github.com/NineSixFourteen/toy_language/blob/main/src/parser/mod.rs"> Parser  </a> <br/>
                    </Col>
                    <Col style={x}>
                        <HeadS color={this.state.color} bg={this.state.bg}> Compiler</HeadS>
                            <a style={x} href="https://github.com/NineSixFourteen/toy_language/blob/main/src/compiler/mod.rs"> Compiler </a> <br/>
                    </Col>
                    <Col style={x}>
                    <HeadS color={this.state.color} bg={this.state.bg}> Interperter </HeadS>
                        <a style={x} href="https://github.com/NineSixFourteen/toy_language/blob/main/src/stack_machine/Evaluator/mod.rs"> Evaluator </a> <br/>
                        <a style={x} href="https://github.com/NineSixFourteen/toy_language/blob/main/src/stack_machine/mod.rs"> Stack Machine</a> <br/>
                    </Col>
                    <Col style={x}>
                    <HeadS color={this.state.color} bg={this.state.bg}> Tests </HeadS>
                        <a style={x} href="https://github.com/NineSixFourteen/toy_language/blob/main/src/tests/mod.rs"> Tests </a> <br/>
                    </Col>
            </Row>
            </TextB>
        
        </div>
    }
} export default ToyNav
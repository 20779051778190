import React from "react";
import HonSpec from "./HonSpec";
import HonsGal from "./HonsGal";
import HonsGit from "./HonsGit";

import Compiler from "./Descriptions/Compiler";
import Lexer from "./Descriptions/Lexer";
import Precompiler from "./Descriptions/pCompiler";
import Parser from "./Descriptions/Parser";
import Features from "./Descriptions/Features";

import NewProjPage from "../../Shared/Util/newProjPage";
import HonDesc from "./Descriptions/HonDesc";
import Holder from "../../Shared/Util/Holder";
import HonOver from "./Descriptions/HonOver";
import HonPlans from "./Descriptions/HonPlans";

class HonsPage extends React.Component{

    constructor(props){
        super(props);
        this.state ={
            color: "white",
            bg: "rgb(20,20,20)"
        }
    }

    flip(){
        if(this.state.color == "white"){
            this.setState({
                color: "rgb(20,20,20)", bg:"white"
            })
        } else {
            this.setState({
                color: "white", bg:"rgb(20,20,20)"
            })
        }

    }

    render(){
        return <NewProjPage 
            func={this.flip.bind(this)}
            childs={
                [
                    {Title: "Overview", body:new Holder(<HonOver/>,<HonOver color={"white"} bg={"rgb(20,20,20)"}/>), childs:[
                        {Title: "Future Goals", body:new Holder(<HonPlans/>,<HonPlans color={"white"} bg={"rgb(20,20,20)"}/>), childs:[]},
                    ]},
                    {Title: "Specification", body:new Holder(<HonSpec/>,<HonSpec color={"white"} bg={"rgb(20,20,20)"}/>), childs:[]},
                    {Title: "Description", body:new Holder(<HonDesc/>,<HonDesc color={"white"} bg={"rgb(20,20,20)"}/>), childs:[
                        {Title: "Features", body:new Holder(<Features/>,<Features color={"white"} bg={"rgb(20,20,20)"}/>), childs:[]},
                        {Title: "Lexer", body:new Holder(<Lexer/>,<Lexer color={"white"} bg={"rgb(20,20,20)"}/>), childs:[]},
                        {Title: "Parser", body:new Holder(<Parser/>,<Parser color={"white"} bg={"rgb(20,20,20)"}/>), childs:[]},                   
                        {Title: "Pre Compiler", body:new Holder(<Precompiler/>,<Precompiler color={"white"} bg={"rgb(20,20,20)"}/>), childs:[]},
                        {Title: "Compiler", body:new Holder(<Compiler/>,<Compiler color={"white"} bg={"rgb(20,20,20)"}/>), childs:[]},
                    ]},
                    {Title: "Gallary", body:new Holder(<HonsGal/>,<HonsGal color={"white"} bg={"rgb(20,20,20)"}/>), childs:[]},
                    {Title: "Links", body:new Holder(<HonsGit/>,<HonsGit color={"white"} bg={"rgb(20,20,20)"}/>), childs:[]},
                ]
            }
        />
    }
} export default HonsPage
import React from "react";
import HeadC from "../../Shared/Text/HeadC";
import HeadZ from "../../Shared/Text/HeadZ";
import TextB from "../../Shared/Text/textB";
import statsW from "../../Shared/Photos/Stats/Home.png"


class StatsOver extends React.Component{

    constructor(props){
        super(props);
        this.state ={
            color: props.color ? props.color: "black",
            bg: props.bg ? props.bg : "white"
        }
    }

    render(){
        return <div className="mx-3" style={{color:this.state.color, background:this.state.bg}}>
            <HeadZ color={this.state.color} bg={this.state.bg}> Stats Website</HeadZ>
            <div style={{display:"grid", justifyContent:"center", alignContent:"center", color:this.state.color, background:this.state.bg}}>
            <img src={statsW} style={{width:"45vw"}}/>
            </div>
            <HeadC color={this.state.color} bg={this.state.bg}> Project Goal </HeadC>
            <TextB color={this.state.color} bg={this.state.bg}>
            The goal of this project was to create a website/tool that I and others could use to analyse their games in League of Legends.
             The features I wanted in particular were the ability to view individual matches and each player's stats for that game in an easy-to-understand manner; 
             I also wanted the ability to view a player's average stats on different game modes, champions, positions, and maps and compare them to other players;
              and the final feature I wanted was the ability to compare players' teamates averages depending on multiple factors,
               which I could not find elsewhere on other stats tools or websites.            </TextB>
            <HeadC color={this.state.color} bg={this.state.bg}> Project Outcome </HeadC>
            <TextB color={this.state.color} bg={this.state.bg}>
            The outcome of this project was me completing the tasks I had laid out, 
            creating all the features I wanted initially, and also incorporating the newer stats that had been recently started being tracked 
            just before I started the project, so I was happy with the project when I left. However, there were some limitations, 
            such as interacting with the tool to retrieve more data from the riot-api, which could have been greatly improved as the UI I created was simple and 
            not clear on how to use. Also, I had limited experience with React at the start, so some of the early work is of a lower standard than later work. 
            </TextB>
            <br/><br/>
        </div>
    }
} export default StatsOver
import React from "react";
import Sections from "../../../../Shared/Util/Sections";
import TextB from "../../../../Shared/Text/textB";
import HeadC from "../../../../Shared/Text/HeadC";
import HeadB from "../../../../Shared/Text/HeadB";

import LoginPage from "../../../../Shared/Photos/GameServer/LoginPage.png"
import LoginPage2 from "../../../../Shared/Photos/GameServer/LoginPage2.png"
import LoginSucces from "../../../../Shared/Photos/GameServer/LoginSucces.png"
import LoginFail from "../../../../Shared/Photos/GameServer/LoginFail.png"
import LoginSuccesZoom from "../../../../Shared/Photos/GameServer/LoginSuccesZoom.png"

class Lpage extends React.Component{

    constructor(props){
        super(props);
        this.state = {
            body: "",
            color: props.color ? props.color: "black",
            bg: props.bg ? props.bg : "white"
        } 
    }

    render(){
        let x = {color:this.state.color, background:this.state.bg}
        return <div style={x} className="mx-3">
        <HeadC color={this.state.color} bg={this.state.bg}> Login Page </HeadC><TextB color={this.state.color} bg={this.state.bg}>
            The Login Page does two things it lets you login (big surprise) and create new accounts. The page looks like 
            <img 
                src={LoginPage}
                width="60%"
                style={{marginLeft:"20%"}}
                alt="Login Page"  
                /><br
            />
            It has two labelled fields for inputting the data and a button labelled for when you enter the data. There is also a yellow piece of text that,
            when you click on it, will display the following<br/>
            <img 
                src={LoginPage2}
                width="60%"
                style={{marginLeft:"20%"}}
                alt="Login Page with stuff at bottom"  
                /><br
            />
            Here are two text fields for entering the username and password, and then after you press the "Create Account" button,
            it will attempt to create a new account and then display a popup based on whether it was successful or not. A successful creation looks like<br/>
            <img 
                src={LoginSucces}
                width="60%"
                style={{marginLeft:"20%"}}
                alt="Login Page with stuff at bottom"  
                /><br
            />
            <img 
                src={LoginSuccesZoom}
                width="60%"
                style={{marginLeft:"20%"}}
                alt="Login Page with stuff at bottom"  
                /><br
            />
            And on failure it looks like this<br/>
            <img 
                src={LoginFail}
                width="60%"
                style={{marginLeft:"20%"}}
                alt="Login Page with stuff at bottom"  
                /><br
            /><br/>
        </TextB>
        </div>
    }
} export default Lpage

import React from "react";
import HeadC from "../../../../Shared/Text/HeadC";

import board from "../../../../Shared/Photos/2048/GameC.png";
import compl from "../../../../Shared/Photos/2048/GameCover.png";
import TextB from "../../../../Shared/Text/textB";

class CGui extends React.Component{

    constructor(props){
        super(props);
        this.state = {
            body: "",
            color: props.color ? props.color: "black",
            bg: props.bg ? props.bg : "white"
        } 
    }
    
    render(){
        let x = {color:this.state.color, background:this.state.bg}
        return <div style={x} className="mx-3">
            <HeadC color={this.state.color} bg={this.state.bg}> C# Gui/FrontEnd </HeadC>
            <TextB color={this.state.color} bg={this.state.bg}>
            The Razor framework, which is part of.Net, was used to create the GUI. Razor pages use a mixture of C# code and HTML 
            where the page looks like an HTML file, but if you want to use C# code, you place it after the @ sign.
            See <a style={x} href="https://github.com/NineSixFourteen/Remaking_2048/blob/main/2048/backend/razor/Pages/Index.razor"> this</a> for an example.
            <br/> 
            The GUI runs in the browser, and it is a 4x4 grid that displays a value for each tile. 
            It has a button for the AI to make a move and a button to restart the game. 
            The controls for the game are arrow keys for movement and the l key for the AI to make a move. 
            You can hold the keys to repeat the movement. Upon finishing the game, 
            it will display information about the game, including moves, time, moves per second, and the final score.
            <br/>Below is an image of the board and of the game completion screen.
            <HeadC color={this.state.color} bg={this.state.bg}> Board</HeadC>
            <img 
                src={board}
                width="60%"
                style={{marginLeft:"20%"}}
                alt="Board"  
                /><br
            />
            <HeadC color={this.state.color} bg={this.state.bg}> Complete</HeadC>
            <img 
                src={compl}
                width="60%"
                style={{marginLeft:"20%"}}
                alt="Board"  
                /><br
            />
        </TextB>
        </div>

    }


} export default CGui
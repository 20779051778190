import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Checklist from "../../Shared/Util/CheckList";
import HeadC from "../../Shared/Text/HeadC";
import HeadS from "../../Shared/Text/HeadS";
import Tags from "../../Shared/Util/Tags";
import TextB from "../../Shared/Text/textB";
import TagFactory from "../../Shared/Util/TagFactory";

class GameSpec extends React.Component{

    constructor(props){
        super(props);
        this.state = {
            body: "",
            color: props.color ? props.color: "black",
            bg: props.bg ? props.bg : "white"
        } 
    }

    render(){
        let x = {color:this.state.color, background:this.state.bg}
        return <div style={x} className="mx-3">
                <TextB color={this.state.color} bg={this.state.bg}>
                    <HeadC color={this.state.color} bg={this.state.bg}> Specifiation</HeadC>
                    <HeadS color={this.state.color} bg={this.state.bg}> Final Goal</HeadS>
                    To build a server capable of playing multiple games that is hosted on the internet so that anyone with an internet connection can play.
                    <HeadS color={this.state.color} bg={this.state.bg}> Technoloogy Used</HeadS>
                </TextB> 
                <Row style={x}> 
                    <Col style={x} sm={2}><TextB color={this.state.color} bg={this.state.bg}> Project Type : </TextB></Col>
                    <Col style={x}><Row style={x}>{TagFactory.makeTags("t", "University, Personal").concat(TagFactory.makeTags("ts", "Solo"))} </Row> </Col>
                </Row>
                <Row style={x}> 
                    <Col style={x} sm={2}><TextB color={this.state.color} bg={this.state.bg}> Language : </TextB></Col>
                    <Col style={x}><Row style={x}>{TagFactory.makeTags("l", "C#, Dart")} </Row></Col>
                </Row>
                <Row style={x}> 
                    <Col style={x} sm={2}><TextB color={this.state.color} bg={this.state.bg}> Technoloogy used : </TextB></Col>
                    <Col style={x} ><Row style={x}>{TagFactory.makeTags("to","Git, ASP.net, MySql, Nginx, AWS, Linode, Flutter")} </Row></Col>
                </Row>
                <TextB color={this.state.color} bg={this.state.bg}>
                <HeadS color={this.state.color} bg={this.state.bg}> Goals</HeadS>
                <Checklist 
                    name = "Project Goals"
                    items={
                        [
                            {done:true, message:"Build a Web API that is capable of acting as a server."},
                            {done:true, message:"Build a client capable of playing games on a website, desktop app and mobile App" },
                            {done:true, message:"Connect server and client using websockets."},
                            {done:false, message:"Build a collection of turn-based games that can be played."},
                        ]
                    }/>
                    <br/>
                <Checklist 
                    name = "Web api"
                    items={
                        [
                            {done:true, message:"Create web API"},
                            {done:true, message:"Connect to DB for storing information about the games"},
                            {done:true, message:"Create routes for the API"},
                            {done:true, message:"-- Create Games/Users"},
                            {done:true, message:"-- Login"},
                            {done:true, message:"-- Load Games"},
                            {done:true, message:"-- Get info about Games"},
                            {done:true, message:"-- Connect to Web Socket"},
                        ]
                    }/>
                    <br/>
                <Checklist 
                    name = "Flutter"
                    items={
                        [
                            {done:true, message:"Create Flutter App"},
                            {done:true, message:"Create Login Page"},
                            {done:true, message:"Create Game Display page"},
                            {done:true, message:"Create Page for playing TicTacToe"},
                            {done:true, message:"Create Page for playing Connect4"},
                            {done:true, message:"Create Pages for mobile"},
                        ]
                    }/><br/>
                <Checklist 
                    name = "Web Hosting"
                    items={
                        [
                            {done:true, message:"Host MySql RDS Database on AWS ."},
                            {done:true, message:"Host Web Api on Linode VM or on Azure."},
                            {done:true, message:"Setup NGinx to expose Webapi to the internet."},
                            {done:true, message:"Serup Services to have Webapi be available at all times."},
                        ]
                    }/><br/>
                <Checklist 
                    name = "Games"
                    items={
                        [
                            {done:true, message:"Connect4"},
                            {done:true, message:"TicTacToe"},
                            {done:false, message:"Make a Single Player Game"},
                            {done:false, message:"Make a 4-8 Player Game"},
                            {done:false, message:"Make a game where people can join during the game"},
                        ]
                    }/><br/>
                    <br/>
                </TextB>
        </div>
    }
} export default GameSpec 
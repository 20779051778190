import React from "react";
import HeadB from "../../../../Shared/Text/HeadB";
import TextB from "../../../../Shared/Text/textB";

import Match1 from "../../../../Shared/Photos/Stats//Match1.png";
import Match2 from "../../../../Shared/Photos/Stats/Match2.png";
import Match3 from "../../../../Shared/Photos/Stats/Match3.png";
import Match5 from "../../../../Shared/Photos/Stats/match5.png";
import HeadC from "../../../../Shared/Text/HeadC";

class MatchP extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            body: "",
            color: props.color ? props.color: "black",
            bg: props.bg ? props.bg : "white"
        } 
    }

    render(){
      let x = {color:this.state.color, background:this.state.bg}
      return <div style={x} className="mx-3">
        <HeadC color={this.state.color} bg={this.state.bg}> Match Page</HeadC>
        <TextB color={this.state.color} bg={this.state.bg}>
      The Match Page is for viewing all the stats about a singular match. 
      When the page loads, there will be a box with a text box where you can write the match ID of a match you wish to view stats about.
      You then hit the search button; this will load the rest of the page.
      <br/>When loaded, the page it looks like this<br/><br/>
      <img 
         src={Match1}
         width="60%"
         style={{marginLeft:"20%"}}
         alt="Match Load"
         /><br/><br/>
        The Match Page has Two Main Sections the Match Overview and Stats Viewer
        <HeadB color={this.state.color} bg={this.state.bg}> Match Overview </HeadB>
        As seen above the Match Overview Shows a quick look at a game and tells you some basic information about each player such as
        <ul style={x}>
          <li style={x}> Players Name</li>
          <li style={x}> Postion Played</li>
          <li style={x}> Team </li> 
          <li style={x}> Kills</li>
          <li style={x}> Deaths</li>
          <li style={x}> Assists</li>
          <li style={x}> KDA</li>
          <li style={x}> Vision Score</li> 
        </ul>
        <HeadB color={this.state.color} bg={this.state.bg}> Stats Viewer </HeadB>
        The Stats Viewer is for viewing the stats of a game in more detail. 
        There are three ways to view statistics in more detail.<br/>
        They are 
        <ul style={x}>
          <li style={x}> Overall</li>
          <li style={x}> Interval</li>
          <li style={x}> LeaderBoard</li> 
        </ul>
        <HeadB color={this.state.color} bg={this.state.bg}> Overall </HeadB>
        <div style={{textAlign:"center", color:this.state.color, background:this.state.bg}}>
        The overall allows you to view all of a player's stats in the game in a labelled table.
        <br/> The stats are identical to the MatchOverall 1 & 2 tables.
        <br/>You can select the player you want by clicking on the image of the champion they played.
        </div>
        <br/>
        <img 
         src={Match2}
         width="60%"
         style={{marginLeft:"20%"}}
         alt="Match 2"  
        /><br/>
        <HeadB color={this.state.color} bg={this.state.bg}> Interval </HeadB>
        <div style={{textAlign:"center", color:this.state.color, background:this.state.bg}}>
        The Interval Section, as seen in the image on the right, will tell you the stats that player had at different intervals during the game.
        <br/>The information displayed is the same as the match interval fields. <br/>
        You can select the player you want by clicking on the image of the champion they played.<br/><br/>
        </div>
        <img 
         src={Match5}
         width="60%"
         style={{marginLeft:"20%"}}
         alt="Match 2"  
        /><br/>
        <HeadB color={this.state.color} bg={this.state.bg}> Leadboard </HeadB>
        <div style={{textAlign:"center", color:this.state.color, background:this.state.bg}}>The Leadboard Section is a leadboard that displays the highest value in a stat in either ascending or descending order.</div>
        <br/>
        <img 
         src={Match3}
         width="60%"
         style={{marginLeft:"20%"}}
         alt="Match 2"  
        /><br/>
        
      </TextB>
      </div>
      
    }

} export default MatchP
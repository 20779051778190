import React from "react";
import HeadB from "../../../../Shared/Text/HeadB";
import HeadC from "../../../../Shared/Text/HeadC";
import TextB from "../../../../Shared/Text/textB";

import Home from "../../../../Shared/Photos/Stats/Home.png"


class Website extends React.Component{

    constructor(props){
      super(props);
      this.state = {
          body: "",
          color: props.color ? props.color: "black",
          bg: props.bg ? props.bg : "white"
      } 
    }

    render(){
        let x = {color:this.state.color, background:this.state.bg}
        return <div style={x} className="mx-3">
          <HeadC color={this.state.color} bg={this.state.bg}>
            Website  
          </HeadC>
          <HeadB color={this.state.color} bg={this.state.bg}>
            Overview
          </HeadB>
          <TextB color={this.state.color} bg={this.state.bg}>
          The website was designed to be the frontend of the project,
          with the goal of displaying the information in an easy-to-read way and making using the API simpler by using things
          such as options instead of having to write out a weird URL, which is easy to make errors in,
          and getting back a JSON data dump that is hard to navigate and read information from.
          It is also just nicer to look at the JSON file. <br/><br/>
          The website has 5 pages: Home, Match, Overall, Player and Admin.<br/>
          The home page is a simple page that just explains the purpose of the site and points to the other pages it looks like
          <br/><br/> <img
            src ={Home}
            style={{marginLeft:"20%"}}
            width="60%"
            alt="home"
          /><br/><br/>
          The admin page is just a more direct way to interact with the API; 
          it gives a text box for different fields to type in parameters instead of doing it all at once in a URL.
          <br/> More information about each page below
          </TextB>
          <HeadB color={this.state.color} bg={this.state.bg}>
            Page Information
          </HeadB>
        </div>
    }
} export default Website



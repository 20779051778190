import React from "react";
import HeadC from "../../Shared/Text/HeadC";
import HeadZ from "../../Shared/Text/HeadZ";
import TextB from "../../Shared/Text/textB";
import disB from "../../Shared/Photos/Discord/kills.png"


class DisOver extends React.Component{

    constructor(props){
        super(props);
        this.state ={
            color: props.color ? props.color: "black",
            bg: props.bg ? props.bg : "white"
        }
    }

    render(){
        return <div className="mx-3" style={{color:this.state.color, background:this.state.bg}}>
            <HeadZ color={this.state.color} bg={this.state.bg}> Discord Bot</HeadZ>
            <div style={{display:"grid", justifyContent:"center", alignContent:"center", color:this.state.color, background:this.state.bg}}>
            <img src={disB} style={{width:"45vw", height:"50vh"}}/>
            </div>
            <HeadC color={this.state.color} bg={this.state.bg}> Project Goal </HeadC>
            <TextB color={this.state.color} bg={this.state.bg}>
                The goal of this project was to create a tool that me and my friends could use to find and compare stats about the 2022 World Championship
                and share this information all within Discord.
            </TextB>
            <HeadC color={this.state.color} bg={this.state.bg}> Project Outcome </HeadC>
            <TextB color={this.state.color} bg={this.state.bg}>
            The project was completed during the World Championship, where it was used by me and my friends while we were watching or discussing the games.
            However, I only created the system for querying the data for champions. I never finished the query system for players.
            </TextB>
            <br/>
        </div>
    }
} export default DisOver
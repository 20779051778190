import React from "react";
import { Row, Col} from "react-bootstrap";
import { CopyBlock, dracula } from "react-code-blocks";
import HeadB from "../../../../Shared/Text/HeadB";
import HeadC from "../../../../Shared/Text/HeadC";
import TextB from "../../../../Shared/Text/textB";
class RTypes extends React.Component{

    constructor(props){
        super(props);
        this.state = {
            body: "",
            color: props.color ? props.color: "black",
            bg: props.bg ? props.bg : "white"
        } 
    }

    render(){
        let x = {color:this.state.color, background: this.state.bg}
        return <div style={x} className="mx-3"><TextB color={this.state.color} bg={this.state.bg} >
            <HeadC color={this.state.color} bg={this.state.bg}> Types</HeadC>
            The types inside of the stack machine are defined 
            as
            <CopyBlock
            text={
                "pub(crate) enum Value {\n" + 
                "   Nothing,\n" + 
                "   Int(i64),\n" + 
                "   String(String),\n" + 
                "   Boolean(bool),\n" + 
                "   Float(f32),\n" + 
                "   Char(char),\n" + 
                "   Array(Vec<Value>)\n" + 
                "}"
            }
            language={"rust"}
            showLineNumbers={false}
            theme={dracula}
            wrapLines
            />
            <HeadB color={this.state.color} bg={this.state.bg}> Errors</HeadB>
            The stack machine will throw errors if it finds them when going through the instruction set.
            Some of the errors overlap with ones found in the parser.
            This is because the stack machine was designed to be separate from the parser and compiler; it is just the target language of them.
            It was designed so it could easily be used for another language.
            It is capable of throwing the following errors:
            <ul style={x}>
                <li style={x}> No Such Var
                    <ul style={x}> 
                        <li style={x}> This will be thrown when a user tries to use a variable that has not been declared</li>
                        <li style={x}> For example, if a user only defines num but then tries to use numb, this error will be thrown </li>
                    </ul>
                </li>
                <li style={x}> Commands Out Of Bounds
                    <ul style={x}> 
                        <li style={x}> This will be thrown if a stack machine tries to read an instruction outside of the instruction list</li>
                    </ul>
                </li>
                <li style={x}> Nothing To Pop
                    <ul style={x}> 
                        <li style={x}> This will be thrown if you try to use an instruction that pops from the stack but the stack is empty</li>
                        <li style={x}> If you pushed 10 on the stack, popped it, and then tried to pop it again, there would be nothing to pop off the stack </li>
                    </ul>
                </li>
                <li style={x}> Go To Zero
                    <ul style={x}> 
                        <li style={x}> This will be thrown if you attempt to jump to a negative number </li>
                        <li style={x}> As an example, suppose the jump command is set to -5, this is thrown as there are no commands below 0</li>
                    </ul>
                </li>
                <li style={x}> Go To Out Of Bounds
                    <ul style={x}> 
                        <li style={x}> Similar to commands out of bounds, but it is detected when trying to jump</li>
                        <li style={x}> An example would be if the jump command pointed to 21 when the instruction set is of size 10</li>
                    </ul>
                </li>
                <li style={x}> Operand Not Supported
                    <ul style={x}> 
                        <li style={x}> This is thrown when an operand, such as +,-,*,/, is used on a type that does not have this</li>
                        <li style={x}> An example would be if you tried to take away (-) from a String this is not supported.</li>
                    </ul>
                </li>
            </ul>
        </TextB>
        </div>
    }
} export default RTypes

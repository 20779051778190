import React from "react";
import { Container } from "react-bootstrap";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card  from "react-bootstrap/Card";
import Navibar from "../Shared/Util/NavBar";
import Email from "../Shared/Photos/CV/email.jpg";
import Git from "../Shared/Photos/CV/git.png";
import web from "../Shared/Photos/CV/web.png";
import likd from "../Shared/Photos/CV/link.jpg";


class CV extends React.Component{

    render() {
        return (
            <div className="CV" style={{background:"white", position:"relative", top:"8vh"}}> 
            <Navibar/>
            <Container className="CV" style={{position:"relative", top:"0vh"}}>
                <div className="CV" style={{ textAlign:"center"}} >
                    <span className="CV" style={{fontSize:"350%", fontWeight:"800"}}> Thomas Williams</span><br />
                    <span  className="CV"style={{fontSize:"120%", fontWeight:"200"}}> Computer Science Graduate</span><br />
                </div>
            </Container>
            <Container className="CV">
                <Row className="my-4 CV">
                    <Col sm={8} className="CV">
                        <Card className="CV" bg="dark" style={{height:"100%"}} >
                            <Card.Header className="CV" style={{color:"white"}}>
                                Profile
                            </Card.Header>
                            <Card.Body className="CV" style={{background:"white"}}>
                            I am looking to start my career in software development and to learn new technologies and create exciting projects as a software developer. 
                            My interest and skills are largely in coding however I enjoy creating both front and back-end systems using many different languages and tools.
                            I am looking forward to learning new skills and adapting my current ones to allow me to work with my colleagues to create professional industry grade software.  
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col className="CV" sm={4}>
                        <Card className="CV" bg="dark" style={{height:"100%"}} >
                            <Card.Header className="CV" style={{color:"white"}}>
                                Contact
                            </Card.Header>
                            <Card.Body className="CV" style={{background:"white"}}>
                                    <Row className="CV"  style={{flexWrap:"nowrap"}}>
                                        <img className="CV"
                                            style={{width:"15%", marginTop:"-1%"}}
                                            src={Email}
                                            alt="Email:"
                                            /> 
                                            < span  className="CV" style={{ fontSize: "93%"}}>thomas-williams-1@hotmail.com</span>
                                    </Row>
                                    <Row className="CV" style={{ flexWrap:"nowrap"}}>
                                    <img className="CV"
                                        style={{width:"15%", marginTop:"-1%"}}
                                        src={Git}
                                        alt="Git:"
                                        /> 
                                    <a className="CV" style={{ fontSize: "115%"}}href="https://github.com/NineSixFourteen"> NineSixFourteen </a>
                                    </Row>
                                    <Row className="CV" style={{flexWrap:"nowrap"}}>
                                        <img className="CV"
                                            style={{width:"18%", marginTop:"-1%", marginLeft:"-1%   "}}
                                            src={likd }
                                            alt="Linkden:"
                                            /> 
                                        <a className="CV" style={{ fontSize: "115%",marginLeft:"-1%" }}href="https://www.linkedin.com/in/thomas-w-40020b1bb/"> Thomas Williams </a>
                                    </Row>
                                    <Row className="CV" style={{ flexWrap:"nowrap"}}>
                                    <img className="CV"
                                            style={{width:"15%", marginLeft:"1%", marginTop:"1.4%"}}
                                            src={web }
                                            alt="Website:"
                                            /> 
                                        <a  className="CV" style={{ fontSize: "115%", marginLeft:"1%", marginTop:"1.4%"}}href="https://thomaswill.co.uk/" > Website</a>
                                    </Row>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                <Row className="my-4 CV">
                    <Col className="CV" sm={8}>
                        <Card className="CV" bg="dark">
                            <Card.Header className="CV" style={{color:"white"}}>
                                Skills
                            </Card.Header>
                            <Card.Body  className="CV"  style={{background:"white"}}>
                                <Container  className="CV" >
                                    <Row  className="CV" >
                                    <Col  className="CV" >
                                        <ul  className="CV" >
                                            <li  className="CV" >
                                                Java
                                                <ul  className="CV" >
                                                    <li  className="CV" > Spring </li>
                                                    <li  className="CV" > ASM </li>
                                                    <li  className="CV" > Maven </li>
                                                    <li  className="CV" > Discord API </li>
                                                </ul>
                                            </li>
                                            
                                        </ul>
                                    </Col>
                                    <Col  className="CV" >
                                        <ul  className="CV" >
                                            <li  className="CV" > Python </li>
                                            <li  className="CV" > Haskell </li>
                                            <li  className="CV" > Rust </li>
                                            <li  className="CV" > Git</li>
                                            <li  className="CV" > Ubuntu </li>
                                            <li  className="CV" > WordPress</li>
                                        </ul>
                                    </Col>
                                    <Col  className="CV" >
                                        <ul  className="CV" >
                                            <li  className="CV" > Web Design</li>
                                            <ul  className="CV" >
                                                <li  className="CV" > HTML </li>
                                                <li  className="CV" > CSS  </li>
                                                <li  className="CV" > PHP  </li>
                                                <li  className="CV" > JavaScript
                                                    <ul  className="CV" >
                                                        <li  className="CV" >React </li>
                                                    </ul>
                                                </li>
                                            </ul> 
                                        </ul>
                                        </Col>
                                        <Col  className="CV" >
                                            <ul  className="CV" >
                                            <li  className="CV" > MySql</li>
                                            </ul>
                                        </Col>
                                    </Row>
                                </Container>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col sm={4}  className="CV" >
                        <Card  className="CV"  bg="dark" style={{height:"100%"}}>
                            <Card.Header  className="CV"  style={{color:"white"}}>
                                Education
                            </Card.Header>
                            <Card.Body  className="CV"  style={{background:"white"}}>
                                <span  className="CV"  style={{fontSize:"120%"}}> September 2018 - July 2022 </span><br/>
                                University of Strathclyde <br />
                                2:1 BSc(Hons) Computer Science <br />
                                <span  className="CV"  style={{fontSize:"120%"}}> August 2012 - July 2018 </span><br/>
                                Lenzie Academy
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                <Row className="my-2 CV"  >
                    <Col  className="CV" >
                        <Card bg="dark"  className="CV" >
                            <Card.Header style={{color:"white"}}  className="CV" >
                                Projects
                            </Card.Header>
                            <Card.Body  className="CV"  style={{background:"white"}}>
                                <span  className="CV"  style={{fontSize:"140%" , fontWeight:"500"}}> Honours Project - Solo</span> <br />
                                This project was done in my fourth year of university. For my individual project, the topic I chose was "Design and Implement a Programming Language" where I worked closely with an advisor to complete this project.
                                I decided to build a source-to-source Java bytecode compiler, which means that I would take a file written in my language and convert it to Java bytecode instructions that could then be run in the JVM.
                                To accomplish this, I created a parser that could parse the language I created into expressions.
                                The expressions would then be given to the compiler, which would convert them into bytecode instructions.
                                The instructions are then saved in a.class file, which the JVM can execute.
                                As part of this project, I was also required to write a dissertation on this topic and explain my process for creating my language. 
                                <br />
                                <span  className="CV"  style={{fontWeight:"600"}}>Tools Used: </span> Java, ASM, Maven, Git, LaTex<br/>
                                <span  className="CV"  style={{fontWeight:"600"}}>Result </span>: 69/100
                                <br /><br />
                                <span  className="CV"  style={{fontSize:"140%" , fontWeight:"500"}}> Stats Website - Solo</span>
                                <br />
                                This was a personal project I started with the purpose of developing a tool that would allow me and others to analyse our stats in the game of League of Legends.
                                The project gathers data from an API made available by the game's developers and stores it in a MySQL database, which can then be queried by an API I created using Java Spring to get and analyse the data.
                                Additionally, I created a React website that acts as the front end for the API.
                                The website makes it easier to use the API and analyzes the data then displays it in interesting ways. 
                                For Example you can search for a playes name and the all the players information will apear in easy to understand format.
                                It also provides extra features, such as allowing you to comapre differn't players stats against each other 
                                or allow a user to compare their performance on differn't charaters in the game. 
                                <br/> 
                                <span  className="CV"  style={{fontWeight:"600"}}>Tools Used: </span> Java, Spring, MySql, Riot API, React <br /><br />
                                <span  className="CV"  style={{fontSize:"140%" , fontWeight:"500"}}> Web and Mobile App Development - Solo, Group</span> <br />
                                These were classes I took during my third year of university.
                                The class required me to undertake many different projects to learn how to effectively create web and mobile apps.
                                Projects I did during this class ranged from building a website to allow an artist to publish and sell their artwork to developing a mobile app that converts currencies or COVID relief apps.
                                During this class, I did a mixture of group and solo work, depending on the scale of the app that we were creating.
                                <br/>
                                <span  className="CV"  style={{fontWeight:"600"}}>Tools Used: </span> HTML, CSS, Bootstrap, JS, PHP, MySql, React, React Native, Andriod Studio <br/>
                                <span  className="CV"  style={{fontWeight:"600"}}>Result </span>: 89/100 and 84/100 <br/> <br />
                                <span  className="CV"  style={{fontSize:"140%" , fontWeight:"500"}}> Boston Metro System Route Planner - Group</span> <br />
                                This was a project that was 100% of my grade for one of my third-year classes.
                                The project was to create a system that was capable of providing you with the shortest path between any two stations on the Boston metro that a user selected.
                                This was a team project consisting of five members.
                                The main tasks I worked on for this project were developing the project's user interface (UI) to allow the user to choose their destination and testing.
                                When creating the UI, the group initially drew up a wireframe of the design, which I then implemented using Swing in Java.
                                For testing, this was done using JUnit, and the testing was primarily to test the path-finding algorithm to ensure it always returned the shortest path.
                                <br /><span className="CV" style={{fontWeight:"600"}}>Tools Used: </span> Java, Swing <br/>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
            <br/>
            <br/>
            </div>
        );
      }

}export default CV;

import React from "react";
import {Col, Row } from "react-bootstrap";
import Img from "../../Shared/Photos/Func/Img.png"
import ImageViewer from 'react-simple-image-viewer';

class FunGal extends React.Component{


    constructor(props){
        super(props);
        this.state = {
            body: "",
            color: props.color ? props.color: "black",
            bg: props.bg ? props.bg : "white"
        } 
    }

    onClose(){
        this.setState({body:""})
    }

    show(num){
        let x =             
            <ImageViewer
                src={[Img ] }
                currentIndex={ num}
                disableScroll={ false }
                closeOnClickOutside={ true }
                onClose={ () => this.onClose()}
            />
        this.setState({body:x})
    }

    render(){
        let x = {color: this.state.color, background:this.state.bg}
        let b = this.state.ind;
        return <div className="mx-3" style={x}>
            <Row className="py-3 px-3" style={x}>
                <Col style={x}>
                    <div 
                    style={{height:"40vh", width:"48vw",
                        backgroundImage:  "url(" + Img + ")", 
                        backgroundRepeat: 'no-repeat',backgroundSize:"cover"}}
                        onClick={() => this.show(0)}
                        />
                </Col>
            </Row>
            <br/><br/><br/>
            {this.state.body}
        </div>
    }

} export default FunGal 
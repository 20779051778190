import React from "react";
import HeadB from "../../../../Shared/Text/HeadB";
import HeadC from "../../../../Shared/Text/HeadC";
import TextB from "../../../../Shared/Text/textB";

class CSSInt extends React.Component{

    constructor(props){
        super(props);
        this.state = {
            body: "",
            color: props.color ? props.color: "black",
            bg: props.bg ? props.bg : "white"
        } 
    }

    render(){
        let x = {color:this.state.color, background:this.state.bg}
        return <div className="mx-3" style={x}><HeadC color={this.state.color} bg={this.state.bg}> CSS Interface</HeadC><TextB color={this.state.color} bg={this.state.bg} >
        The interfaces for creating and editing CSS
        <ul style={x}>
            <li style={x}>CSS Interface: The primary interface and entry point into the CSS Section.</li>
            <li style={x}>CS Interface: The interface responsible for creating any styles you want to add to your page</li>
            <li style={x}>PE Interface: The interface in charge of creating any CSS elements you desire </li>
        </ul>
        </TextB>
        </div>
    } 
} export default CSSInt


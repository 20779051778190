import React from "react";
import Pui from "./Pui";
import Pgame from "./Pgame";
import Sections from "../../../../Shared/Util/Sections";
import TextB from "../../../../Shared/Text/textB";
import HeadC from "../../../../Shared/Text/HeadC";

class PyVer extends React.Component{

    constructor(props){
        super(props);
        this.state = {
            body: "",
            color: props.color ? props.color: "black",
            bg: props.bg ? props.bg : "white"
        } 
    }
    

    render(){
        let x = {color:this.state.color, background:this.state.bg}
        return <div style={x} className="mx-3">
            <HeadC color={this.state.color} bg={this.state.bg}> Python Version </HeadC>
            <TextB color={this.state.color} bg={this.state.bg}>
                The Python version was the first version I made for a class, most of the game logic was 
                provided in the class and the ExpectMax AI and Tkinter GUI were created by me.
            </TextB>
            <br/>
        </div>
    }
} export default PyVer 

import React from "react";
import HeadC from "../../../../Shared/Text/HeadC";
import TextB from "../../../../Shared/Text/textB";

import "../../../../../node_modules/video-react/dist/video-react.css";

<link rel="stylesheet" href="/css/video-react.css" />

class Compa extends React.Component{

    constructor(props){
        super(props);
        this.state = {
            body: "",
            color: props.color ? props.color: "black",
            bg: props.bg ? props.bg : "white"
        } 
    }
    

    render(){
        let x = {color:this.state.color, background:this.state.bg}
        return <div style={x} className="mx-3">
            <HeadC color={this.state.color} bg={this.state.bg}> Compare Python and C# </HeadC>
            <TextB color={this.state.color} bg={this.state.bg}>
            The point of recreating the project in C# was to compare it to the Python version and see how much faster it is. 
            I made two comparisons, how many moves each made per second with and without the GUI. 
            </TextB>
            <br/>
        </div>
    }

} export default Compa


import React from "react";
import HeadC from "../../Shared/Text/HeadC";
import TextB from "../../Shared/Text/textB";

class SerPlan extends React.Component{

    constructor(props){
        super(props);
        this.state ={
            color: props.color ? props.color: "black",
            bg: props.bg ? props.bg : "white"
        }
    }

    render(){
        return <div className="mx-3">
            <HeadC color={this.state.color} bg={this.state.bg}> Additional Goals</HeadC>
            <TextB color={this.state.color} bg={this.state.bg}>
                Any additional goals for this project would be to add more things to do on the server such as more games and different types aswell as other basic 
                features such as messaging as I feel I have underutilised the AWS RDS database I set up the project. 
            </TextB>
        </div>
    }
} export default SerPlan
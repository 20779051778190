import React from "react";
import HeadC from "../../Shared/Text/HeadC";
import HeadZ from "../../Shared/Text/HeadZ";
import TextB from "../../Shared/Text/textB";
import game from "../../Shared/Photos/GameServer/LoginPage.png"


class SerOver extends React.Component{

    constructor(props){
        super(props);
        this.state ={
            color: props.color ? props.color: "black",
            bg: props.bg ? props.bg : "white"
        }
    }

    render(){
        return <div className="mx-3" style={{color:this.state.color, background:this.state.bg}}>
            <HeadZ color={this.state.color} bg={this.state.bg}> Game Server</HeadZ>
            <div style={{display:"grid", justifyContent:"center", alignContent:"center", color:this.state.color, background:this.state.bg}}>
            <img src={game} style={{width:"40vw"}}/>
            </div>
            <HeadC color={this.state.color} bg={this.state.bg}> Project Goal </HeadC>
            <TextB color={this.state.color} bg={this.state.bg}>
            The goal of this project was to create a web-API that could act as a server to a group of clients and utilise websocket connections to share information between them,
            as well as host this project on the internet so anyone with an internet connection could use it.
            This would also allow me to begin to better understand the process of hosting projects on the web using VMs and the required skills, 
            such as nginx. The goal of the frontend was to familiarise myself with Flutter, as it has many advantages for developing apps.
            </TextB>
            <HeadC color={this.state.color} bg={this.state.bg}> Project Outcome </HeadC>
            <TextB color={this.state.color} bg={this.state.bg}>
            I completed the goals of building a server that could handle multiple connections at the same time and hosting on the web with a Linode VM. 
            I also gained a good grasp of developing front-end apps with Flutter.
            However, the actual server in question only has a few activities, such as the few games available to play on it.
            </TextB>
            <br/><br/>
        </div>
    }
} export default SerOver
import React from "react";
import { Col, Row } from "react-bootstrap";
import HeadC from "../../../../Shared/Text/HeadC";
import HeadB from "../../../../Shared/Text/HeadB";
import TextB from "../../../../Shared/Text/textB";
class EventT extends React.Component{

    constructor(props){
        super(props);
        this.state = {
            color: props.color ? props.color: "black",
            bg: props.bg ? props.bg : "white"
        } 
    }

    render(){
        let x = {color:this.state.color, background:this.state.bg}
        return <div className="mx-3" style={x}><HeadC color={this.state.color} bg={this.state.bg}> Event Table</HeadC><TextB color={this.state.color} bg={this.state.bg}>
            The other data stores information about event and stats about the event.Its fields are
            <Row style={x}>
                <Col style={x}>
                    <ul style={x}>
                        <li style={x}>Tournament winner</li>
                        <li style={x}>Player with most kills</li>
                        <li style={x}>How many reverse sweeps</li>
                        <li style={x}>How many baron steals</li>
                        <li style={x}>Number of Elder Drakes</li>
                    </ul>
                </Col>
                <Col style={x}>
                    <ul style={x}>
                        <li style={x}>Group A winner</li>
                        <li style={x}>Team with most unique champions played</li>
                        <li style={x}>How many pentakills</li>
                        <li style={x}>List of all drakes</li>
                    </ul>
                </Col>
                <Col style={x}>
                    <ul style={x}>
                        <li style={x}>Group B winner</li>
                        <li style={x}>Best team from 2 seed region</li>
                        <li style={x}>Longest game time</li>
                        <li style={x}>Most Common Drake</li>
                    </ul>
                </Col>
            </Row>
        </TextB>
        </div>
    }
} export default  EventT
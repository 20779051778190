import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Checklist from "../../Shared/Util/CheckList";
import HeadC from "../../Shared/Text/HeadC";
import HeadS from "../../Shared/Text/HeadS";
import Tags from "../../Shared/Util/Tags";
import TextB from "../../Shared/Text/textB";
import TagFactory from "../../Shared/Util/TagFactory";

class ConSpec extends React.Component{

    constructor(props){
        super(props)
        this.state = {
            body: "", 
            color: props.color ? props.color: "black",
            bg: props.bg ? props.bg : "white",
        }
    }

    render(){
        let x = {color:this.state.color, background: this.state.bg}
        return <div className="mx-3">
                <TextB color={this.state.color} bg={this.state.bg}>
                    <HeadC color={this.state.color} bg={this.state.bg}> Specifiation</HeadC>
                    <HeadS color={this.state.color} bg={this.state.bg}> Final Goal</HeadS>
                    To build programs utilising concurrency and networking.
                    <HeadS color={this.state.color} bg={this.state.bg}> Technoloogy Used</HeadS>
                </TextB> 
                <Row style={x}> 
                    <Col style={x} sm={2}><TextB color={this.state.color} bg={this.state.bg}> Project Type : </TextB></Col>
                    <Col style={x}><Row style={x}>{TagFactory.makeTags("t", "University").concat(TagFactory.makeTags("ts", "Solo, Medium"))} </Row> </Col>
                </Row>
                <Row style={x}> 
                    <Col style={x} sm={2}><TextB color={this.state.color} bg={this.state.bg}> Language : </TextB></Col>
                    <Col style={x}><Row style={x}>{TagFactory.makeTags("l", "Java")} </Row></Col>
                </Row>
                <Row style={x}> 
                    <Col style={x} sm={2}><TextB color={this.state.color} bg={this.state.bg}> Technoloogy used : </TextB></Col>
                    <Col><Row style={x}>{TagFactory.makeTags("to","Git ")} </Row></Col>
                </Row>
                    <TextB color={this.state.color} bg={this.state.bg}>
                    <HeadS color={this.state.color} bg={this.state.bg}> Goals</HeadS>
                    <Checklist 
                    name = "Project Goals"
                    items={
                        [
                            {done:true, message:"To build a tic-tac-toe game using sockets."},
                            {done:true, message:"To build a simulation of a TCP connection."},
                            {done:true, message:"To build a program to view and create new threads in the JVM."},
                            {done:true, message:"To build a banking program with concurrency. "},
                        ]
                    }/>
                    <br/>
                    <Checklist 
                    name = "Tic-Tac-Toe"
                    items={
                        [
                            {done:true, message:"Create a tic-tac-toe game."},
                            {done:true, message:"Send packets through sockets for each move."},
                        ]
                    }/>
                    <br/>
                    <Checklist 
                    name = "TCP connection"
                    items={
                        [
                            {done:true, message:"Create packets to send down the connection."},
                            {done:true, message:"Create a system to detect faulty packets or missing ones."},
                            {done:true, message:"Create a system that sends faulty or missing packets until all packets are received."},
                        ]
                    }/><br/>
                    <Checklist 
                    name = "Thread Viewer"
                    items={
                        [
                            {done:true, message:"Create a programme that can find all of the threads and their information using ThreadGroup."},
                            {done:true, message:"Create an interface that can display and create new ones."},
                        ]
                    }/><br/>
                    <Checklist 
                    name = "Bank System"
                    items={
                        [
                            {done:true, message:"Creating a back end system with accounts for withdrawal, deposit, and transfer"},
                            {done:true, message:"Convert functions to use concurrency, i.e. Synchronized functions and use of locks"},
                            {done:true, message:"Write tests to be quadruple sure."},
                        ]
                    }/><br/>
                </TextB>
        </div>
    }
} export default ConSpec 
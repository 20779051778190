import React from "react";
import HeadC from "../../Shared/Text/HeadC";
import HeadZ from "../../Shared/Text/HeadZ";
import TextB from "../../Shared/Text/textB";
import Map from "../../Shared/Photos/Boston/Map.png";

class BMOver extends React.Component{

    constructor(props){
        super(props);
        this.state ={
            color: props.color ? props.color: "black",
            bg: props.bg ? props.bg : "white"
        }
    }

    render(){
        return <div className="mx-3" style={{color:this.state.color, background:this.state.bg}}>
            <HeadZ color={this.state.color} bg={this.state.bg}> Bostom Metro System</HeadZ>
            <div style={{display:"grid", justifyContent:"center", alignContent:"center", color:this.state.color, background:this.state.bg}}>
            <img src={Map} style={{width:"25vw"}}/>
            </div>
            <HeadC color={this.state.color} bg={this.state.bg}> Project Goal </HeadC>
            <TextB color={this.state.color} bg={this.state.bg}>
                The goal of this project was to create a GUI interface where you could select two points on the Boston Metro, and then applications would show you the shortest path between the two points.
            </TextB>
            <HeadC color={this.state.color} bg={this.state.bg}> Project Outcome </HeadC>
            <TextB color={this.state.color} bg={this.state.bg}>
            We completed the application with the GUI and the desired Dijkstra's algorithm in use for finding the shortest path.
</TextB>
<br/><br/>
        </div>
    }
} export default BMOver
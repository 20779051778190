import React from "react";
import { CodeBlock, CopyBlock, a11yLight, tomorrowNightBlue} from "react-code-blocks";
import HeadC from "../../../Shared/Text/HeadC";
import TextB from "../../../Shared/Text/textB";

class Parser extends React.Component{

    constructor(props){
        super(props);
        this.state ={
            color: props.color ? props.color: "black",
            bg: props.bg ? props.bg : "white"
        }
    }

    render(){
        let x = {color:this.state.color, background: this.state.bg };
        return <div className="mx-3" style={{color:this.state.color, background: this.state.bg, display:"block", justifyContent:"center"}}>
            <HeadC color={this.state.color} bg={this.state.bg}> Parser </HeadC>
        <TextB color={this.state.color} bg={this.state.bg} >
        The parser is the part of the program that takes in the contents of the text file and then converts it into tokens, expressions and lines.
        <br></br> For example, the string  <br/>
        <br/>
        <div className="mx-3 ass" style={{width:"auto"}}>
        <CodeBlock
            text={ 
                "\"int i = 0;\""
                }
            language={"Java"}
            showLineNumbers={false}
            theme={a11yLight}
            /></div><br/>
        Would become a list of tokens <br/><br/>
        <div style={{width:"23.6%"}}>
        <CodeBlock
            text={
                "[INT, Var(i), EQ, Val(0), SemiColan]"    
                }
            language={"Java"}
            showLineNumbers={false}
            theme={a11yLight}
            wrapLines
            /></div><br/>
       
        Which would later become a line like<br/><br/>
        <div style={{width:"24%"}}>
        <CodeBlock
            text={
                    "Line{\n" 
                    + "  type: IntAssign\n"
                    + "  exprs: [\n"
                    + "   Expr(type:Var, Left: \"i\", right:null)\n"
                    + "   Expr(type:Val, Left: 0, right:null)\n"
                    + "]}"     
                }
            language={"Java"}
            showLineNumbers={false}
            theme={a11yLight}
            wrapLines
            style={{backgroundColor:"white"}}
            /></div><br/>
        The Strucure of a program would look like <br/><br/></TextB>
        <div style={{width:"24%"}}>
        <CodeBlock
            text={
                    "Class Program{\n" 
                    + "   String name;\n"
                    + "   List<Functions> functions;\n"
                    +  "}"      
                }
            language={"Java"}
            showLineNumbers={false}
            theme={a11yLight}
            wrapLines
            style={{backgroundColor:"white"}}
            /></div><br/>
        <div style={{width:"24%"}}><CodeBlock
            text={
                    "Class Function{\n" 
                    + "   String name;\n"
                    + "   List<Vars> vars;\n"
                    + "   List<Lines> lines;\n"
                    +  "}"      
                }
            language={"Java"}
            showLineNumbers={false}
            theme={a11yLight}
            wrapLines
            style={{backgroundColor:"white"}}
            /></div><br/>
        <div style={{width:"24%"}}><CodeBlock
            text={
                    "Class Lines{\n" 
                    + "   Type type;\n"
                    + "   List<Exprs> expressions;\n"
                    +  "}"      
                }
            language={"Java"}
            showLineNumbers={false}
            theme={a11yLight}
            wrapLines
            style={{backgroundColor:"white"}}
            /></div><br/>
            <div style={{width:"24%"}}><CodeBlock
            text={
                    "Class Expression{\n" 
                    + "   Type type;\n"
                    + "   Expression left;\n"
                    + "   Expression right;\n"
                    +  "}"      
                }
            language={"Java"}
            showLineNumbers={false}
            theme={a11yLight}
            wrapLines
            style={{backgroundColor:"white"}}
            /></div><br/>
        <TextB color={this.state.color} bg={this.state.bg}>
            This creates a sort of tree-like structure, so a function could potentially look like this:<br/><br/>
            <div style={{width:"24%"}}><CodeBlock
            text={
                    " Function: Double(int a)\n" + 
                    "         |   \n" + 
                    "     Line: Return \n" +
                    "         |       \n" +
                    "     Expr: Mul    \n" + 
                    "       /   \\   \n" + 
                    "    Var    Val  \n" + 
                    "    /\\     /\\  \n" + 
                    "   a  _    2  _  \n"
                }
            language={"Java"}
            showLineNumbers={false}
            theme={a11yLight}
            wrapLines
            style={{backgroundColor:"white"}}
            /></div><br/>
            Where _ = null<br/>
            This would be the original function
            <br/><br/><div style={{width:"24%"}}><CodeBlock
            text={
                "Double(int a){\n" + 
                "   return a * 2;\n" + 
                "}"
                }
            language={"Java"}
            showLineNumbers={false}
            theme={a11yLight}
            wrapLines
            style={{backgroundColor:"white"}}
            /></div><br/>
            When this has been done for the whole text file, it is then passed onto the pre compiler to check for errors and to optimize.
        </TextB>
    </div>
    }

}export default Parser
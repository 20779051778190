import React from "react";
import { Row } from "react-bootstrap";
import HeadC from "../../../../Shared/Text/HeadC";
import HeadB from "../../../../Shared/Text/HeadB";
import TextB from "../../../../Shared/Text/textB";

class InfoScrape extends React.Component{

    constructor(props){
        super(props);
        this.state = {
            body: "",
            color: props.color ? props.color: "black",
            bg: props.bg ? props.bg : "white"
        } 
    }

    render(){
        let x = {color:this.state.color, background:this.state.bg}
        return <div style={x} className="mx-3">
            <Row style={x}>
                <HeadC color={this.state.color} bg={this.state.bg}> Website Scraper</HeadC>
            </Row>
            <Row style={x}>
                <HeadB color={this.state.color} bg={this.state.bg}> Description</HeadB>
            </Row>
            <Row style={x}>
                <TextB color={this.state.color} bg={this.state.bg}>
                To scrape data from the web, I used a library called JSoup, which I would provide with the URL, and it would return a "Document" object.
                This would contain all HTML element nodes, similar to when you inspect a webpage and see structures like div and all of its child
                nodes contained within that div's tags.
                From there, I simply had to navigate to the nodes I wanted and then extract the data.
                </TextB>
            </Row>
        </div>
    }
} export default InfoScrape


import React from "react";
import { Row } from "react-bootstrap";
import HeadB from "../../../../Shared/Text/HeadB";
import HeadC from "../../../../Shared/Text/HeadC";
import TextB from "../../../../Shared/Text/textB";

class RComp extends React.Component{

    constructor(props){
        super(props);
        this.state = {
            body: "",
            color: props.color ? props.color: "black",
            bg: props.bg ? props.bg : "white"
        } 
    }

    render(){
        let x = {color:this.state.color, background: this.state.bg}
        return <div className="mx-3" style={x}>
            <Row style={x}>
                <HeadC color={this.state.color} bg={this.state.bg}> Compiler</HeadC>
            </Row>
            <Row style={x}>
                <HeadB color={this.state.color} bg={this.state.bg}> Description</HeadB>
            </Row>
            <Row style={x}>
                <TextB color={this.state.color} bg={this.state.bg}>
                The compiler's job is to convert the parsed expressions into an instruction set that the stack machine can run.
                To do this, we convert the function structure, the line enum, and the expression enum.
                </TextB>
            </Row>
            <Row style={x}>
                <TextB color={this.state.color} bg={this.state.bg}>
                <HeadB color={this.state.color} bg={this.state.bg}> Functions</HeadB>
                Each function is converted into a list of instructions (see stack machine).
                This is done by converting all of the lines into instructions and adding them all to one list, which becomes the final instruction set for that function.
                <HeadB color={this.state.color} bg={this.state.bg}> Line</HeadB>
                Each line will be converted into an instruction set by matching the type of line to a function that will convert all of its expressions into instructions and then add a few more instructions for the line.
                For example, the return line will compile its expression and then push the command "Return" onto the end of the list.
                <HeadB color={this.state.color} bg={this.state.bg}> Expressions</HeadB>
                Expressions are typically converted to instructions by first converting their left and right expressions to 
                simple values and then adding the instruction for that type of expression.
                For example, the expression Plus(Plus(9,Plus(1,2)),3), which would be like ((9 + (1 + 2)) + 3), or just 9 + 1 + 2 + 3, would become the instruction set.
                <br/>
                | Push 1|<br/>
                | Push 2|<br/>
                | Add   |<br/>
                | Push 9|<br/>
                | Add   |<br/>
                | Push 3|<br/>
                | Add   |<br/>
                </TextB>
            </Row>
        </div>
    }

} export default RComp

import React from "react"
import HeadB from "../../../Shared/Text/HeadB"
import HeadC from "../../../Shared/Text/HeadC"
import TextB from "../../../Shared/Text/textB"
class NetW extends React.Component{

    constructor(props){
        super(props)
        this.state = {
            body: "", 
            color: props.color ? props.color: "black",
            bg: props.bg ? props.bg : "white",
        }
    }


    render(){
        let x = {color:this.state.color, background: this.state.bg}

        return <div className="mx-3" style={x}>
            <HeadC  color={this.state.color} bg={this.state.bg}> Networking</HeadC>
            <TextB  color={this.state.color} bg={this.state.bg}>
            During the networking section 
            <ul style={x}> 
                <li style={x}> Built a tic-tac-toe game that could be played over sockets</li>
                <li style={x}> Built a program that would simulate all the steps of sending a packet using a tcp connection</li>
            </ul>
        </TextB>
        </div>
    }
} export default NetW


import React from "react"
import { Row } from "react-bootstrap"
import HeadC from "../../../Shared/Text/HeadC"
import TextB from "../../../Shared/Text/textB"

class HonDesc extends React.Component{

    constructor(props){
        super(props);
        this.state ={
            color: props.color ? props.color: "black",
            bg: props.bg ? props.bg : "white"
        }
    }

    render(){
        return <div className="mx-3">
            <HeadC color={this.state.color} bg={this.state.bg}>
                Description
            </HeadC>
            <Row>
            <TextB color={this.state.color} bg={this.state.bg}>
                        My honours project was to "Design and Implement a Programming Language".
                        I decided to create a source-to-source compiler thats target language was Java Byte Code. 
                        This means that I wouldn't have to worry about building an interpreter to run the outputed code,
                        I could just use the JVM. 
                        This method is the same as programming languages such as Java, Groovy and Kotlin.
                        To create my language I would only need a program a parser that could parse a text file with code writen in the lexer of my language 
                        and then compile that into Java byte code, which could then be run in the JVM.
                        This also has the added benefit that I could use libraries and files written in Java with ones writen in my own language and vice versa, 
                        as both Java and my language have the same target language.
                    </TextB>
            </Row>
        </div>
    }

} export default HonDesc
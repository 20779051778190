import React from "react";
import { Container, Row } from "react-bootstrap";
import TextB from "../../Shared/Text/textB";
import HeadC from "../../Shared/Text/HeadC";

class GameServer extends React.Component{

    constructor(props){
        super(props);
        this.state = {
            body: "",
            color: props.color ? props.color: "black",
            bg: props.bg ? props.bg : "white"
        } 
    }

    render(){
        let x = {color:this.state.color, background:this.state.bg}
        return <div style={x} className="mx-3">
                <Row style={x}>
                    <HeadC color={this.state.color} bg={this.state.bg}> Description </HeadC>
                </Row>
                <Row style={x}> 
                    <TextB color={this.state.color} bg={this.state.bg}>
                        This project is for the purposes of learning ASP.net, Flutter, and more about hosting projects online using things such as AWS, Azure, and Linode.
                        The project is to create a server that will be hosted on the internet that will allow anyone to play turn-based games across the internet and 
                        allow others to spectate the game by having the web API designed using ASP.net act as a server that maintains a number of websocket connections and 
                        sends moves to the server that will send out the updated board to all websocket connections viewing that game.
                        The front end of the app is designed using Flutter for a few reasons
                        <ol style={x}>
                            <li style={x}> You can create a website, desktop app, mobile app (android and IOS) all at once in one code base </li>
                            <li style={x}> Dart the language used in flutter is a language that is designed with the purpose of building front ends which makes it a lot nicer to use than something like   </li>                             
                        </ol> 
                    </TextB>
                </Row>
        </div>
    }
} export default GameServer
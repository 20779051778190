import React from "react";
import { Container, Row} from "react-bootstrap";
import InfoScrape from "./Description/Scraper/InfoSrape";
import BotInteraction from "./Description/Bot/BotInteraction";
import Sections from "../../Shared/Util/Sections";
import HeadC from "../../Shared/Text/HeadC";
import TextB from "../../Shared/Text/textB";
import IStorage from "./Description/Storage/InfoStorage";

class DiscordBot extends React.Component{

    constructor(props){
        super(props);
        this.state = {
            body: "",
            color: props.color ? props.color: "black",
            bg: props.bg ? props.bg : "white"
        } 
    }

    render(){
        let x = {color:this.state.color, background:this.state.bg}
        return <div style={x} className="mx-3">
            <Row style={x}>
                <HeadC color={this.state.color} bg={this.state.bg}> Description </HeadC>
            </Row>
            <Row style={x}>
                <TextB color={this.state.color} bg={this.state.bg}>
                    This project was created to make finding out stats about the League of Legends World Championship 2022 easier 
                    and do able inside of discord chat to make the information to available to anyone in the chat. 
                    The stats that are available are centred around the "Pick em" <a style={x} href="https://www.esports.net/news/worlds-pickem-predictions/" >event</a> that was run during the tornament 
                    where you would try and guess what person/champion would have the highest or lowest stat. <br/>
                    The website used where
                    <ul style={x}>
                        <li style={x}> <a style={x} href="https://lol.fandom.com/wiki/"> Lol Fandom</a> </li> 
                        <li style={x}> <a style={x} href="https://www.rotowire.com/esports/tables/">Rotowire </a></li>
                        <li style={x}> <a style={x} href="https://gol.gg/tournament/"> Game of Legends</a></li>
                    </ul>
                </TextB>
            </Row>
        </div>
    }
} export default DiscordBot
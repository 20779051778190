import React from "react";
import TextB from "../../../../Shared/Text/textB";
import HeadC from "../../../../Shared/Text/HeadC";
import HeadB from "../../../../Shared/Text/HeadB";

class SerFront extends React.Component{

    constructor(props){
        super(props);
        this.state = {
            body: "",
            color: props.color ? props.color: "black",
            bg: props.bg ? props.bg : "white"
        } 
    }

    render(){
        let x = {color:this.state.color, background:this.state.bg}
        return <div style={x} className="mx-3">
            <HeadC color={this.state.color} bg={this.state.bg}> Front end </HeadC>
            <TextB color={this.state.color} bg={this.state.bg}>
            The front end, i.e. the part the user will interact with, is built in Flutter. It is made of 2 main pages: the login page and the game display page,
            and there are also pages for playing the games.
            </TextB>
            <br/>
        </div>
    }
} export default SerFront 



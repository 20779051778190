import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Checklist from "../../Shared/Util/CheckList";
import HeadC from "../../Shared/Text/HeadC";
import HeadS from "../../Shared/Text/HeadS";
import Tags from "../../Shared/Util/Tags";
import TextB from "../../Shared/Text/textB";
import TagFactory from "../../Shared/Util/TagFactory";

class WebSpec extends React.Component{

    constructor(props){
        super(props);
        this.state = {
            body: "",
            color: props.color ? props.color: "black",
            bg: props.bg ? props.bg : "white"
        } 
    }

    render(){
        let x = {color:this.state.color, background:this.state.bg}
        return <div className="mx-3" style={x}>
                <TextB color={this.state.color} bg={this.state.bg} >
                    <HeadC color={this.state.color} bg={this.state.bg} > Specifiation</HeadC>
                    <HeadS color={this.state.color} bg={this.state.bg} > Final Goal</HeadS>
                    To build a tool for creating webpages using a CLI.
                    <HeadS color={this.state.color} bg={this.state.bg} > Technoloogy Used</HeadS>
                </TextB> 
                <Row style={x}> 
                    <Col style={x} sm={2}><TextB color={this.state.color} bg={this.state.bg}> Project Type : </TextB></Col>
                    <Col style={x}><Row style={x}>{TagFactory.makeTags("t", "Personal").concat(TagFactory.makeTags("ts", "Solo"))} </Row> </Col>
                </Row>
                <Row style={x}> 
                    <Col style={x} sm={2}><TextB color={this.state.color} bg={this.state.bg}> Language : </TextB></Col>
                    <Col style={x}><Row style={x}>{TagFactory.makeTags("l", "Java")} </Row></Col>
                </Row>
                <Row style={x}> 
                    <Col style={x} sm={2}><TextB color={this.state.color} bg={this.state.bg}> Technoloogy used : </TextB></Col>
                    <Col><Row style={x}>{TagFactory.makeTags("to","Git, Maven")} </Row></Col>
                </Row>
                    <TextB color={this.state.color} bg={this.state.bg}>
                    <HeadS color={this.state.color} bg={this.state.bg}> Goals</HeadS>
                    <Checklist 
                    name = "Project Goals"
                    items={
                        [
                            {done:true, message:"Build a tool to create HTML files"},
                            {done:true, message:"Build a tool to create CSS files" },
                            {done:true, message:"Build a tool to create JS files."},
                            {done:true, message:"Build a command-line interface to use the tools."},
                        ]
                    }/>
                    <br/>
                    <Checklist 
                    name = "HTML"
                    items={
                        [
                            {done:true, message:"Build a tool that can create and populate HTML-like objects."},
                            {done:true, message:"Build a tool that can create actual HTML from the objects."},
                        ]
                    }/>
                    <br/>
                    <Checklist 
                    name = "CSS"
                    items={
                        [
                            {done:true, message:"Build a tool that allows you to add 57 styles to HTML, classes, and IDs."},
                            {done:true, message:"Build a tool that can create a CSS file from the objects."},
                        ]
                    }/><br/>
                    <Checklist 
                    name = "JS"
                    items={
                        [
                            {done:true, message:"Build a tool for creating JS functions."},
                            {done:false, message:"Build a tool for adding and populating lines to functions."},
                            {done:false, message:"Build a tool to create a JS file from the functions."},
                        ]
                    }/>
                    <br/>
                    <Checklist 
                    name = "Interfaces"
                    items={
                        [
                            {done:true, message:"Build a main interface as a starting point."},
                            {done:true, message:"Build interfaces for dealing with HTML."},
                            {done:true, message:"Build interfaces for dealing with CSS."},
                            {done:false, message:"Build interfaces for dealing with JS."},
                        ]
                    }/>
                    <br/>
                </TextB>
        </div>
    }
} export default WebSpec 
import React from "react";
import { Row } from "react-bootstrap";
import HeadC from "../../Shared/Text/HeadC";
import TextB from "../../Shared/Text/textB";

class StatsWebsite extends React.Component{

    
  constructor(props){
    super(props);
    this.state = {
        body: "",
        color: props.color ? props.color: "black",
        bg: props.bg ? props.bg : "white"
    } 
}

    render(){
        let x = {color:this.state.color, background:this.state.bg}
        return <div className="mx-3" style={x}>
                <Row style={x}>
                    <HeadC color={this.state.color} bg={this.state.bg}>
                        Description
                    </HeadC>
                </Row>
                <Row style={x}>
                    <TextB color={this.state.color} bg={this.state.bg}>
                    This is a project I created to view mine and others' stats in a game called League of Legends. 
                    It uses the official API to gather information about matches played and stores it in a MySQL database. 
                    I built an API using Java Spring Boot to extract the information from the database.
                    The website I created uses the API to retrieve the information the user requests and return it in an easy-to-read format. <br/>
                    <span style={{fontWeight:"600", color:this.state.color, background:this.state.bg}}>Below is more information about the differnt sections of this project</span>
                    </TextB>
                </Row>
        </div>
    }
} export default StatsWebsite
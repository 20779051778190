import React from "react";
import { Col, Row } from "react-bootstrap";
import { CopyBlock, dracula } from "react-code-blocks";
import HeadB from "../../../Shared/Text/HeadB";
import HeadC from "../../../Shared/Text/HeadC";
import TextB from "../../../Shared/Text/textB";

class Wcss extends React.Component{

    
    constructor(props){
        super(props);
        this.state = {
            body: "",
            color: props.color ? props.color: "black",
            bg: props.bg ? props.bg : "white"
        } 
    }

    render(){
        let x = {color:this.state.color, background:this.state.bg}
        return <div style={x} className="mx-3">
            <HeadC color={this.state.color} bg={this.state.bg}> CSS/JS</HeadC>
            <HeadB color={this.state.color} bg={this.state.bg}> Description</HeadB>
            <TextB color={this.state.color} bg={this.state.bg}>
                The CSS and JS files of the webpage are generated from objects created in the CLI.
                <br/>
                The objects are <br/>
                CSS
                <ul style={x}>
                    <li style={x}> CSSElement</li>
                </ul>
                JS
                <ul style={x}>
                    <li style={x}> Functions</li>
                    <li style={x}> Lines</li>
                </ul>
                <HeadB color={this.state.color} bg={this.state.bg}> CSSElement</HeadB>
                CSSElement is a structure that represents a block in CSS, like
                <CopyBlock
                    text={
                        "h1 {" + 
                        "  color: \"black\";" + 
                        "}"
                    }
                    language={"css"}
                    showLineNumbers={false}
                    theme={dracula}
                    wrapLines
                />
                The fields it stores are 
                <ul style={x}>
                    <li style={x}> Name: The name of the tag you want to style, i.e., "h1"</li>
                    <li style={x}> State: The state of the tag when you want to apply these styles, i.e., hover, active, or none</li> 
                    <li style={x}> Type: Whether the tag is an ID, a class, or an HTML element. </li>
                    <li style={x}> Styles: The styles you want to apply</li>
                </ul>
                <HeadB color={this.state.color} bg={this.state.bg}> Styles</HeadB>
                Styles are the properties you want to change, i.e., color, background, font-size, etc.
                All of the available styles have been broken into different groups to make finding the one you want easier.
                The groups and their members are shown below.
                <Row style={x}>
                    <Col style={x}>
                        Size 
                        <ul style={x}>
                            <li style={x}>width</li>
                            <li style={x}>height</li>
                            <li style={x}>min_width</li>
                            <li style={x}>max_width</li>
                            <li style={x}>min_height</li>
                            <li style={x}>max_height</li>
                        </ul>
                    </Col>
                    <Col style={x}>
                        Colour 
                        <ul style={x}>
                            <li style={x}>background_colour</li>
                            <li style={x}>colour</li>
                        </ul>
                    </Col>
                    <Col style={x}> 
                        Margins
                        <ul style={x}>
                            <li style={x}>margin</li>
                            <li style={x}>margin_top</li>
                            <li style={x}>margin_right</li>
                            <li style={x}>margin_bottom</li>
                            <li style={x}>margin_left</li>
                        </ul>
                    </Col>
                    <Col style={x}>
                        Padding 
                        <ul style={x}>
                            <li style={x}>padding</li>
                            <li style={x}>padding_top</li>
                            <li style={x}>padding_right</li>
                            <li style={x}>padding_bottom</li>
                            <li style={x}>padding_left</li>
                        </ul>
                    </Col>
                    <Col style={x}>
                        Border 
                        <ul style={x}>
                            <li style={x}>border</li>
                            <li style={x}>border_top</li>
                            <li style={x}>border_left</li>
                            <li style={x}>border_bottom</li>
                            <li style={x}>border_right</li>
                        </ul>
                    </Col>
                    <Col style={x}>
                        Border Style 
                        <ul style={x}>
                            <li style={x}>border_style</li>
                            <li style={x}>border_top_style</li>
                            <li style={x}>border_right_style</li>
                            <li style={x}>border_bottom_style</li>
                            <li style={x}>border_left_style</li>
                        </ul>
                    </Col>
                    <Col style={x}>
                        Border Other 
                        <ul style={x}>
                            <li style={x}>border_radius</li>
                            <li style={x}>border_width</li>
                            <li style={x}>border_colour</li>
                        </ul>
                    </Col>
                    <Col style={x}>
                        Outline 
                        <ul style={x}>
                            <li style={x}>outline</li>
                            <li style={x}>outline_style</li>
                            <li style={x}>outline_color</li>
                            <li style={x}>outline_width</li>
                            <li style={x}>outline_offset</li>
                        </ul>
                    </Col>
                    <Col style={x}>
                        Text 
                        <ul style={x}>
                            <li style={x}>text_transform</li>
                            <li style={x}>text_indent</li>
                            <li style={x}>text_shadow</li>
                            <li style={x}>text_align</li>
                        </ul>
                    </Col>
                    <Col style={x}>
                        Letter 
                        <ul style={x}>
                            <li style={x}>letter_spacing</li>
                            <li style={x}>line_height</li>
                            <li style={x}>word_spacing</li>
                            <li style={x}>white_space</li>
                        </ul>
                    </Col>
                    <Col style={x}>
                        Font 
                        <ul style={x}>
                            <li style={x}>font_family</li>
                            <li style={x}>font_size</li>
                            <li style={x}>font_weight</li>
                            <li style={x}>font_variant</li>
                        </ul>
                    </Col>
                    <Col style={x}>
                        List 
                        <ul style={x}>
                            <li style={x}>list_style_type</li>
                            <li style={x}>list_style_image</li>
                            <li style={x}>list_style_position</li>
                            <li style={x}>list_style</li>
                        </ul>
                    </Col>
                    <Col style={x}> 
                        <ul style={x}>
                            <li style={x}>visibility</li>
                            <li style={x}>display</li>
                            <li style={x}>position</li>
                            <li style={x}>z_index</li>
                            <li style={x}>overflow</li>
                            <li style={x}>box_sizing</li>

                        </ul>
                    </Col>
                </Row>
                <HeadB color={this.state.color} bg={this.state.bg}> Functions</HeadB>
                Function Object is the represenents for a function of javascript. 
                Its fields are 
                <ul style={x}>
                    <li style={x}> Name </li>
                    <li style={x}> Parameters </li> 
                    <li style={x}> Lines</li>
                </ul>
                <HeadB color={this.state.color} bg={this.state.bg}> Lines </HeadB>
                Lines are structures that are meant to represent a line of JavaScript. 
                The various types of lines are 
                <ul style={x}>
                    <li style={x}> For Each</li>
                    <li style={x}> For</li>
                    <li style={x}> If</li>
                    <li style={x}> HTML Element Edit</li>
                    <li style={x}> Style Edit</li>
                    <li style={x}> Variable declare</li>
                    <li style={x}> Variable change</li>
                    <li style={x}> While Loop</li>
                    <li style={x}> Function Call</li> 
                </ul>
                These lines will have different fields that will be made of expressions and other lines. 
                <HeadB color={this.state.color} bg={this.state.bg}> Expressions</HeadB>
                The expressions are structures meant to represent all of the small pieces. 
                There are different types of expressions, such as operands, syntax, etc.
                Below is a list of all expressions.
                <Row style={x}>
                    <Col style={x}>
                        <ul style={x}>
                            <li style={x}>Add</li>
                            <li style={x}>Minus</li>
                            <li style={x}>Multiply</li>
                            <li style={x}>Divide</li> 
                            <li style={x}>Or</li>
                        </ul>
                    </Col>
                    <Col style={x}>
                        <ul style={x}>
                            <li style={x}>Less Than</li>
                            <li style={x}>Greater Than</li>
                            <li style={x}>Less Than or Equal To</li>
                            <li style={x}>Greater Than or Equal To</li>
                            <li style={x}>Not</li>
                        </ul>
                    </Col>
                    <Col style={x}>
                        <ul style={x}>
                            <li style={x}>Value</li>
                            <li style={x}>Get ID</li>
                            <li style={x}>Bracketed Expression</li>
                            <li style={x}>And</li>
                        </ul>
                    </Col>
                </Row>
                These expressions are all made of other expressions, making a tree like structure until it reaches the leaf/value expressions.
            </TextB>
        </div>
    }

} export default Wcss
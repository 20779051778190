import React from "react";
import { Row } from "react-bootstrap";
import HeadC from "../../Shared/Text/HeadC";
import TextB from "../../Shared/Text/textB";

class WebpageBuild extends React.Component{

    constructor(props){
        super(props);
        this.state = {
            body: "",
            color: props.color ? props.color: "black",
            bg: props.bg ? props.bg : "white"
        } 
    }

    render(){
        let x = {color:this.state.color, background:this.state.bg}
        return <div style={x} className="mx-3">
            <Row style={x}>
                <HeadC color={this.state.color} bg={this.state.bg} > Description</HeadC>
                </Row>
                <Row style={x}>
                    <TextB color={this.state.color} bg={this.state.bg} >
                    This project was done to help me get a better understanding of using a variety of design patterns such as builders, factories, etc.
                    The project was to create a command-line interface that would allow a user to easily build a webpage that contained HTML, CSS, and Javascript.
                    </TextB>
                </Row>
        </div>
    }
} export default WebpageBuild
import React from "react";
import { Container, Row, Col, Card, Button } from "react-bootstrap";
import Navibar from "../Shared/Util/NavBar";
import Email from "../Shared/Photos/CV/email.png";
import Git from "../Shared/Photos/CV/git2.png";
import link from "../Shared/Photos/CV/link2.png";
import { motion } from "framer-motion";
import ProjectMaker from "../ProjectsN/ProjectMaker";
import tn from "../Shared/Photos/Hons/Thumbnail.png"
import stats from "../Shared/Photos/Stats/Home.png";
import sev from "../Shared/Photos/GameServer/LoginPage.png"
import port from "../Shared/Photos/Home.png";
import java from "../Shared/Photos/About/java.png"
import edu from "../Shared/Photos/About/Education.png"
import c from "../Shared/Photos/About/c#.png"
import net from "../Shared/Photos/About/csharp.png"
import css from "../Shared/Photos/About/css.png"
import flutter from "../Shared/Photos/About/flutter.png"
import git from "../Shared/Photos/About/git.png"
import haskell from "../Shared/Photos/About/haskell.png"
import html from "../Shared/Photos/About/HTML.png"
import js from "../Shared/Photos/About/js.png"
import linode from "../Shared/Photos/About/linode.png"
import python from "../Shared/Photos/About/python.png"
import react from "../Shared/Photos/About/react.png"
import spring from "../Shared/Photos/About/spring.png"
import ubuntu from "../Shared/Photos/About/ubuntu.png"
import compile from "../Shared/Photos/About/compile.png"
import { Link } from "react-router-dom";

class About extends React.Component{

    render() {
        let x ;
        let width = window.innerWidth < window.innerHeight ? "100%" : "30%";
        let func = window.innerWidth > window.innerHeight ? ProjectMaker.makeProjectCard : ProjectMaker.makeSmallProject
        let y = <><Row style={{width:width}}>
                    <Col>
                        <span style={{fontSize:"4.5vh", color:"rgb(212, 122, 122)", background:"transparant"}}> Email<br/></span>
                        <img src={Email}  style={{aspectRatio:"inherit", width:"20vh"}}/><br/>
                        <span style={{fontSize:"3vh", color:"rgb(212, 122, 122)", background:"transparant"}}>thomas-williams-1@hotmail.com</span>
                    </Col>
                </Row>
                <Row style={{width:width}}>
                    <Col>
                        <span style={{fontSize:"4.5vh", color:"rgb(212, 122, 122)", background:"transparant"}}> Github<br/> </span>
                        <img src={Git} style={{aspectRatio:"inherit", height:"20vh"}}/><br/>
                        <a style={{fontSize:"3vh"}} href="https://github.com/NineSixFourteen"> NineSixFourteen </a>
                    </Col>
                </Row>
                <Row style={{width:width}}>
                    <Col>
                        <span style={{fontSize:"4.5vh", color:"rgb(212, 122, 122)", background:"transparant"}}> linkedin<br/></span>
                        <img src={link}  style={{aspectRatio:"inherit", height:"20vh"}}/><br/>
                        <a style={{fontSize:"3vh"}}href="https://www.linkedin.com/in/thomas-w-40020b1bb/">Thomas Williams</a>
                    </Col>
                </Row>;
                </>
        if(window.innerWidth > window.innerHeight){
            x = <Row className="m-4" style={{justifyContent:"center", textAlign:"center"}}>
                {y}
            </Row>
        } else {
            x = <Col className="m-4" style={{justifyContent:"center", textAlign:"center"}}>
                {y}
            </Col>
        }
        return (
            <motion.div exit={{width:"0", transition:1.2, opacity:0}} style={{position:"relative", top:"14vh"}}>
            <Navibar/>
            <motion.main
                initial={{opacity:0, width:0}}
                animate={{opacity:1, width:"100%"}}
                exit={{opacity:0, transition:5}}
            >
            <Container>
                <motion.p>
                <span className="mx-4" style={{fontSize:"8vh", paddingTop:"10vh", color:"rgb(212, 122, 122)"}}>
                    Information
                </span><br/>
                <span className="mx-4" style={{fontSize:"5vh", paddingTop:"2vh", color:"rgb(212, 122, 122)"}}>
                    About me
                </span>
                </motion.p>
                <Card className="m-4" style={{background:"transparent", border:"2px solid rgb(212, 122, 122)"}}>
                    <Card.Body>
                        <Row>
                        <Col > 
                            <motion.p className="p-1" style={{color:"white", fontSize:"3vh"}} >
                            I am someone who is obsessed with technology and has been since I was 13 years old. I have loved using many different technologies 
                            in order to build projects that I want to create for myself and others. Programming is something that I do most days as I enjoy the
                             challenges that arise from my projects and all the skills I can learn as technology is constantly growing and changing, 
                            meaning there is always something fun and exciting to learn.
                            </motion.p>
                        </Col>
                        </Row>
                    </Card.Body>
                </Card>
                <span className="mx-4" style={{fontSize:"5vh", paddingTop:"3vh", color:"rgb(212, 122, 122)"}}>
                    Education
                </span><br/>
                <img src={edu} style={{width:"60vw"}}></img><br/>

                <span className="mx-4" style={{fontSize:"8vh", paddingTop:"3vh", color:"rgb(212, 122, 122)"}}>
                    Tech Skills
                    <Row className="px-4">
                        <Skill Name={"Java"} Image={java}/>  
                        <Skill Name={"Compiler"} Image={compile}/>
                        <Skill Name={"C#"} Image={c}/>  
                        <Skill Name={"Flutter/Dart"} Image={flutter}/>  
                        <Skill Name={"Python"} Image={python}/>  
                        <Skill Name={"Haskell"} Image={haskell}/>  
                        <Skill Name={"HTML"} Image={html}/> 
                        <Skill Name={"CSS"} Image={css}/>  
                        <Skill Name={"JS"} Image={js}/> 
                        <Skill Name={"React"} Image={react}/>  
                        <Skill Name={"ASP.net"} Image={net}/>  
                        <Skill Name={"Spring"} Image={spring}/>  
                        <Skill Name={"Git"} Image={git}/>  
                        <Skill Name={"Linode"} Image={linode}/>  
                        <Skill Name={"Ubuntu"} Image={ubuntu}/>  
                    </Row>
                </span>

                <div>
                <span style={{fontSize:"10vh", paddingTop:"10vh", color:"rgb(212, 122, 122)"}}>
                    Projects
                </span><br/>
                {func(
                    {
                        Title :  "Honours Project",
                        Category : "CLI, Compiler",  
                        Type  : "University",
                        Language : "Java",
                        Tools : "Maven, Git, LaTeX",
                        TeamSize : "Solo",
                        Other : "ASM",
                        Description : "A source-to-source Java bytecode compiler I wrote for my honours project, whose title was \"Design and Implement a Programming Language.\" For this project, I worked closely with an advisor.",
                        link : "/projects/Honours"
                    },tn, 1.1
                )}
                {func(
                    {
                        "Title" :  "Stats Website",
                        "Category" : "Website, Web-API",  
                        "Type"  : "Personal",
                        "Language" : "Java, JavaScript",
                        "Tools" : "MySql",
                        "TeamSize" : "Solo",
                        "Other" : "Spring Boot, Riot API, React, Git",
                        "Description" : "A website/tool for analysing the match history and stats of players in League of Legends ",
                        "link": "/projects/Stats"
                    },stats, 1.1
                )}
                {func(
                    {
                        Title : "Personal Website",
                        Category : "Website",  
                        Type : "Personal",
                        Language: "JavaScript",
                        Tools : "Git",
                        TeamSize : "Solo",
                        Other : "None, React",
                        Description : "The website you are on right now I built this to have a place to record and show off my projects.",
                        link : "/"
                    },port, 1.1
                )}
                {func(
                    {
                        Title : "Game Server",
                        Category : "Web-API, Website, Desktop, Mobile, Hosted",  
                        Type  : "Personal",
                        Language : "C#, Dart",
                        Tools : "Flutter, Git, Nginx, AWS, Linode, MySql",
                        TeamSize : "Solo",
                        Other : "None",
                        Description : "This project is a game server that allows you to play turn-based games online. The project was done to learn how to use AWS rds and how to use a Linode VM to host a web API. There is a mobile and desktop app available for this project that is done in Flutter.",
                        link : "/projects/GameServer"
                    },sev, 1.1
                )}
                </div>
                <Link style={{textDecoration:"none"}} to={"/projects/all"}><Button style={{fontSize:"4vh", width:"100%"}}> More Projects</Button></Link>
                <div style={{textAlign:"center", justifyContent:"center", alignItems:"center"}}>
                    <span style={{fontSize:"10vh", color:"rgb(212, 122, 122)", background:"transparant"}}> Any Questions?, Contact me</span>
                </div>
                {x}
                
            </Container>
            </motion.main>
            </motion.div>
        );
      }

}export default About;

class Skill extends React.Component{

    constructor(props){
        super(props);
        this.state = {
            Name: props.Name,
            Image: props.Image
        }
    }

    render(){
        return <Col className="m-3" style={{Width:"10vw",fontSize:"3vh", color:"rgb(212, 122, 122)", justifyContent:"center", textAlign:"center"}}>
                <motion.img 
                    initial={{ opacity:0, height:0}}
                    transition={{duration:1}}
                    whileInView={{ opacity:1,  height:"80%"}}
                    src={this.state.Image} style={{width:"10vw"}}
                />  
            <motion.div
                    initial={{opacity:0}}
                    ransition={{delay:2, duration:1}}
                    whileInView={{opacity:1}}
                >
                <span style={{color:"white"}}>{this.state.Name}</span>
            </motion.div>
        </Col>
    }
}

import React from "react";
import HeadC from "../../Shared/Text/HeadC";
import HeadZ from "../../Shared/Text/HeadZ";
import TextB from "../../Shared/Text/textB";
import g2048 from "../../Shared/Photos/2048/GameC.png"


class GameOver extends React.Component{

    constructor(props){
        super(props);
        this.state ={
            color: props.color ? props.color: "black",
            bg: props.bg ? props.bg : "white"
        }
    }

    render(){
        return <div className="mx-3" style={{color:this.state.color, background:this.state.bg}}>
            <HeadZ color={this.state.color} bg={this.state.bg}> 2048(AI, GUI)</HeadZ>
            <div style={{display:"grid", justifyContent:"center", alignContent:"center", color:this.state.color, background:this.state.bg}}>
            <img src={g2048} style={{width:"30vw"}}/>
            </div>
            <HeadC color={this.state.color} bg={this.state.bg}> Project Goal </HeadC>
            <TextB color={this.state.color} bg={this.state.bg}>
                The goal of this project was initially to create an AI capable of playing 2048,
                but I added a goal of creating a GUI for the AI. Later on, I recreated the programme in C# to compare the speeds of the AI.
            </TextB>
            <HeadC color={this.state.color} bg={this.state.bg}> Project Outcome </HeadC>
            <TextB color={this.state.color} bg={this.state.bg}>
                I completed all the goals I set and added overtime and was able to show a significant performance gain in C#, especially after I added concurrency to the program.            </TextB>
        </div>
    }
} export default GameOver
import React from "react";
import { Row, Col} from "react-bootstrap";
import HeadB from "../../../../Shared/Text/HeadB";
import HeadC from "../../../../Shared/Text/HeadC";
import TextB from "../../../../Shared/Text/textB";
class Tokens extends React.Component{

    constructor(props){
        super(props);
        this.state = {
            body: "",
            color: props.color ? props.color: "black",
            bg: props.bg ? props.bg : "white"
        } 
    }

    render(){
        let x = {color:this.state.color, background: this.state.bg}

        return <div className="mx-3" style={x}>
            <HeadC color={this.state.color} bg={this.state.bg}> Tokens</HeadC>
            <TextB color={this.state.color} bg={this.state.bg}>
            The tokens are what define the lexer it is all of the keywords, special charaters{"(){}:;"}, etc. 
            Tokens are created by going through the source code provided and then looking for either the individual characters used or certain words.
            <HeadB color={this.state.color} bg={this.state.bg}> Keywords</HeadB>
            The keywords in the language are usually things such as types, commands (if, for, return), or declaring a new function, and they are all case-sensitive.     
            <Row style={x}>
                <Col style={x}>
                    <ul style={x}>
                        <li style={x}>int</li>
                        <li style={x}>boolean</li>
                        <li style={x}>char</li>
                        <li style={x}>String</li>
                    </ul>
                </Col>
                <Col style={x}>
                    <ul style={x}>
                    <li style={x}>Print</li>
                        <li style={x}>for</li>
                        <li style={x}>else</li>
                        <li style={x}>return</li>
                    </ul>
                </Col>
                <Col style={x}>
                <ul style={x}>
                    <li style={x}>def</li>
                </ul>
                </Col>
            </Row>
            <HeadB color={this.state.color} bg={this.state.bg}> Special characters</HeadB>
            The special characters used are similar to those in other languages, with things like math symbols, logic symbols, and commonly used symbols such as commas or brackets.            <Row>
                <Col style={x}>
                <ul style={x}>
                    <li style={x}> Plus: +</li>
                    <li style={x}> Minus: -</li>
                    <li style={x}> Multiple: * </li>
                    <li style={x}> Divide: /</li>
                    <li style={x}> Bitwise And: &</li>
                    <li style={x}> Bitwise Or: |</li>
                    <li style={x}> Increment: ++</li>
                    <li style={x}> Decrement: --</li>
                </ul>
                </Col>
                <Col style={x}>
                <ul style={x}>
                    <li style={x}>Not: !</li>
                    <li style={x}>Boolean And: &&</li>
                    <li style={x}>Boolean Or: ||</li>
                    <li style={x}>Less Than: {"<"}</li>
                    <li style={x}>Greater Than: {">"}</li>
                    <li style={x}>Less Than or Equal To: {"<="}</li>
                    <li style={x}>Greater Than or Equal To: {">="}</li>
                    <li style={x}>Equals(Assign): =  or Equal(Compare): ==</li>
                </ul>
                </Col>
                <Col style={x}>
                <ul style={x}>
                    <li style={x}> Left Bracket: {"("}</li>
                    <li style={x}> Right Bracket: {")"}</li>
                    <li style={x}> Left Square Bracket: {"["}</li>
                    <li style={x}> Right Square Bracket: {"]"}</li>
                    <li style={x}> Left Curly Bracket: {"{"}</li>
                    <li style={x}> Right Curly Bracket: {"}"}</li>
                    <li style={x} > Comma: ,</li>
                    <li style={x}> Semi Colan: ;</li> 
                </ul>
                </Col>
            </Row>
        </TextB>
        </div>
    }
} export default Tokens

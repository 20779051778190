import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Checklist from "../../Shared/Util/CheckList";
import HeadC from "../../Shared/Text/HeadC";
import HeadS from "../../Shared/Text/HeadS";
import Tags from "../../Shared/Util/Tags";
import TextB from "../../Shared/Text/textB";
import TagFactory from "../../Shared/Util/TagFactory";

class DiscordSpec extends React.Component{

    constructor(props){
        super(props);
        this.state = {
            body: "",
            color: props.color ? props.color: "black",
            bg: props.bg ? props.bg : "white"
        } 
    }

    render(){
        let x = {color:this.state.color, background:this.state.bg}
        return <div style={x} className="mx-3">
                <TextB color={this.state.color} bg={this.state.bg} >
                    <HeadC color={this.state.color} bg={this.state.bg}> Specifiation</HeadC>
                    <HeadS color={this.state.color} bg={this.state.bg}> Final Goal</HeadS>
                    To build a bot that a user could easily use to share stats about Worlds 2022 in a Discord chat.
                    <HeadS color={this.state.color} bg={this.state.bg}> Technoloogy Used</HeadS>
                </TextB> 
                <Row style={x}> 
                    <Col style={x} sm={2}><TextB color={this.state.color} bg={this.state.bg}> Project Type : </TextB></Col>
                    <Col style={x}><Row style={x}>{TagFactory.makeTags("t", "Personal").concat(TagFactory.makeTags("ts", "Solo"))} </Row> </Col>
                </Row>
                <Row style={x}> 
                    <Col style={x} sm={2}><TextB color={this.state.color} bg={this.state.bg}> Language : </TextB></Col>
                    <Col style={x}><Row style={x}>{TagFactory.makeTags("l", "Java")} </Row></Col>
                </Row>
                <Row style={x}> 
                    <Col style={x} sm={2}><TextB color={this.state.color} bg={this.state.bg}> Technoloogy used : </TextB></Col>
                    <Col><Row style={x}>{TagFactory.makeTags("to","Git, Maven, JDA")} </Row></Col>
                </Row>
                    <TextB color={this.state.color} bg={this.state.bg}>
                    <HeadS color={this.state.color} bg={this.state.bg}> Goals</HeadS>
                    <Checklist 
                    name = "Project Goals"
                    items={
                        [
                            {done:true, message:"Build a tool to scrape information off the internet."},
                            {done:true, message:"Build a storage system with querying capabilities." },
                            {done:true, message:"Build a Discord bot frontend."},
                        ]
                    }/>
                    <br/>
                    <Checklist 
                    name = "Scrape Information"
                    items={
                        [
                            {done:true, message:"Retrieve information about players."},
                            {done:true, message:"Retrieve information about champions."},
                            {done:true, message:"Retrieve information about the event."},
                        ]
                    }/>
                    <br/>
                    <Checklist 
                    name = "Data Storage"
                    items={
                        [
                            {done:true, message:"Create classes for all data."},
                            {done:true, message:"Build a tool to combine records."},
                            {done:true, message:"Build a tool to query the information."},
                        ]
                    }/><br/>
                    <Checklist 
                    name = "Discord Bot"
                    items={
                        [
                            {done:true, message:"Create commands to display information about players."},
                            {done:true, message:"Create commands to display information about champions."},
                            {done:true, message:"Build interfaces to query the information about champions and display it."},
                            {done:true, message:"Create commands to display information about the event."},
                        ]
                    }/>
                    <br/>
                </TextB>
        </div>
    }
} export default DiscordSpec 
import React from "react";
import HeadC from "../../Shared/Text/HeadC";
import TextB from "../../Shared/Text/textB";

class WebPlan extends React.Component{

    constructor(props){
        super(props);
        this.state ={
            color: props.color ? props.color: "black",
            bg: props.bg ? props.bg : "white"
        }
    }

    render(){
        return <div className="mx-3">
            <HeadC color={this.state.color} bg={this.state.bg}> Additional Goals</HeadC>
            <TextB color={this.state.color} bg={this.state.bg}>
                Any additional goals for this project would be to first improve the javascript support in the command line as it is very limited and seconds would 
                be to more expand on the components you can and expanding them beyond basic HTML aiming for somthing closer to what bootstrap offers or simple adding support for using 
                a CSS tool such as tailwind or bootstrap would greatly improve the sites the tool could create. 
            </TextB>
        </div>
    }
} export default WebPlan
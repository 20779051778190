import { Route, Routes, useLocation } from "react-router-dom";
import CV from './CV/CV';
import About from './About/About';
import Home from "./Home/Home";
import NeProjects from "./ProjectsN/newProjects";
import Unknown from './Unknown'

import StatPage from "./PersonalProjects/StatsWebsite/StatsPage";
import DiscordPage from './PersonalProjects/DiscordBot/DiscordPage';
import GamPage from './PersonalProjects/Game2048/G2048Page';
import ToyPage from './PersonalProjects/ToyLang/ToyPage';
import WebPage from './PersonalProjects/WebpageBuild/WebpagePage';

import HonsPage from './UniProjects/Hons/HonsPage';
import BMPage from './UniProjects/BM/BMPage';
import FunPage from './UniProjects/FunctionalP/FunPPage';
import WAMPage from './UniProjects/WAM/WAMPang';
import ConPage from './UniProjects/Con&Net/ConPage';

import SerPage from './PersonalProjects/GameServer/SerPage';


import { AnimatePresence } from "framer-motion";
import NewProjPage from "./Shared/Util/newProjPage";

function AnimatedRoutes(){
    const location = useLocation();
    return (
        <AnimatePresence mode="wait"  >    
            <Routes location={location} key={location.pathname}>
                <Route path="*" element={<Unknown/>}></Route>
                <Route path="/" element={<Home />}> </Route>
                <Route path="home" element={<Home />}> </Route>
                <Route path="about" element={<About />}> </Route>
                <Route path="cv" element={<CV />}> </Route>
                <Route path="projects/all" element={<NeProjects />}> </Route>
                <Route path="projects/Stats" element={<StatPage />}> </Route>
                <Route path="projects/Discord" element={<DiscordPage />}> </Route>
                <Route path="projects/Toy" element={<ToyPage />}> </Route>
                <Route path="projects/Webpage" element={<WebPage />}> </Route>
                <Route path="projects/GameWAI" element={<GamPage />}> </Route>
                <Route path="projects/Honours" element={<HonsPage />}> </Route>
                <Route path="projects/Functional" element={<FunPage />}> </Route>
                <Route path="projects/Web" element={<WAMPage />}> </Route>
                <Route path="projects/BMS" element={<BMPage />}> </Route>
                <Route path="projects/Conc" element={<ConPage />}> </Route>
                <Route path="projects/GameServer" element={<SerPage />}> </Route>
                <Route path="test" element={<NewProjPage />}></Route>
            </Routes>
      </AnimatePresence>
    );
} export default AnimatedRoutes
import React from "react"
import HeadB from "../../../../Shared/Text/HeadB";
import TextB from "../../../../Shared/Text/textB";
import Overall1 from "../../../../Shared/Photos/Stats/overall1.png";
import Overall2 from "../../../../Shared/Photos/Stats/overall2.png";
import Overall3 from "../../../../Shared/Photos/Stats/Overall3.png";
import Overall4 from "../../../../Shared/Photos/Stats/overall4.png";
import HeadC from "../../../../Shared/Text/HeadC";
class OverP extends React.Component{

    constructor(props){
      super(props);
      this.state = {
          body: "",
          color: props.color ? props.color: "black",
          bg: props.bg ? props.bg : "white"
      } 
  }

    render(){
      let x = {color:this.state.color, background:this.state.bg}
      return <div style={x} className="mx-3"><HeadC color={this.state.color} bg={this.state.bg}> Overall Page</HeadC><TextB color={this.state.color} bg={this.state.bg}>
        The overall page is for finding stats that don't relate to just one player or match but rather the overall data.
        <br/> When loaded, the page looks like<br/><br/>
        <img 
           src={Overall4}
           width="60%"
           style={{marginLeft:"20%"}}
           alt="Match Load"
           /><br/><br/>
        The Overall page has 4 Sections in it 
        <ul style={x}>
          <li style={x}> Overall and Average Stats</li>
          <li style={x}> Compare</li>
          <li style={x}> Compare Team</li>
        </ul>
        <HeadB color={this.state.color} bg={this.state.bg}> Overall and Average Stats</HeadB>
        <div style={{textAlign:"center", color:this.state.color, background:this.state.bg}}>
        The Overall Stats and the Average Stats show the overall and Average Stats across all records.<br/> 
        It shows this information for Overall, SR, ARAM and  Top, Jungle, Mid, Adc and Support.
        </div><br/> 
        <img 
           src={Overall1}
           width="60%"
           style={{marginLeft:"20%"}}
           alt="Match Load"
           /><br/>
        <HeadB color={this.state.color} bg={this.state.bg}> Compare</HeadB>
        <div style={{textAlign:"center", color:this.state.color, background:this.state.bg}}>
        The Compare Section is divided into two sections, each of which allows you to filter all records in the database and return the average
        of these statistics.<br/> 
        The filters are Name, Champion, Position, and Gamemode With these filters, you could easily compare
        <ul style={x}>
          <li style={x}> The average stats of different players playing the same champion </li>
          <li style={x}> The average stats of different players in the same position </li>
          <li style={x}> The average stats of a player in different game modes </li>
        </ul> 
        It looks like <br/><br/>
        </div>
        <img 
           src={Overall2}
           width="60%"
           style={{marginLeft:"20%"}}
           alt="Match Load"
           /><br/><br/>
        <HeadB color={this.state.color} bg={this.state.bg}> Compare Team</HeadB>
        <div style={{textAlign:"center", color:this.state.color, background:this.state.bg}}>
        The Compare Team option allows you to compare the average team and opponent of a player's filtered games.<br/> 
        It accomplishes this by initially filtering the set of games in the same way that compare does, with the exception of requiring a name.<br/> 
        Following the first filter, it will then filter all remaining game records.<br/> 
        It will then return the average of the remaining records.<br/> 
        This is useful for things like
        <ul style={x}>
          <li style={x}> Comparing the average Yasuo on your team versus the average Yasuo on the enemy team</li>
          <li style={x}> Comparing the average teammate or enemy stats overall or in different game modes</li>
          <li style={x}> When comparing the average teammate when a player plays Mid versus Adc </li>
        </ul>
        </div>
         <br/>
        <img 
          src={Overall3}
          width="60%"
          style={{marginLeft:"20%"}}
          alt="Match Load"
          /><br/><br/> <br/> 
      </TextB>
      </div>
    }
  } export default OverP
import React from "react";
import Sections from "../../../../Shared/Util/Sections";
import TextB from "../../../../Shared/Text/textB";
import HeadC from "../../../../Shared/Text/HeadC";
import HeadB from "../../../../Shared/Text/HeadB";

import TicTacToe from "../../../../Shared/Photos/GameServer/TicTacToe.png"
import Connect4 from "../../../../Shared/Photos/GameServer/Connect4.png"


class Gpage extends React.Component{
    
    constructor(props){
        super(props);
        this.state = {
            body: "",
            color: props.color ? props.color: "black",
            bg: props.bg ? props.bg : "white"
        } 
    }

    render(){
        let x = {color:this.state.color, background:this.state.bg}
        return <div style={x} className="mx-3">
        <HeadC color={this.state.color} bg={this.state.bg}> Game Pages </HeadC><TextB color={this.state.color} bg={this.state.bg}>
            The Game pages are the games where you can actually play the game i.e. make moves and receive oppentent moves. 
            <HeadB color={this.state.color} bg={this.state.bg}> TicTacToe</HeadB>
            <img 
                src={TicTacToe}
                width="60%"
                style={{marginLeft:"20%"}}
                alt="Top of the display page"  
                /><br
            />
            The Tic-Tac-Toe board is made up of 9 buttons, with each button representing one area on the board. 
            If a button is clicked and it is that player's turn and the area is not claimed, the player will claim that area. 
            When the game is complete, i.e., no more moves or a player has won, you will not be able to make any more moves.
            There is also an area at the top that states what players are in the game and if that player is either X or O.
            <br/>
            There is also a area at the top that states what players are in the game and if that player is either X or O.<br/>
            <HeadB color={this.state.color} bg={this.state.bg}> Connect 4 </HeadB>
            <img 
                src={Connect4}
                width="60%"
                style={{marginLeft:"20%"}}
                alt="Top of the display page"  
                /><br
            />
            The connect4 game is a 7x7 grid that has been spit up into 7 buttons with 7 areas because in connect4, when you make a move, you choose a row, 
            not a coordinate for where to place your piece. If you are on the desktop, hovering over a row on the board will show an indicator of where your piece will be positioned
            this is shown as a piece that is lighter color, and this will dissapear when you stop hovering over that row.To make a move, 
            you simply click the button of the row you wish to place your piece on, and if it is your turn and that row is not full and the game is not over, 
            it will place a piece in that row for you. There is also a area at the top with an indicator of what colour each player is and their profile picture.
        </TextB>
        </div>
    }
} export default Gpage
import React from "react";
import {Col, Row } from "react-bootstrap";
import Img from "../../Shared/Photos/WAM/Calc.png"
import Mob from "../../Shared/Photos/WAM/Mobile.png"
import ImageViewer from 'react-simple-image-viewer';

class WAMGal extends React.Component{

    constructor(props){
        super(props)
        this.state = {
            body: "",
            color: props.color ? props.color: "black",
            bg: props.bg ? props.bg : "white"
        }
    }

    onClose(){
        this.setState({body:""})
    }

    show(num){
        console.log(this.state.ind)
        let x =             
            <ImageViewer
                src={[Mob, Img ] }
                currentIndex={ num}
                disableScroll={ false }
                closeOnClickOutside={ true }
                onClose={ () => this.onClose()}
            />
        this.setState({body:x})
    }

    render(){
        let sty = {color:this.state.color, background: this.state.bg}
        let b = this.state.ind;
        return <>
            <Row className="py-3 px-3" style={sty}>
                <Col style={sty}>
                    <div 
                    style={{height:"100vh", width:"33vw",
                        backgroundImage:  "url(" + Mob + ")", 
                        backgroundRepeat: 'no-repeat',backgroundSize:"cover"}}
                        onClick={() => this.show(0)}
                        />
                </Col>
                <Col style={sty}>
                    <div 
                    style={{height:"50vh", width:"33vw",
                        backgroundImage:  "url(" + Img + ")", 
                        backgroundRepeat: 'no-repeat',backgroundSize:"cover"}}
                        onClick={() => this.show(1)}
                        />
                </Col>
            </Row>
            <br/><br/><br/>
            {this.state.body}
        </>
    }

} export default WAMGal 
import React from "react";
import TextB from "../../../../Shared/Text/textB";
import HeadC from "../../../../Shared/Text/HeadC";

class CsVer extends React.Component{

    constructor(props){
        super(props);
        this.state = {
            body: "",
            color: props.color ? props.color: "black",
            bg: props.bg ? props.bg : "white"
        } 
    }
    

    render(){
        let x = {color:this.state.color, background:this.state.bg}
        return <div style={x} className="mx-3">
            <HeadC color={this.state.color} bg={this.state.bg}> C# Version </HeadC>
            <TextB color={this.state.color} bg={this.state.bg}>
            The C# version was written at the end of 2022 to see how much faster the AI would be if written in C# 
            instead of Python, especially if I used parallel programming to speed up the calculations.
            It was also an opportunity to build a GUI using the Razor framework, which I had not done before.
            </TextB>
            <br/>
        </div>
    }
} export default CsVer 
import React from "react";
import Sections from "../../../../Shared/Util/Sections";
import TextB from "../../../../Shared/Text/textB";
import HeadC from "../../../../Shared/Text/HeadC";

class SerBack extends React.Component{

    constructor(props){
        super(props);
        this.state = {
            body: "",
            color: props.color ? props.color: "black",
            bg: props.bg ? props.bg : "white"
        } 
    }

    render(){
        let x = {color:this.state.color, background:this.state.bg}
        return <div style={x} className="mx-3">
            <HeadC color={this.state.color} bg={this.state.bg}> Backend/ Server </HeadC>
            <TextB color={this.state.color} bg={this.state.bg}>
            The backend of the project is written in c# and uses ASP.NET; its main job is to interact with a database for storing information about games and users, 
            as well as act as the middle man between a group of clients that would be playing or spectating the game.
            </TextB>
            <br/>
        </div>
    }
} export default SerBack 





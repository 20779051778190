import React from "react";
import { Col, Row } from "react-bootstrap";
import HeadB from "../../../../Shared/Text/HeadB";
import HeadC from "../../../../Shared/Text/HeadC";
import TextB from "../../../../Shared/Text/textB";

class MatchInterval extends React.Component{

    constructor(props){
        super(props);
        this.state = {
            body: "",
            color: props.color ? props.color: "black",
            bg: props.bg ? props.bg : "white"
        } 
    }

    render(){
        let x = {color:this.state.color, background:this.state.bg}
        return <div style={x} className="mx-3">
            <HeadC color={this.state.color} bg={this.state.bg}> Match Intervals Table</HeadC>
        <TextB color={this.state.color} bg={this.state.bg}>
        The Match Intervals Table is the table that stores information about each player at specific intervals throughout a game; 
        depending on the length of the game, it will generate between ten and fifty records.
            The fields in Match Interval table are <br/>
            <Row style={x}>
                <Col style={x}>
                <ul style={x}>
                    <li style={x}>Match ID</li>
                    <li style={x}>Kills</li>
                    <li style={x}>Damage Done</li>
                    <li style={x} >Jungle Killed</li>
                </ul>
                </Col>
                <Col style={x}>
                <ul style={x}>
                    <li style={x}>Name</li>
                    <li style={x}>Deaths</li>
                    <li style={x}>Level</li>
                    <li style={x}>XP </li>
                </ul>
                </Col>
                <Col style={x}>
                <ul style={x}>
                    <li style={x}>Time</li>
                    <li style={x}>Assists</li>
                    <li style={x}>Minions Killed</li>
                    <li style={x}>Gold </li>
                </ul>
                </Col>
            </Row>
            <br/>
            <br/>
        </TextB>
        </div>
    }
} export default MatchInterval
import React from "react";
import HeadC from "../../../Shared/Text/HeadC";
import TextB from "../../../Shared/Text/textB";

class Lexer extends React.Component{

    constructor(props){
        super(props);
        this.state ={
            color: props.color ? props.color: "black",
            bg: props.bg ? props.bg : "white"
        }
    }


    render(){
        let x = {color:this.state.color, background: this.state.bg };
        return <div className="mx-3">
            <HeadC color={this.state.color} bg={this.state.bg}>
                Lexer
            </HeadC>
            <TextB color={this.state.color} bg={this.state.bg}>
                    The lexer is what decides the language, such as what symbols are used and what the commands are called. 
                    My language's lexer is similar to Java in that it is made up of a number of tokens, such as 
                    <ul style={x}>
                        <li style={x}> + </li>
                        <li style={x}> - </li>
                        <li style={x}> * </li>
                        <li style={x}> / </li>
                        <li style={x}> Print </li>
                        <li style={x}> For </li>
                    </ul>
                    The full list of tokens is <br/>
                    &emsp;//Break on<br/>
                    &emsp; &ensp;LBracket, RBracket, LBrace, RBrace,LSquare, RSquare,<br/>
                    &emsp; //other Break on s<br/>
                    &emsp; &ensp;SemiColan, Comma, Dot, Colan ,<br/>
                    &emsp; //Types<br/>
                    &emsp; &ensp;Var, Int, Float, Void, Double, Char, String, Short, Boolean, Long, Array, Object,<br/>
                    &emsp;//Operands<br/>
                    &emsp; &ensp;Plus, Minus, Mul, Div, Mod,<br/>
                    &emsp; //Builtin functions<br/>
                    &emsp; &ensp; If , Then , Else , For, Print , Switch , Case , Default , Break , While , Try , Catch ,<br/>
                    &emsp; //Class stuff<br/>
                    &emsp; &ensp;Class, Value, New , Null ,<br/>
                    &emsp; //Logic stuff<br/>
                    &emsp; &ensp; Equal, EqualTo, NotEqualTo, LThan, GThan, LThanEq, GThanEq, And, Or, Not ,<br/>
                    &emsp; //Function stuff<br/>
                    &emsp; &ensp;Return, FunctionCall, ArrayCall, Private , Public , Static , Throws ,<br/>
                    &emsp; //Value tokens<br/>
                    &emsp; &ensp;Expression, Unknown, BoolExpression , ObjectDeclaration , ArrayDeclaration , ArrayDeclaration2 , IfThenElse , StringVal , ArrayLength ,<br/>
                    &emsp;//File Stuff<br/>
                    &emsp; &ensp; Import , Const , ConstFunc, Cast<br/>
                </TextB>
                </div>
    }

} export default Lexer
import React from "react";
import TextB from "../../../../Shared/Text/textB";
import HeadC from "../../../../Shared/Text/HeadC";
import HeadB from "../../../../Shared/Text/HeadB";

import Display1 from "../../../../Shared/Photos/GameServer/Display1.png"
import NewGame from "../../../../Shared/Photos/GameServer/NewGame.png"
import NewGameNew from "../../../../Shared/Photos/GameServer/NewGameNew.png"
import Display2 from "../../../../Shared/Photos/GameServer/display2.png"
import Display3 from "../../../../Shared/Photos/GameServer/Display3.png"
import Display4 from "../../../../Shared/Photos/GameServer/Display4.png"


class GDis extends React.Component{

    constructor(props){
        super(props);
        this.state = {
            body: "",
            color: props.color ? props.color: "black",
            bg: props.bg ? props.bg : "white"
        } 
    }

    render(){
        let x = {color:this.state.color, background:this.state.bg}
        return <div style={x} className="mx-3">
        <HeadC color={this.state.color} bg={this.state.bg}> Game Display </HeadC><TextB color={this.state.color} bg={this.state.bg}>
            The Game Display Page is the page that will display information about games the player is in, it has two main sections the first is 
            <HeadB color={this.state.color} bg={this.state.bg}> Control Panel</HeadB>
            <img 
                src={Display1}
                width="60%"
                style={{marginLeft:"20%"}}
                alt="Top of the display page"  
                /><br
            />
            It has 4 Sections
            Stats ,
            Navigation,
            Filter and 
            Create Game Button,
            <br/><br/>
            The <span style={{fontWeight:"700", color:this.state.color, background:this.state.bg}}> Stats section </span>  displays information about the players' match history,
            including how many games they have played, their win rates, how many they lost, how many games are still ongoing, etc. <br/><br/>
            The <span style={{fontWeight:"700", color:this.state.color, background:this.state.bg}}> Fitler section </span>  is for filtering all the games that are shown in the next section,
             where you can filter on the player in the game, the outcome of the match, and what gamemode is being played.<br/><br/>
            The <span style={{fontWeight:"700", color:this.state.color, background:this.state.bg}}> Navigation section </span>  is where you can choose how many games to display at once and navigate through them, 
            as well as three buttons. The "Refresh" button will refresh the match list so it will update the displays and get any new games,
            the "Change view" button will toggle through the two display options for each game, allowing you to choose your preferred way of viewing the game info 
            (see the next section to view how each looks), and the "Spectate" button will allow you to watch games that you are not an active member in so you can watch 
            your friends play each other through the client. <br/><br/>
            The <span style={{fontWeight:"700", color:this.state.color, background:this.state.bg}}> Create New Game Button </span> allows you to create new games, when pressed it will bring up a menu that looks like this
            <img 
                src={NewGame}
                width="60%"
                style={{marginLeft:"20%"}}
                alt="Top of the display page"  
                /><br
            />
            It lets you choose the player you want to play with, and then there's a new option that looks like this.
            <img 
                src={NewGameNew}
                width="20%"
                style={{marginLeft:"40%"}}
                alt="Top of the display page"  
                /><br
            />
            where you can enter the name of someone you haven't played before. 
            The other options allow you to choose what gamemode you want to play and what position you want to be do you want to start or go second.
            <HeadB color={this.state.color} bg={this.state.bg}> The Game List</HeadB>
            This section is where game information is displayed, and you can click on a game display to start playing that game.
            There are two different options for displaying information. The first one looks like  <br/>
            <div style={{textAlign:"center", color:this.state.color, background:this.state.bg}}><span style={{ fontWeight:"700", fontSize:"120%", color:this.state.color, background:this.state.bg}}>For TicTacToe </span></div><br/>
            <img 
                src={Display2}
                width="60%"
                style={{marginLeft:"20%"}}
                alt="Top of the display page"  
                /><br
            />
            <div style={{textAlign:"center", color:this.state.color, background:this.state.bg}}><span style={{ fontWeight:"700", fontSize:"120%", color:this.state.color, background:this.state.bg}}>For Connect4 </span></div><br/>
            <img 
                src={Display4}
                width="60%"
                style={{marginLeft:"20%"}}
                alt="Top of the display page"  
                /><br
            />
            The other way you can view the information is in a list format, which looks like this
            <img 
                src={Display3}
                width="60%"
                style={{marginLeft:"20%"}}
                alt="Top of the display page"  
                /><br
            />
        You can switch between the two styles by pressing the change view button in the navigation.<br/>
        It will not fit in list form due to the size of the Connect4 board, so it simply stats the gamemode but does not show the current state of the game.
        </TextB>
        </div>
    }
} export default GDis
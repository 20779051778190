import React from "react";
import HeadB from "../../../../Shared/Text/HeadB";
import HeadC from "../../../../Shared/Text/HeadC";
import TextB from "../../../../Shared/Text/textB";

class AddP extends React.Component{

  constructor(props){
    super(props);
    this.state = {
        body: "",
        color: props.color ? props.color: "black",
        bg: props.bg ? props.bg : "white"
    } 
}

    render(){
      let x = {color:this.state.color, background:this.state.bg}
      return <div style={x} className="mx-3"><HeadC color={this.state.color} bg={this.state.bg}> Add Route</HeadC><TextB color={this.state.color} bg={this.state.bg} >
        The Mappings to the Functions responsible for adding to the Database can be found in this <a style={x} href="https://github.com/NineSixFourteen/Lol-Personal-Stat-Checker/blob/main/Lol-Personal-Stats/src/main/java/Stats/FrontEnd/AddController.java">file</a>.
        <br/>
        The Functions for adding to the database are
        <HeadB color={this.state.color} bg={this.state.bg}> Match</HeadB>
        Takes MatchID(String) <br/>
        This Function is for adding a match to the database by do the following
        <ul style={x}>
          <li style={x}> Fetch Data about Match </li>
          <li style={x}> Create All Records for the match 
            <ul style={x}>
              <li style={x}> 1 Match History Record</li>
              <li style={x}> 10 Match Overall 1 Records & 10 Match Overall 2 Records</li>
              <li style={x}> Up to 50 Match Intervals Recrods</li>
            </ul>
          </li>
          <li style={x}> Add Records to Database</li>
        </ul>
        <HeadB color={this.state.color} bg={this.state.bg}> Player</HeadB>
        Takes Name(String) and Amount(int) <br/>
        This Function is for adding a players last {"{amount}"} of games to the database by do the following
        <ul style={x}>
          <li style={x}> Fetch Player's Unique id </li>
          <li style={x}> Fetch Player's MatchHistory ids </li>
          <li style={x}> For {"{amount}"}
            <ul style={x}>
              <li style={x}>Do the same as the addPlayer function</li>
            </ul>
          </li>
        </ul>
        <HeadB color={this.state.color} bg={this.state.bg}> Test</HeadB>
        Takes Nothing<br/>
        This Function is for testing if the key is still valid it does this by do the following
        <ul style={x}>
          <li style={x}> Will attempt to access the API
            <ul style={x}>
              <li style={x}> IF Success returns true</li>
              <li style={x}> IF Fails returns false</li>
            </ul>
          </li>
        </ul>
      </TextB>
      </div>
    }
  } export default AddP
  
import React from "react";
import { Card} from "react-bootstrap";
import Min from "../Photos/None.png"
import MinD from "../Photos/NoneD.png"

class ChildLess extends React.Component{

    constructor(props){
        super(props);
        this.state = {
            Title: props.Title,
            childs: props.childs,
            body: props.body, 
            func: props.func,
            colorS: props.color,
            bg: props.bg, 
            color: props.color,
            isDark: props.bg != "rgb(230,230,255)"
        }
    }

    mouseEnter(){
        let color;
        if(this.state.isDark){
            color = "rgb(89, 142, 255)"
        } else {
            color = "rgb(60, 86, 140)"
        }
        this.setState({color: color})
    }

    mouseLeave(){
        this.setState({color: this.state.colorS})
    }
    
    render(){
        let x = window.screen.availHeight < window.screen.availWidth;
        let img = this.state.isDark ?  Min : MinD;
        return <div style={{background:"rgb(9, 1, 20)"}}>
        <Card className="m-3 border-0" style={{display:"flex"}}>
            <Card.Header className="px-2 py-0"  style={{background:"rgb(9, 1, 20)", color:"white",fontSize:"3vh", fontWeight:"700"}}>
            <div  className="p-3"  style={{background:this.state.bg, width: x ? "18vw" : "90vw", borderRadius:"3vh"}}>
                <img src={img} height="20vh" onClick={()=>this.flipShow()} width="20vw" style={{background:this.state.bg}} alt="" />
                <a className="mx-2" onMouseEnter={()=> this.mouseEnter()} onMouseLeave={()=> this.mouseLeave()} onClick={()=>this.state.func(this.state.body)} style={{background:this.state.bg, color:this.state.color, textDecoration:"underline",cursor:"pointer"}}>{this.state.Title}</a>
            </div>
            </Card.Header>
        </Card>
    </div>
    }

} export default ChildLess
import React from "react"
import HeadB from "../../../Shared/Text/HeadB"
import TextB from "../../../Shared/Text/textB"

class WebP extends React.Component{

    constructor(props){
        super(props)
        this.state = {
            body: "",
            color: props.color ? props.color: "black",
            bg: props.bg ? props.bg : "white"
        }
    }

    render(){
        let x = {color:this.state.color, background: this.state.bg}
        return <div style={x} className="mx-3">
         <HeadB color={this.state.color} bg={this.state.bg}> Website Projects</HeadB><TextB color={this.state.color} bg={this.state.bg}>
            The notable websites I created during this class
            <ul style={x}>
                <li style={x}> Artist's Website</li>
            </ul>
            <HeadB color={this.state.color} bg={this.state.bg}> Artist's Website</HeadB>
            The artist's websites' main features were
            <ul style={x}>
                <li style={x}> The website is fully styled.</li>
                <li style={x}>
                    Home Page
                    <ul style={x}>
                        <li style={x}>Provide information about the artist.</li>
                        <li style={x}> Show off some of the artists' paintings in a carousel.</li> 
                    </ul>
                </li>
                <li style={x}>
                    Store Page 
                    <ul style={x}>
                        <li style={x}> List the information for all paintings stored in the paintings table in a MySQL database.</li>
                        <li style={x}> An order page for any painting in the table that allows you to select size and frame.</li>
                        <li style={x}> Following an order, an email is automatically sent with a full receipt. </li>
                    </ul>
                </li>
                <li style={x}>
                    Tour Visit Booking Page
                    <ul style={x}>
                        <li style={x}> Show a calendar of time slots and indicate if they are available.</li>
                        <li style={x}> Bookings are added to the database to prevent double bookings.</li>
                    </ul>
                </li>
                <li style={x}> 
                    Admin Page
                    <ul style={x}>
                        <li style={x}> Password Protected</li>
                        <li style={x}> Displays all sales receipts as well as all bookings.</li> 
                    </ul>
                </li>
            </ul>
        </TextB>
        </div>
    }
} export default WebP
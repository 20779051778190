import React from "react"
import HeadB from "../../../Shared/Text/HeadB"
import HeadC from "../../../Shared/Text/HeadC"
import TextB from "../../../Shared/Text/textB"
class ConC extends React.Component{

    constructor(props){
        super(props)
        this.state = {
            body: "", 
            color: props.color ? props.color: "black",
            bg: props.bg ? props.bg : "white",
        }
    }

    render(){
        let x = {color:this.state.color, background: this.state.bg}
        return <div className="mx-3" style={x}>
            <HeadC  color={this.state.color} bg={this.state.bg}> Concurrency</HeadC>
            <TextB color={this.state.color} bg={this.state.bg}>
            During the Concurrency Section 
            <ul style={x}> 
                <li style={x}> Built a program that could monitor all the JVM threads as well as create new ones.</li>
                <li style={x}> Built a program to simulate a banking system using synchronised functions.s</li>
            </ul>
        </TextB>
        </div>
    }
} export default ConC

import React from "react";
import { Row } from "react-bootstrap";
import HeadC from "../../../../Shared/Text/HeadC";
import HeadB from "../../../../Shared/Text/HeadB";
import TextB from "../../../../Shared/Text/textB";

class Interfaces extends React.Component{

    constructor(props){
        super(props);
        this.state = {
            body: "",
            color: props.color ? props.color: "black",
            bg: props.bg ? props.bg : "white"
        } 
    }

    render(){
        let x = {color:this.state.color, background:this.state.bg}
        return <div style={x} className="mx-3"><HeadC color={this.state.color} bg={this.state.bg}> Interfaces</HeadC><TextB color={this.state.color} bg={this.state.bg}>
            There are two main interfaces for using tihs bot are the discord chat and a Modal which is a sort of GUI you can access through discord.
            <HeadB color={this.state.color} bg={this.state.bg}> Discord Chat</HeadB>
            The bot will listen for all message that are available to it however it will ignore all that are not in a chat called "bot-cmds". 
            The bot will take the message it can see and then will compare the first word in the message and compare that against a list of its commands. 
            If it finds a command it will execute the function assosicated with that command. 
            <HeadB color={this.state.color} bg={this.state.bg}> Modal</HeadB>
            The Modal is a GUI built into discord you can customise it with text fields that have differnt parameters such as required or number of allowed characters.
            To use the modal you need a way access it this is usually done through building a command into a discord 
            server for example my bot adds the command "/champs" to the servers it is in and you can acess the modal by typing that discord command in. 
            <br/><br/>
        </TextB>
        </div>
    }
} export default Interfaces
import React from "react";

class HeadA extends React.Component{

    constructor(props){
        super(props);
        this.state ={
            color: props.color ? props.color: "white",
            bg: props.bg ? props.bg: "black"
        }
    }

    render(){
        return <div style={{textAlign:"center", color: this.state.color, background:this.state.bg}}>
            <span style={{fontSize:"300%", fontWeight:"800", color: this.state.color, background:this.state.bg}}>
                {this.props.children}
            </span>
        </div>
    }

}export default HeadA
import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Checklist from "../../Shared/Util/CheckList";
import HeadB from "../../Shared/Text/HeadB";
import HeadC from "../../Shared/Text/HeadC";
import HeadS from "../../Shared/Text/HeadS";
import Tags from "../../Shared/Util/Tags";
import TextB from "../../Shared/Text/textB";
import TagFactory from "../../Shared/Util/TagFactory";

class WAMSpec extends React.Component{


    constructor(props){
        super(props);
        this.state ={
            color: props.color ? props.color: "black",
            bg: props.bg ? props.bg : "white"
        }
    }


    render(){
        let x = {color:this.state.color, background:this.state.bg}
        return <div className="mx-3">
                <TextB color={this.state.color} bg={this.state.bg}>
                    <HeadC color={this.state.color} bg={this.state.bg}> Specifiation</HeadC>
                    <HeadS color={this.state.color} bg={this.state.bg}> Final Goal</HeadS>
                    To build websites and mobile apps using HTML, CSS, Bootstrap, JS, React, PHP, and MySql.
                    <HeadS color={this.state.color} bg={this.state.bg}> Technoloogy Used</HeadS>
                </TextB> 
                <Row style={x}> 
                    <Col style={x} sm={2}><TextB color={this.state.color} bg={this.state.bg}> Project Type : </TextB></Col>
                    <Col style={x}><Row style={x}>{TagFactory.makeTags("t", "University").concat(TagFactory.makeTags("ts", "Solo, Medium"))} </Row> </Col>
                </Row>
                <Row style={x}> 
                    <Col style={x} sm={2}><TextB color={this.state.color} bg={this.state.bg}> Language : </TextB></Col>
                    <Col style={x}><Row style={x}>{TagFactory.makeTags("l", "Javascript")} </Row></Col>
                </Row>
                <Row style={x}> 
                    <Col style={x} sm={2}><TextB color={this.state.color} bg={this.state.bg}> Technoloogy used : </TextB></Col>
                    <Col><Row style={x}>{TagFactory.makeTags("to", "Git, React, MySql")} </Row></Col>
                </Row>
                    <TextB color={this.state.color} bg={this.state.bg}>
                    <HeadS color={this.state.color} bg={this.state.bg}> Goals</HeadS>
                    <Checklist 
                    name = "Project Goals"
                    items={
                        [
                            {done:true, message:"Build a website for an artist."},
                            {done:true, message:"Build a currency calculator phone app."},
                            {done:true, message:"Build a COVID-relief mobile app."},
                        ]
                    }/>
                    <br/>
                    <Checklist 
                    name = "Artist Website"
                    items={
                        [
                            {done:true, message:"Create a home page for the website."},
                            {done:true, message:"Create a page to list all of the artwork available for sale."},
                            {done:true, message:"Create a page to take reservations for studio tours.."},
                            {done:true, message:"Create an admin page that shows all purchases and bookings."},
                        ]
                    }/>
                    <br/>
                    <Checklist 
                    name = "Currency Calculator"
                    items={
                        [
                            {done:true, message:"Read in an XML sheet with euro currency exchange"},
                            {done:true, message:"Build an interface that allows you to select any two currencies from the list and the amount of tax, then let you convert any amount between them."},
                        ]
                    }/><br/>
                    <Checklist 
                    name = "Relif app"
                    items={
                        [
                            {done:true, message:"Create a health tracking section that tracks the number of steps per day."},
                            {done:true, message:"Make chatrooms where people can talk."},
                            {done:true, message:"Build a section with a daily TODO list."},
                        ]
                    }/><br/>
                </TextB>
        </div>
    }
} export default WAMSpec 
import React from "react";
import TextB from "../../../../Shared/Text/textB";
import HeadC from "../../../../Shared/Text/HeadC";
import HeadB from "../../../../Shared/Text/HeadB";

class SerGList extends React.Component{

    constructor(props){
        super(props);
        this.state = {
            body: "",
            color: props.color ? props.color: "black",
            bg: props.bg ? props.bg : "white"
        } 
    }

    render(){
        let x = {color:this.state.color, background:this.state.bg}
        return <div style={x} className="mx-3"><HeadC color={this.state.color} bg={this.state.bg}> Games List and planned games to add </HeadC><TextB color={this.state.color} bg={this.state.bg}>
            The games are the whole point of this project and the ones that are available to play are listed below and games I play to add later are also named. 
            To see images or video of the games either go to the front end section or go to Gallery.
            <HeadB color={this.state.color} bg={this.state.bg}>Games that are available</HeadB>
            <ul style={x}>
                <li style={x}> TicTacToe</li>
                <li style={x}> Connect4</li>
            </ul>
            <HeadB color={this.state.color} bg={this.state.bg}>Games I plan to add</HeadB>
            <ul style={x}>
                <li style={x}> Chess</li>
                <li style={x}> Checkers</li>
                <li style={x}> 4 Player game </li>
                <li style={x}> Single player game (crossword, sudoku, wordle)</li>
                <li style={x}> Game that any number can join and join during game (hangman) </li>
            </ul>
        </TextB>
        </div>
    }
} export default SerGList 
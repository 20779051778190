import React from "react";
import { Col, Row } from "react-bootstrap";
import HeadC from "../../Shared/Text/HeadC";
import HeadS from "../../Shared/Text/HeadS";
import TextB from "../../Shared/Text/textB";

class DisNav extends React.Component{


    constructor(props){
        super(props);
        this.state = {
            body: "",
            color: props.color ? props.color: "black",
            bg: props.bg ? props.bg : "white"
        } 
    }

    render(){
        let x = {color:this.state.color, background:this.state.bg}
        return <div style={{textAlign:"center"}}>
        <TextB>
            <HeadS color={this.state.color} bg={this.state.bg}> Project on <a style={x} href="https://github.com/NineSixFourteen/Worlds-2022-Discord-pickem-bot">Github</a></HeadS>
            <Row style={x}>
                <Col style={x}>
                    <HeadS color={this.state.color} bg={this.state.bg}> Information Scraper</HeadS>
                        <a style={x} href="https://github.com/NineSixFourteen/Lol-Personal-Stat-Checker/tree/main/Lol-Personal-Stats/src/main/java/Stats/InfoFetcher"> Champion Scraper </a> <br/>
                        <a style={x} href="https://github.com/NineSixFourteen/Lol-Personal-Stat-Checker/blob/main/Lol-Personal-Stats/src/main/java/Stats/InfoFetcher/Fetcher.java"> Player Scraper  </a> <br/>
                        <a style={x} href="https://github.com/NineSixFourteen/Lol-Personal-Stat-Checker/blob/main/Lol-Personal-Stats/src/main/java/Stats/InfoFetcher/FetchOverall.java"> Event Scraper </a> <br/>
                    </Col>
                <Col style={x}>
                    <HeadS color={this.state.color} bg={this.state.bg}> Information Storage</HeadS>
                        <a style={x} href="https://github.com/NineSixFourteen/Lol-Personal-Stat-Checker/tree/main/Lol-Personal-Stats/src/main/java/Stats/BackEnd"> Champion </a> <br/>
                        <a style={x} href="https://github.com/NineSixFourteen/Lol-Personal-Stat-Checker/tree/main/Lol-Personal-Stats/src/main/java/Stats/FrontEnd"> Player  </a> <br/>
                        <a style={x} href="https://github.com/NineSixFourteen/Lol-Personal-Stat-Checker/tree/main/Lol-Personal-Stats/src/main/java/Stats/FrontEnd"> Event  </a> <br/>
                </Col>
                <Col style={x}>
                    <HeadS color={this.state.color} bg={this.state.bg}> Discord Bot</HeadS>
                        <a style={x} href="https://github.com/NineSixFourteen/Worlds-2022-Discord-pickem-bot/tree/main/pickems/src/main/java/bot/Bot/MessageMakers"> Message Makers</a> <br/>
                        <a style={x} href="https://github.com/NineSixFourteen/Worlds-2022-Discord-pickem-bot/tree/main/pickems/src/main/java/bot/Bot/Listeners"> Listeners </a> <br/>
                        <a style={x} href="https://github.com/NineSixFourteen/Worlds-2022-Discord-pickem-bot/blob/main/pickems/src/main/java/bot/Bot/Bot.java"> Bot</a> <br/>
                    </Col>
            </Row>
            </TextB>
        
        </div>
    }
} export default DisNav
import React from "react";
import { Card, Row, Col, Collapse } from "react-bootstrap";
import Check from "./Check";

class Checklist extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            items: props.items,
            name: props.name,
            color: props.color ? props.color: "black",
            bg: props.bg ? props.bg : "white"
        }
    }

    render(){
        let x = [];
        let comple = 0;
        let st = {color:this.state.color, background:this.state.bg}
        this.state.items.forEach(item => {
            if(item.done){
                comple++;
            }
            x.push(
                <Check message={item.message} done={item.done} />
            )
        });
        return <>
        <Card style={st}>
            <Card.Header style={st}> 
                <Row style={st}>
                    <Col style={st}>
                        {this.state.name}
                    </Col>
                    <Col style={st}>
                        <div style={{background:this.state.bg,textAlign:"right", color:"green"}}> {comple + "/" + this.state.items.length + " Goals Complete"} </div>
                    </Col>
                </Row> 
            </Card.Header>
            <Card.Body style={st}>
            <div style={st}>
                {x}
            </div>
            </Card.Body>
        </Card>
        </>;
    }

} export default Checklist
import React from "react";
import { Form, Row } from "react-bootstrap";
import Multiselect from 'multiselect-react-dropdown';

class Filters extends React.Component{
    
    constructor(props){
        super(props);
        this.state = {
            Options: ["Language", "Type", "Team Size", "Tools", "Libarys"],
            Selected: 0,
            FullOptions: [
                ["Java", "JavaScript", "Python", "C#", "Rust", "Dart", "Haskell"],
                ["University", "Personal", "Mix"],
                ["Solo", "Small", "Medium", "Large"],
                ["MySql", "AWS", "Maven", "Linode", "Git", "Nginx", "LaTeX", "Flutter", "Ubuntu"],
                ["Spring Boot", "Tkinter", "JDA", "Swing", "ASM", "Razir", "React"]
            ],  
            ref: React.createRef(), 
            ind: props.ind, 
            selfDelete: props.delete,
            sendUpdate: props.sendUpdate
        }
    }

    update(mode, filter, key){
        this.state.sendUpdate(mode, filter, key) 
    }

    sendDelete(key){
        this.state.selfDelete(key)
    }

    resetValues() {
        this.state.ref.current.resetSelectedValues();
        this.update("C",undefined, this.state.ind)
    }

    getValues(){
        return this.state.ref.current.getSelectedItems();
    }

    changeType(x){
        this.resetValues();
        this.setState({Selected: x})
        this.update('U', x, this.state.ind)
    }


    render(){
        let x = window.screen.availHeight > window.screen.availWidth;
        let color = "rgb(20,20,20)";
        return <div className="mx-1 my-4 p-3"  style={{background:color, width: x ? "80vw" : "18vw", border:"1px solid white", position:"relative"}} >
            <Row className="g-0 " style={{background:color}}>
                <span style={{background:color, color:"white", fontSize:"4vh"}}> Filter Type</span><br/>
                <Form.Select onChange={e => {this.changeType(e.currentTarget.value)}} className="mx-0" name="SelectOption" id = "selectList" style={{background:color, color:"white", fontSize:"3vh"}}> 
                    <option style={{background:color}} value={0}> Language   </option>
                    <option style={{background:color}} value={1}> Type       </option>
                    <option style={{background:color}} value={2}> Team Size  </option>
                    <option style={{background:color}} value={3}> Tools      </option>
                    <option style={{background:color}} value={4}> Libarys    </option>
                </Form.Select>
            </Row>
            <br/>
            <Row className="g-0" style={{background:color}}>
                <span style={{background:color, color:"white", fontSize:"4vh",  }}> Filter</span>
                <Multiselect
                    style={{ 
                        searchBox:{ background:color, color:"white"}, 
                        inputField:{color:"white", fontSize:"3vh"},
                        option:{color:"white", fontSize:"3vh", background:color},
                        chips:{color:"white", fontSize:"2vh", background:"grey"},
                        groupHeading:{background:"grey"},
                        cancel:{background:"grey"}
                    }}
                    isObject={false}
                    selectedValues={this.state.Selcts}
                    closeIcon={"close"}
                    onRemove={(e,f) => {this.update("R",f, this.state.ind)}}
                    onSelect={(e,f) => {this.update("A",f, this.state.ind)}}
                    placeholder="Search"
                    ref={this.state.ref}
                    options={this.state.FullOptions[this.state.Selected]}
                    />
            </Row>
            <div style={{width:"3vh", position:"absolute", top:"2vh", left:"87%", background:"red",textAlign:"center", cursor:"pointer"}} 
            onClick={() => this.sendDelete(this.state.ind)}> 
                <span style={{background:"red", color:"white", fontSize:"2vh"}}> X</span>
            </div>
        </div>
    }
    

} export default Filters

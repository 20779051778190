import React from "react";
import { Form, Row, Col } from "react-bootstrap";

class Check extends React.Component{

    constructor(props){
        super(props);
        this.state = {
            message: props.message,
            done: props.done,
            color: props.color ? props.color: "black",
            bg: props.bg ? props.bg : "white"
        }
    }


    render(){
        let st = {color: this.state.color, background:this.state.bg}
        let x = this.state.done;
        return <Row style={st}>
            <Col style={{background:this.state.bg, color:this.state.color, maxWidth:"50px"}}> <Form style={st}> <Form.Check   style={{width:"50px",marginRight:"100%", background:this.state.bg, color:this.state.color,}} disabled checked={x} /></Form></Col>
            <Col style={st}> {this.state.message}</Col>
        </Row>
    }
} export default Check
import React from "react";
import Sections from "../../../../Shared/Util/Sections";
import TextB from "../../../../Shared/Text/textB";
import HeadC from "../../../../Shared/Text/HeadC";
import HeadB from "../../../../Shared/Text/HeadB";

class Routess extends React.Component{

    constructor(props){
        super(props);
        this.state = {
            body: "",
            color: props.color ? props.color: "black",
            bg: props.bg ? props.bg : "white"
        } 
    }

    render(){
        let x = {color:this.state.color, background:this.state.bg}
        return <div style={x} className="mx-3">
        <HeadC color={this.state.color} bg={this.state.bg}> Routes </HeadC><TextB color={this.state.color} bg={this.state.bg}>
            The important routes for the Web API are 
            <HeadC color={this.state.color} bg={this.state.bg}> User </HeadC>
            <HeadB color={this.state.color} bg={this.state.bg} > Create</HeadB>
                The route for creating a new user is to give it a username and password, and if the username is not used, it creates the account.
            <HeadB color={this.state.color} bg={this.state.bg}> Login</HeadB>
                The route for logging into an account takes a username and password and returns the auth/token if the password is correct
            <HeadB color={this.state.color} bg={this.state.bg}> Games</HeadB>
            <HeadC color={this.state.color} bg={this.state.bg}> Game/Get</HeadC>
            When getting information about games, you can either give a single ID or use the gets route to give multiple ids which will return a list of objects of the board class        
            <HeadB color={this.state.color} bg={this.state.bg}> Create</HeadB>
                Create a new Game takes Players, Gamemode and then create a new game. 
            <HeadB color={this.state.color} bg={this.state.bg}> Load</HeadB>
                Loads a game from the database so you can play it
            <HeadB color={this.state.color} bg={this.state.bg}> Connect</HeadB>
                Connects to the server and establishes the a websocket connection that is used for sending moves and updated versions of the game to people connected.
        </TextB>
        </div>
    }
} export default Routess

import React from "react"
import { motion } from "framer-motion"
import { Button, Row, Col } from "react-bootstrap"
import TagFactory from "../Shared/Util/TagFactory";
import { Link } from "react-router-dom";

class ProjectMaker {

    static makeProjectCard(Project, img, scale){
        let Tf = TagFactory;
        let Languages = [];
        Languages =Tf.makeTags("l",Project.Language)
        console.log(Project.Title)
        console.log(Languages)
        let categoires = Tf.makeTags("c",Project.Category)
        let Type = Tf.makeTags("t",Project.Type)
        let TeamSize = Tf.makeTags("ts",Project.TeamSize)
        let Lib = Tf.makeTags("to",Project.Tools + ", " + Project.Other)
        let x;
        x = <motion.div
            whileHover={{scale:scale, color:"black", zIndex:10}}
            initial={{opacity:0, x:-100}}
            whileInView={{opacity:1, x:0}}
            > <Row className="mx-5">
                <Col sm={6}>
                    <span style={{color:"white", fontSize:"6vh"}}> {Project.Title}</span><br/>
                    <span style={{color:"white", fontSize:"4vh"}}> Tools</span><br/><br/>
                    <Row className="my-2">
                        { Languages }
                        { categoires }
                        { Type }
                        { TeamSize }
                        { Lib }
                    </Row><br/>
                    <span className="my-5" style={{color:"white", fontSize:"2vh"}}> {Project.Description}</span><br/>
                    <Link style={{textDecoration:"none"}} to={Project.link}><Button className="my-2" style={{width:"30vw", fontSize:"2vh"} }> Go to Page</Button> </Link><br/><br/>
                </Col>
                <Col sm={6} style={{position:"relative"}}>
                    <img src={img} style={{position:"relative", top:"10%", left:"0%", width:"100%", height:"40vh", border:"2px solid black", borderRadius:"1em"}} />
                </Col>
            </Row>
        </motion.div>
        return x;
    }

    static makeSmallProject(Project, img, scale){
        let x = window.screen.availHeight < window.screen.availWidth;
        let z = <motion.div
            whileHover={{scale:scale, color:"black"}}
            initial={{opacity:0, x:-100}}
            whileInView={{opacity:1, x:0}}
            style={{width: x ? "20vw": "80vw"}}
            className="mx-2"
            > 
            <Row>
                <img src={img} style={{position:"relative", top:"10%", left:"0%", height:"20vh"}} />
            </Row>
            <Row >
                <Col>
                    <div style={{minHeight:"40vh", display:"block"}}>
                    <span style={{color:"white", fontSize: "4.6vh", fontWeight:"800", top:"auto", position:"static"}}>{Project.Title.length > 12 ? Project.Title.substring(0,12).concat("..") : Project.Title}</span><br/>
                    <span className="my-5" style={{color:"white", fontSize:"2.6vh"}}> {Project.Description}</span><br/>
                    </div>
                    <Link style={{textDecoration:"none"}} to={Project.link}><Button className="my-2" style={{width:"30vw", fontSize:"3vh", width:"100%"}}> Go to Page</Button><br/><br/></Link><br/><br/>
                </Col>
            </Row>
        </motion.div>
        return z;
    }

} export default ProjectMaker
import React from "react";
import { Row, Col} from "react-bootstrap";

import HeadB from "../../../../Shared/Text/HeadB";
import HeadC from "../../../../Shared/Text/HeadC";
import TextB from "../../../../Shared/Text/textB";
import { CopyBlock, dracula } from "react-code-blocks";
class RStack extends React.Component{

    constructor(props){
        super(props);
        this.state = {
            body: "",
            color: props.color ? props.color: "black",
            bg: props.bg ? props.bg : "white"
        } 
    }

    render(){
        let x = {color:this.state.color, background: this.state.bg}
        return <div style={x} className="mx-3">
            <Row style={x}>
                <HeadC color={this.state.color} bg={this.state.bg} > Stack Machine</HeadC>
            </Row>
            <Row style={x}>
                <HeadB color={this.state.color} bg={this.state.bg}> Description</HeadB>
            </Row>
            <Row style={x}>
                <TextB color={this.state.color} bg={this.state.bg}>
                    The stack machine is the part that acculates runs the program it is an interperter that runs the instruction set it is given.
                    In order for it to work it needs 
                    <ul style={x}> 
                        <li style={x}>Memory: For storing variables</li>
                        <li style={x}>Stack: Used to store values in a list for later use</li>
                        <li style={x}>Point: So it knows what instruction it is to run next; also used for jump commands</li>
                        <li style={x}>Main: The function it will actually run</li>
                        <li style={x}>Functions: The list of all functions that might be called</li>
                    </ul>
                </TextB>
            </Row>
        </div>
    }

} export default RStack


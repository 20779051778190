import React from "react";

import NewProjPage from "../../Shared/Util/newProjPage";
import Holder from "../../Shared/Util/Holder";
import ConSpec from "./ConSpec";
import ConAndNet from "./Con&Net";
import ConC from "./Descriptions/ConC";
import NetW from "./Descriptions/NetW";
import ConGal from "./ConGallery";
import ConOver from "./ConOver";

class ConPage extends React.Component{

    constructor(props){
        super(props);
        this.state ={
            color: "white",
            bg: "rgb(20,20,20)"
        }
    }

    flip(){
        if(this.state.color == "white"){
            this.setState({
                color: "rgb(20,20,20)", bg:"white"
            })
        } else {
            this.setState({
                color: "white", bg:"rgb(20,20,20)"
            })
        }

    }

    render(){
        return <NewProjPage 
            func={this.flip.bind(this)}
            childs={
                [
                    {Title: "Overview", body:new Holder(<ConOver/>,<ConOver color={"white"} bg={"rgb(20,20,20)"}/>), childs:[]},
                    {Title: "Specification", body:new Holder(<ConSpec/>,<ConSpec color={"white"} bg={"rgb(20,20,20)"}/>), childs:[]},
                    {Title: "Description", body:new Holder(<ConAndNet/>,<ConAndNet color={"white"} bg={"rgb(20,20,20)"}/>), childs:[
                        {Title: "Concurrency", body:new Holder(<ConC/>,<ConC color={"white"} bg={"rgb(20,20,20)"}/>), childs:[]},
                        {Title: "Networking", body:new Holder(<NetW/>,<NetW color={"white"} bg={"rgb(20,20,20)"}/>), childs:[]},
                    ]},
                    {Title: "Gallary", body:new Holder(<ConGal/>,<ConGal color={"white"} bg={"rgb(20,20,20)"}/>), childs:[]},
                ]
            }
        />
    }
} export default ConPage
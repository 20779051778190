import React from "react";
import { Col, Row } from "react-bootstrap";
import HeadC from "../../../../Shared/Text/HeadC";
import HeadB from "../../../../Shared/Text/HeadB";
import TextB from "../../../../Shared/Text/textB";
class PlayerT extends React.Component{

    constructor(props){
        super(props);
        this.state = {
            body: "",
            color: props.color ? props.color: "black",
            bg: props.bg ? props.bg : "white"
        } 
    }

    render(){
        let x = {color:this.state.color, background:this.state.bg}
        return <div className="mx-3" style={x}><HeadC color={this.state.color} bg={this.state.bg}> Player Table</HeadC><TextB color={this.state.color} bg={this.state.bg}>
            The player data stores information about player and their stats during the tournament.Its fields are
            <Row style={x}>
                <Col style={x}>
                    <ul style={x}>
                        <li style={x}> Player Name</li>
                        <li style={x}> Loses</li>
                        <li style={x}> Deaths</li>
                        <li style={x}> CS</li>
                        <li style={x}> Gold per minute</li>
                        <li style={x}> Gold share</li>
                        <li style={x}> First Blood</li>
                    </ul>
                </Col>
                <Col style={x}>
                    <ul style={x}>
                        <li style={x}> Games Played</li>
                        <li style={x}> Win Rate</li>
                        <li style={x}> Assists</li>
                        <li style={x}> CS per minute</li>
                        <li style={x}> Kill particapnt</li>
                        <li style={x}> Champions Played</li>
                        <li style={x}> Team</li>
                    </ul>
                </Col>
                <Col style={x}>
                    <ul style={x}>
                        <li style={x}> Wins </li>
                        <li style={x}> Kills</li>
                        <li style={x}> KDA</li>
                        <li style={x}> Gold</li>
                        <li style={x}> Kill share</li>
                        <li style={x}> Postition</li>
                    </ul>
                </Col>
            </Row>
        </TextB>
        </div>
    }
} export default PlayerT
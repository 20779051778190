import React from "react";
import { Col, Row } from "react-bootstrap";
import HeadC from "../../../../Shared/Text/HeadC";
import Champs from "../../../../Shared/Photos/Discord/champsC.png"
import HeadB from "../../../../Shared/Text/HeadB";
import TextB from "../../../../Shared/Text/textB";
class QueryS extends React.Component{

    constructor(props){
        super(props);
        this.state = {
            body: "",
            color: props.color ? props.color: "black",
            bg: props.bg ? props.bg : "white"
        } 
    }

    render(){
        let x = {color:this.state.color, background:this.state.bg}
        return <div style={x} className="mx-3"><HeadC color={this.state.color} bg={this.state.bg}> Query System</HeadC><TextB color={this.state.color} bg={this.state.bg}>
            The Query system is helping for finding information that is not available by another command such as finding the top 12 champions with highest total deaths in less than 5 games and do this you could write.
            =champs size-12 sort-td filter-gp,{"<"},5 show-3,8,20 <br/>
            The Commands in the query system are 
            <ul style={x}>
                <li style={x}> Sort: Takes a fields and sorts alphabetically</li>
                <li style={x}> Example: Sort-Wins, Sort-GP  </li>
                <li style={x}> Size: Takes a number and returns that many records </li>
                <li style={x}> Example: Size-12 returns 12 results</li>
                <li style={x}> Show: Takes a list of numbers and only shows those fields</li>
                <li style={x}> Example: Show-1,2,3,4 , Show-all</li>
                <li style={x}> Filter: Takes a Field, An Operation i.e {">"} or == and a number </li>
                <li style={x}> Example: Filter-GP,{">"},4</li>
                <li style={x}> You can combine all of the above at use at once</li> 
                <li style={x}> These can can be used at the same time so you can write  </li>
                <li style={x}> Example: =champs size-5 sort-wr show-1,3,2,7,8 filter-gp,{">"},10</li>
            </ul>
            <div style={{textAlign:"center", color:this.state.color, background:this.state.bg}}>
            The interaction would appear like<br/><br/>
            </div>
            <img
            src={Champs}
            width="60%"
            style={{marginLeft:"20%"}}
            alt="result"
            />
            <br/><br/>
            <HeadB color={this.state.color} bg={this.state.bg}> Champion Fields</HeadB>
            Below is the name of the fields and then what you can type to get that field
            <Row style={x}>
            <Col style={x}>
            <ol style={x}>
                <li style={x}> Presence 
                    <ul style={x}>
                        <li style={x}>presence</li>
                        <li style={x}>p</li>
                    </ul>
                </li>
                <li style={x}> Bans
                    <ul style={x}>
                        <li style={x}> bans</li>
                        <li style={x}> b</li>
                    </ul>
                </li>
                <li style={x}> Games Played                 
                    <ul style={x}>
                        <li style={x}> games played</li>
                        <li style={x}> gp</li>
                    </ul>
                </li>
                <li style={x}> Wins                 
                    <ul style={x}>
                        <li style={x}> wins</li>
                        <li style={x}> w</li>
                    </ul>
                </li>
                <li style={x}> Loses                 
                    <ul style={x}>
                        <li style={x}> loses</li>
                        <li style={x}> l</li>
                    </ul>
                </li>
            </ol>
            </Col>
            <Col style={x}>
            <ol style={x} start="5">
                <li style={x}> Win Ratio                 
                    <ul style={x}>
                        <li style={x}> winrate</li>
                        <li style={x}> wr</li>
                    </ul>
                </li>
                <li style={x}> Kills                
                    <ul style={x}>
                        <li style={x}> kills</li>
                        <li style={x}> k</li>
                    </ul>
                </li>
                <li style={x}> Deaths                 
                    <ul style={x}>
                        <li style={x}> deaths</li>
                        <li style={x}> d</li>
                    </ul>
                </li>
                <li style={x}> Assists                 
                    <ul style={x}>
                        <li style={x}> assists</li>
                        <li style={x}> a</li>
                    </ul>
                </li>
                <li style={x}> (Kills + Assits) / Death                 
                    <ul style={x}>
                        <li style={x}> kda</li>
                    </ul>
                </li>
            </ol>
            </Col>
            <Col style={x}>
                <ol style={x} start="10">
                <li style={x}> Average CS                 
                    <ul style={x}>
                        <li style={x}> avg cs</li>
                        <li style={x}> cs</li>
                    </ul>
                </li>
                <li style={x}> CS Per Minute                 
                    <ul style={x}>
                        <li style={x}> cs per minute</li>
                        <li style={x}> cspm</li>
                    </ul>
                </li>
                <li style={x}> Gold                 
                    <ul style={x}>
                        <li style={x}> gold</li>
                        <li style={x}> g</li>
                    </ul>
                </li>
                <li style={x}> Gold Per Minute                 
                    <ul style={x}>
                        <li style={x}> gold per minute</li>
                        <li style={x}> gpm</li>
                    </ul>
                </li>
                <li style={x}> Kill Particpants                 
                    <ul style={x}>
                        <li style={x}> kill particpants</li>
                        <li style={x}> kp</li>
                    </ul>
                </li>
            </ol>
            </Col>
            <Col style={x}>
            <ol style={x} start="15">
                <li style={x}> Kill Share                 
                    <ul style={x}>
                        <li style={x}> kill share</li>
                        <li style={x}> ks</li>
                    </ul>
                </li>
                <li style={x}> Gold Share                 
                    <ul style={x}>
                        <li style={x}> gold share</li>
                        <li style={x}> gs</li>
                    </ul>
                </li>
                <li style={x}> Postions Played                 
                    <ul style={x}>
                        <li style={x}> positions played</li>
                        <li style={x}> pos</li>
                    </ul>
                </li>
                <li style={x}> Total Kills                 
                    <ul style={x}>
                        <li style={x}> total kills</li>
                        <li style={x}> tk</li>
                    </ul>
                </li>
                <li style={x}> Total Deaths                 
                    <ul style={x}>
                        <li style={x}> total deaths</li>
                        <li style={x}> td</li>
                    </ul>
                </li>
            </ol>
            </Col>
            </Row>
        </TextB>
        </div>
    }
} export default QueryS
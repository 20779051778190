import React from "react";
import Sections from "../../../../Shared/Util/Sections";
import TextB from "../../../../Shared/Text/textB";
import HeadC from "../../../../Shared/Text/HeadC";

class DBss extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            body: "",
            color: props.color ? props.color: "black",
            bg: props.bg ? props.bg : "white"
        } 
    }

    render(){
        let x = {color:this.state.color, background:this.state.bg}
        return <div style={x} className="mx-3">
        <HeadC color={this.state.color} bg={this.state.bg}> Databases </HeadC><TextB color={this.state.color} bg={this.state.bg}>
            The database for this project is to keep a hard copy of everygame so there is a record of all games,
            it is also used for storing user information such as username, and a hashed version of the password aswell what profile picture they choose.
            The database is a MySQL RDS hosted on AWS. 
        </TextB>
        </div>
    }
} export default DBss

import React from "react";
import HeadC from "../../../Shared/Text/HeadC";
import HeadZ from "../../../Shared/Text/HeadZ";
import TextB from "../../../Shared/Text/textB";
import TN from "../../../Shared/Photos/Hons/Thumbnail.png";


class HonOver extends React.Component{

    constructor(props){
        super(props);
        this.state ={
            color: props.color ? props.color: "black",
            bg: props.bg ? props.bg : "white"
        }
    }

    render(){
        return <div className="mx-3" style={{color:this.state.color, background:this.state.bg}}>
            <HeadZ color={this.state.color} bg={this.state.bg}> Honours Project</HeadZ>
            <div style={{display:"grid", justifyContent:"center", alignContent:"center", color:this.state.color, background:this.state.bg}}>
            <img src={TN} style={{width:"25vw"}}/>
            </div>
            <HeadC color={this.state.color} bg={this.state.bg}> Project Goal </HeadC>
            <TextB color={this.state.color} bg={this.state.bg}>
            The goal of this project was to create a programming language similar to Java with similar syntax and support. To do this,
            I chose a target language of Java Bytecode, as this allows me to use all libraries from Java, Groovy, Kotlin, and others in my language.
                This means I would be able to focus on much smaller things, such as syntax and built-in functionality (if, for, switch), etc.  
            </TextB>
            <HeadC color={this.state.color} bg={this.state.bg}> Project Outcome </HeadC>
            <TextB color={this.state.color} bg={this.state.bg}>
            The outcome of this project was achieving all of the initial goals I had set and being able to make all the programmes I had set as goals.
            However, while all goals were met, some were not met to the highest standard because they were completed inefficiently.
            For example, when it came to boolean operations, EXL required more commands than Java, and there was also less gain in time than I was aiming for with flat arrays.
            </TextB>
        </div>
    }
} export default HonOver
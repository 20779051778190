import React from "react";
import { Container, Row} from "react-bootstrap";
import HeadC from "../../Shared/Text/HeadC";
import TextB from "../../Shared/Text/textB";

class Game2048 extends React.Component{

    constructor(props){
        super(props);
        this.state = {
            body: "",
            color: props.color ? props.color: "black",
            bg: props.bg ? props.bg : "white"
        } 
    }
    
    render(){
        let x = {color:this.state.color, background:this.state.bg}
        return <div className="mx-3" style={x}>
                <Row style={x}>
                    <HeadC color={this.state.color} bg={this.state.bg}> Description </HeadC>
                </Row>
                <Row style={x}> 
                    <TextB color={this.state.color} bg={this.state.bg}>
                    This project began in an AI class I had in my third year of university. In the class, I was to implement an AI capable of playing the 2048 game.
                    During the summer, I built a version of the 2048 game that was playable and had a GUI, 
                    as the one provided by my university was command-line only and not playable by a user.<br/> 
                    Later on,I recreated the project in C# to make the AI a lot quicker and I also built a new GUI using Razor that plays the game in the browser.
                    </TextB>
                </Row>
        </div>
    }
} export default Game2048
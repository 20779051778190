import React from "react";
import { Card, Row } from "react-bootstrap";

class Tags extends React.Component {

    constructor(props){
        super(props);
        this.state ={
            tags: props.tags,
            body: "temp", 
            color: props.color ? props.color: "black",
            bg: props.bg ? props.bg : "white",
        };
    }

    render(){
        console.log(this.state.tags)
        let x = this.state.tags.split(", ");
        let bod = []
        x.forEach(
            e => {
                switch(e){
                    case "University":
                        bod.push(
                            <Card className="px-0 mx-2 bx-0" key={e} style={{textAlign:"center", color:"white", width:"110px" , lineHeight:"0.5"
                            , background:"rgba(30, 6, 87)"}}>
                                <Card.Header>
                                    University
                                </Card.Header>
                            </Card>
                        )
                        break;
                    case "Personal":
                        bod.push(
                            <Card className="px-0 mx-2 bx-0" key={e} style={{textAlign:"center",color:"white", width:"110px" , lineHeight:"0.5",
                             background:"rgb(171, 87, 3)"}}>
                                <Card.Header>
                                Personal
                                </Card.Header>
                            </Card>
                        )
                        break;
                    case "Java":
                        bod.push(
                            <Card className="px-0 mx-2 bx-0" key={e} style={{textAlign:"center",color:"white", width:"70px" , lineHeight:"0.5",
                            background:"rgb(237, 121, 5)"}}>
                                <Card.Header>
                                Java
                                </Card.Header>
                            </Card>
                        )
                        break;
                    case "Rust":
                        bod.push(
                            <Card className="px-0 mx-2 bx-0" key={e} style={{textAlign:"center",color:"white", width:"70px" , lineHeight:"0.5",
                            background:"rgb(61, 51, 45)"}}>
                                <Card.Header>
                                Rust
                                </Card.Header>
                            </Card>
                        )
                        break;
                    case "JavaScript":
                        bod.push(
                            <Card className="px-0 mx-2 bx-0" key={e} style={{textAlign:"center",color:"white", width:"110px" , lineHeight:"0.5",
                            background:"rgb(212, 174, 51)"}}>
                                <Card.Header>
                                JavaScript
                                </Card.Header>
                            </Card>
                        )
                        break;
                    case "Python":
                        bod.push(
                            <Card className="px-0 mx-2 bx-0" key={e} style={{textAlign:"center",color:"white", width:"90px" , lineHeight:"0.5",
                            background:"rgb(6, 24, 156)"}}>
                                <Card.Header>
                                Python
                                </Card.Header>
                            </Card>
                        )
                        break;
                    case "Haskell":
                        bod.push(
                            <Card className="px-0 mx-2 bx-0" key={e} style={{textAlign:"center",color:"white", width:"90px" , lineHeight:"0.5",
                            background:"rgb(77, 71, 66)"}}>
                                <Card.Header>
                                Haskell
                                </Card.Header>
                            </Card>
                        )
                        break;
                    case "MySql":
                        bod.push(
                            <Card className="px-0 mx-2 bx-0" key={e} style={{textAlign:"center",color:"white", width:"85px" , lineHeight:"0.5",
                            background:"rgb(66, 135, 245)"}}>
                                <Card.Header>
                                MySql
                                </Card.Header>
                            </Card>
                        )
                        break;
                    case "Git":
                        bod.push(
                            <Card className="px-0 mx-2 bx-0" key={e} style={{textAlign:"center",color:"white", width:"60px" , lineHeight:"0.5",
                            background:"rgb(74, 232, 2)"}}>
                                <Card.Header>
                                Git
                                </Card.Header>
                            </Card>
                        )
                        break;
                    case "Maven":
                        bod.push(
                            <Card className="px-0 mx-2 bx-0" key={e} style={{textAlign:"center",color:"white", width:"87px" , lineHeight:"0.5",
                            background:"rgb(237, 24, 5)"}}>
                                <Card.Header>
                                Maven
                                </Card.Header>
                            </Card>
                        )
                        break;
                    case "LaTeX":
                        bod.push(
                            <Card className="px-0 mx-2 bx-0" key={e} style={{textAlign:"center",color:"white", width:"87px" , lineHeight:"0.5",
                            background:"rgb(154, 179, 252)"}}>
                                <Card.Header>
                                LaTex
                                </Card.Header>
                            </Card>
                        )
                        break;
                    case "Solo":
                        bod.push(
                            <Card className="px-0 mx-2 bx-0" key={e} style={{textAlign:"center",color:"white", width:"72px" , lineHeight:"0.5",
                            background:"rgb(12, 135, 104)"}}>
                                <Card.Header>
                                Solo
                                </Card.Header>
                            </Card>
                        )
                        break;
                    case "Group":
                    bod.push(
                        <Card className="px-0 mx-2 bx-0" key={e} style={{textAlign:"center",color:"white", width:"85px" , lineHeight:"0.5",
                        background:"rgb(16, 38, 54)"}}>
                            <Card.Header>
                            Group
                            </Card.Header>
                        </Card>
                    )
                        break;
                    case "Spring Boot":
                        bod.push(
                            <Card className="px-0 mx-2 bx-0" key={e} style={{textAlign:"center",paddingLeft:"0", color:"white", width:"125px" , lineHeight:"0.5",
                            background:"rgb(132, 7, 173)"}}>
                                <Card.Header>
                                Spring Boot
                                </Card.Header>
                            </Card>
                        )
                        break;
                    case "React":
                        bod.push(
                            <Card className="px-0 mx-2 bx-0" key={e} style={{textAlign:"center",color:"white", width:"80px" , lineHeight:"0.5",
                            background:"rgb(219, 4, 105)"}}>
                                <Card.Header>
                                React
                                </Card.Header>
                            </Card>
                        )
                        break;
                    case "ASM":
                        bod.push(
                            <Card className="px-0 mx-2 bx-0" key={e} style={{textAlign:"center",color:"white", width:"75px" , lineHeight:"0.5",
                            background:"rgb(232, 116, 139)"}}>
                                <Card.Header style={{background:"rgb(232, 116, 139)"}}>
                                ASM
                                </Card.Header>
                            </Card>
                        )
                        break;
                    case "Ubuntu":
                        bod.push(
                            <Card className="px-0 mx-2 bx-0" key={e} style={{textAlign:"center",color:"white", width:"95px" , lineHeight:"0.5",
                            background:"rgb(117, 3, 128)"}}>
                                <Card.Header>
                                Ubuntu
                                </Card.Header>
                            </Card>
                        )
                        break;
                    case "JDA":
                        bod.push(
                            <Card className="px-0 mx-2 bx-0" key={e} style={{textAlign:"center",color:"white", width:"70px" , lineHeight:"0.5",
                            background:"rgb(77, 2, 29)"}}>
                                <Card.Header>
                                JDA
                                </Card.Header>
                            </Card>
                        )
                        break;
                    case "Riot API":
                        bod.push(
                            <Card className="px-0 mx-2 bx-0" key={e} style={{textAlign:"center",color:"white", width:"100px" , lineHeight:"0.5",
                            background:"rgb(7, 46, 6)"}}>
                                <Card.Header>
                                Riot Api
                                </Card.Header>
                            </Card>
                        )
                        break;
                    case "Tkinter":
                        bod.push(
                            <Card className="px-0 mx-2 bx-0" key={e} style={{textAlign:"center",color:"white", width:"95px" , lineHeight:"0.5",
                            background:"rgb(56, 3, 22)"}}>
                                <Card.Header>
                                Tkinter
                                </Card.Header>
                            </Card>
                        )
                        break;
                    case "Swing":
                        bod.push(
                            <Card className="px-0 mx-2 bx-0" key={e} style={{textAlign:"center",color:"white", width:"85px" , lineHeight:"0.5",
                            background:"rgb(46, 33, 4)"}}>
                                <Card.Header>
                                Swing
                                </Card.Header>
                            </Card>
                        )
                        break;
                    case "Agda":
                        bod.push(
                            <Card className="px-0 mx-2 bx-0" key={e} style={{textAlign:"center",color:"white", width:"80px" , lineHeight:"0.5",
                            background:"rgb(3, 9, 43)"}}>
                                <Card.Header>
                                Agda
                                </Card.Header>
                            </Card>
                        )
                        break;
                    case "None":
                        break;
                    default:
                        console.log("WEIRD CHAMP" + e)
                }
            }
        )
        return <Row style={{color:this.state.color, background:this.state.bg}}>
            {bod}
        </Row>
    }
} export default Tags;
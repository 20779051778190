import React from "react";
import { Row, Col} from "react-bootstrap";
import HeadB from "../../../../Shared/Text/HeadB";
import HeadC from "../../../../Shared/Text/HeadC";
import TextB from "../../../../Shared/Text/textB";
class REval extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            body: "",
            color: props.color ? props.color: "black",
            bg: props.bg ? props.bg : "white"
        } 
    }

    render(){
        let x = {color:this.state.color, background: this.state.bg}
        return <div style={x} className="mx-3">
            <HeadC color={this.state.color} bg={this.state.bg}> Evaluator</HeadC>
       <TextB color={this.state.color} bg={this.state.bg}>
            The evaluator is the part that goes through the instruction and keeps track of the memory, stack, and point. It takes the main function and starts to execute the code; while doing this, if there is a function call, the "first" evaluator will create more evaluators to run that function and return the result.
            It does this by creating the new evaluator with the parameters already stored in the new memory.
            This process can repeat with an endless number of evaluators, but when the programme ends, all the evaluators must return a value so the main evaluator can complete its function and return the final value of the program.
            <br/><br/>
            
            Note: You may have noticed that the above means that we always pass by copy, 
            which is true as there are no objects in my language and String is an immutable type,
            meaning you always create a new one and discard the old String, 
            so being passed by copy is not an issue for this interpreter.
            </TextB>
        </div>
    }
} export default REval
import React from "react";
import HeadC from "../../../Shared/Text/HeadC";
import TextB from "../../../Shared/Text/textB";
import funcP from "../../../Shared/Photos/Func/TN.png"
import HeadZ from "../../../Shared/Text/HeadZ";


class FunOver extends React.Component{

    constructor(props){
        super(props);
        this.state ={
            color: props.color ? props.color: "black",
            bg: props.bg ? props.bg : "white"
        }
    }

    render(){
        return <div className="mx-3" style={{color:this.state.color, background:this.state.bg}}>
            <HeadZ color={this.state.color} bg={this.state.bg}> Functional Programming</HeadZ>
            <div style={{display:"grid", justifyContent:"center", alignContent:"center", color:this.state.color, background:this.state.bg}}>
            <img src={funcP} style={{width:"25vw"}}/>
            </div>
            <HeadC color={this.state.color} bg={this.state.bg}> Project Goal </HeadC>
            <TextB color={this.state.color} bg={this.state.bg}>
                These were classes I undertook during university, so my goals were to get good grades and to get a good understanding of functional programming.
            </TextB>
            <HeadC color={this.state.color} bg={this.state.bg}> Project Outcome </HeadC>
            <TextB color={this.state.color} bg={this.state.bg}>
            82/100 in functional and 61/100 in advanced functional were the results.
            I am happy with my progress in Haskell, as I feel I have a good grasp of the language and how to use it. On the other hand, Agda,
            I feel I have a decent grasp of the language but not the large advantages of the language as I didn't fully grasp concepts like category theory.
            </TextB>
        </div>
    }
} export default FunOver
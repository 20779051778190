import React from "react";
import { Col, Row } from "react-bootstrap";
import HeadB from "../../../Shared/Text/HeadB";
import HeadC from "../../../Shared/Text/HeadC";
import TextB from "../../../Shared/Text/textB";

class Whtml extends React.Component{

    constructor(props){
        super(props);
        this.state = {
            body: "",
            color: props.color ? props.color: "black",
            bg: props.bg ? props.bg : "white"
        } 
    }

    render(){
        let x = {color:this.state.color, background:this.state.bg}
        return <div style={x} className="mx-3">
            <HeadC color={this.state.color} bg={this.state.bg} > HTML</HeadC>
            <HeadB color={this.state.color} bg={this.state.bg} > Description</HeadB>
            <TextB color={this.state.color} bg={this.state.bg} >
                The HTML would be generated from a list of objects that are similar to HTML tags that the user would create when using the builder that are controlled by the CLI.                <br/> 
                The objects you can generate 
                <Row style={x}>
                    <Col style={x}>
                        <ul style={x}>
                            <li style={x}> Button</li>
                            <li style={x}> Heading</li>
                        </ul>
                    </Col>
                    <Col style={x}>
                        <ul style={x}>
                            <li style={x}> Paragraph</li>
                            <li style={x}> List</li>
                        </ul>
                    </Col>
                    <Col style={x}>
                        <ul style={x}>
                            <li style={x}> Table</li>
                            <li style={x}> Div </li>
                        </ul>
                    </Col>
                </Row>
                <HeadB color={this.state.color} bg={this.state.bg} > Button</HeadB>
                A button that can be pressed on the screen, its fields were
                <ul style={x}>
                    <li style={x}> Text: The text that would appear on the button</li>
                    <li style={x}> Function: The function that would be called when button pressed</li>
                </ul>
                <HeadB color={this.state.color} bg={this.state.bg} > Heading </HeadB>
                A heading would display text in a larger and bolder font, its fields were
                <ul style={x}>
                    <li style={x}> Text: The text that would be displayed </li>
                    <li style={x}> Level: There were six different levels that a heading could have that would determine its font size and weight</li>
                </ul>
                <HeadB color={this.state.color} bg={this.state.bg} > Paragraph</HeadB>
                A paragraph would display some text. Its fields were
                <ul style={x}>
                    <li style={x}> Text: The text that would be displayed </li>
                </ul>
                <HeadB color={this.state.color} bg={this.state.bg} > List</HeadB>
                A list would display a list of items, one under the other, and its fields are
                <ul style={x}>
                    <li style={x}> Elements: The list of text you want displayed </li>
                    <li style={x}> Type: There are 3 types of lists: unordered, ordered, and described</li>
                </ul>
                <HeadB color={this.state.color} bg={this.state.bg}> Table</HeadB>
                A table would show a table of information with a heading row and all other rows underneath it.
                <ul style={x}>
                    <li style={x}> Titles: The headings of each field </li>
                    <li style={x}> Contents: The other rows</li>
                </ul>
                <HeadB color={this.state.color} bg={this.state.bg} > Div</HeadB>
                A div is just a way to group other tags that come one after the other into one big group under one name, its fields were
                <ul style={x}>
                    <li style={x}> Children: all of the tags included int this div</li> 
                </ul>
                <HeadB color={this.state.color} bg={this.state.bg} > Also</HeadB>
                All of these objects have an additional two fields that were
                <ul style={x}>
                    <li style={x}> Class</li>
                    <li style={x}> id</li> 
                </ul> 
                These are used for adding CSS to certain groups of tags.
            </TextB>
        </div>
    }

} export default Whtml
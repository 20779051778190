import React from "react";
import { Col, Row } from "react-bootstrap";
import TextB from "../../../../Shared/Text/textB";
import { Player } from "video-react";
import CL  from "../../../../Shared/Videos/2048/CL.mp4" 

import "../../../../../node_modules/video-react/dist/video-react.css";
import HeadC from "../../../../Shared/Text/HeadC";
<link rel="stylesheet" href="/css/video-react.css" />

class WoGui extends React.Component{

    constructor(props){
        super(props);
        this.state = {
            body: "",
            color: props.color ? props.color: "black",
            bg: props.bg ? props.bg : "white"
        } 
    }
    
    render(){
        let x = {color:this.state.color, background:this.state.bg}
        return <div style={x} className="mx-3"><HeadC color={this.state.color} bg={this.state.bg}> Without GUI</HeadC><TextB color={this.state.color} bg={this.state.bg}>
            The Without GUI section is running the command-line version of the game, with the AI making moves 
            until the game is over.
            There is a <a href="https://github.com/NineSixFourteen/Remaking_2048/tree/main/2048/backend/Bench">Benchmark</a> folder
            that, when run, will run 10 games of the C# version with concurrency, 10 games of the C# version without concurrency,
            and 10 games of the Python version. 
            It will run them one after the other. I chose to have a C# without concurrency as it is more accurate to the 
            Python version that does not have concurrency.
            <br/>The average stats from when I run the benchmark where
            <br/><br/>
            <Row style={x}>
                <Col style={x}>
                    <span style={{fontSize:"200%", fontWeight:"600", color:this.state.color, background:this.state.bg}}>C# with concurrency</span><br/>
                    <ul style={x}>
                        <li style={x}>Average score: 75,362</li>
                        <li style={x}>Average Total moves: 3,478 </li>
                        <li style={x}>Average Time:  32.78 Seconds</li>
                        <li style={x}>Average Moves per second: 105 </li>
                    </ul>
                </Col>
                <Col style={x}>
                    <span style={{fontSize:"200%", fontWeight:"600", color:this.state.color, background:this.state.bg}}>C#</span><br/>
                    <ul style={x}>
                        <li style={x}>Average score: 53,431 </li>
                        <li style={x}>Average Total moves: 4,010 </li>
                        <li style={x}>Average Time: 140.03 seconds </li>
                        <li style={x}>Average Moves per second: 29 </li>
                    </ul>
                </Col>
                <Col style={x}>
                    <span style={{fontSize:"200%", fontWeight:"600", color:this.state.color, background:this.state.bg}}>Python</span><br/>
                    <ul style={x}>
                        <li style={x}>Average score: 56,165</li>
                        <li style={x}>Average Total moves: 2,672 </li>
                        <li style={x}>Average Time: 1,871 Seconds </li>
                        <li style={x}>Average Moves per second: 1.4 </li>
                    </ul>
                </Col>
            </Row>
            <br/>
            <br/>
            For a visual comparison of how much faster the C# version is, here is a video.
            <Player
                playsInline
                src={CL}
            />
            <br/>
            Completion Time <br/>
            C# W/ Con : 29 Seconds <br/>
            C# : 2 Minutes 50 Seconds<br/>
            Python : 4 Minutes 50 Seconds <br/>
            <br/>
        </TextB>
        </div>
    }
} export default WoGui

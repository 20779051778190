import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Checklist from "../../Shared/Util/CheckList";
import HeadB from "../../Shared/Text/HeadB";
import HeadC from "../../Shared/Text/HeadC";
import HeadS from "../../Shared/Text/HeadS";
import Tags from "../../Shared/Util/Tags";
import TextB from "../../Shared/Text/textB";
import TagFactory from "../../Shared/Util/TagFactory";

class BMSpec extends React.Component{

    constructor(props){
        super(props);
        this.state = {
            body: "",
            color: props.color ? props.color: "black",
            bg: props.bg ? props.bg : "white"
        } 
    }

    render(){
        let x = {color:this.state.color, background: this.state.bg}
        return <div style={x} className="mx-3">
                <TextB color={this.state.color} bg={this.state.bg}>
                    <HeadC color={this.state.color} bg={this.state.bg}> Specifiation</HeadC>
                    <HeadS color={this.state.color} bg={this.state.bg}> Final Goal</HeadS>
                    To build a program that could give you the shortest path between any two stops on the metro.
                    <HeadS color={this.state.color} bg={this.state.bg}> Technoloogy Used</HeadS>
                </TextB> 
                <Row style={x}> 
                    <Col style={x} sm={2}><TextB color={this.state.color} bg={this.state.bg}> Project Type : </TextB></Col>
                    <Col style={x}><Row style={x}>{TagFactory.makeTags("t", "University").concat(TagFactory.makeTags("ts", "Medium"))} </Row> </Col>
                </Row>
                <Row style={x}> 
                    <Col style={x} sm={2}><TextB color={this.state.color} bg={this.state.bg}> Language : </TextB></Col>
                    <Col style={x}><Row style={x}>{TagFactory.makeTags("l", "Java")} </Row></Col>
                </Row>
                <Row style={x}> 
                    <Col style={x} sm={2}><TextB color={this.state.color} bg={this.state.bg}> Technoloogy used : </TextB></Col>
                    <Col><Row style={x}>{TagFactory.makeTags("to","Git, Swing")} </Row></Col>
                </Row>
                    <TextB color={this.state.color} bg={this.state.bg}>
                    <HeadS color={this.state.color} bg={this.state.bg}> Goals</HeadS>
                    <Checklist 
                    name = "Project Goals"
                    items={
                        [
                            {done:true, message:"Build a backend using Dijkstra's algorithm to find the shortest path."},
                            {done:true, message:"Build a GUI to interact with the back end."},
                        ]
                    }/>
                    <br/>
                    <Checklist 
                    name = "Backend"
                    items={
                        [
                            {done:true, message:"Can load in data about the metro system."},
                            {done:true, message:"Uses Dijkstra's algorithm to find the shortest path."},
                        ]
                    }/>
                    <br/>
                    <Checklist 
                    name = "GUI"
                    items={
                        [
                            {done:true, message:"Can select both stations by selecting them."},
                            {done:true, message:"Can access backend to find path."},
                            {done:true, message:"Display the path to the user on the GUI."},
                        ]
                    }/><br/>
                </TextB>
        </div>
    }
} export default BMSpec 
import React from "react";
import HeadB from "../../../../Shared/Text/HeadB";
import HeadC from "../../../../Shared/Text/HeadC";
import TextB from "../../../../Shared/Text/textB";

class Pgame extends React.Component{

    constructor(props){
        super(props);
        this.state = {
            body: "",
            color: props.color ? props.color: "black",
            bg: props.bg ? props.bg : "white"
        } 
    }
    

    render(){
        let x = {color:this.state.color, background:this.state.bg}
        return <div style={x} className="mx-3">
            <HeadC color={this.state.color} bg={this.state.bg}> Python Board</HeadC>
            <TextB color={this.state.color} bg={this.state.bg}> 
            The 2048 game I created has the same rules as the  <a style={x} href="https://en.wikipedia.org/wiki/2048_(video_game)"> standerd game</a>, which include
                <ul style={x}>
                    <li style={x}> Ability to move in any of the 4 directions</li>
                    <li style={x}> Merging of tiles on colision</li> 
                    <li style={x}> Game Logic</li>
                </ul>
            <HeadB color={this.state.color} bg={this.state.bg}> AI</HeadB>
            The AI I built to play the game was 
            <ul style={x}>
                <li style={x}> An Expectimax AI meaning
                    <ul style={x}> 
                        <li style={x}> It generates every possible table that could result from a move, scores them, and averages all scores.</li>
                        <li style={x}> It will make the move with the highest average board score.</li>
                    </ul>
                </li>
                <li style={x}> It look a depth of 2 Moves a head in early game and 4 Moves when the above a certain score</li>
                <li style={x}> It computes the board score by
                    <ul style={x}>
                        <li style={x}> Score of all tiles on the board</li>
                        <li style={x}> Weighting the position of each piece on the board using a snake pattern</li>
                    </ul>
                </li> 
                <li style={x}> Uses a transposition table to speed up calculations</li>
            </ul>
            </TextB>
            </div>
    }

} export default Pgame
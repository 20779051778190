import React from "react";
import HeadB from "../../../../Shared/Text/HeadB";
import HeadC from "../../../../Shared/Text/HeadC";
import TextB from "../../../../Shared/Text/textB";

class DeleteP extends React.Component{

  constructor(props){
    super(props);
    this.state = {
        body: "",
        color: props.color ? props.color: "black",
        bg: props.bg ? props.bg : "white"
    } 
}

    render(){
      let x = {color:this.state.color, background:this.state.bg}
      return <div style={x} className="mx-3"><HeadC color={this.state.color} bg={this.state.bg}> Delete Route</HeadC><TextB color={this.state.color} bg={this.state.bg}>
        The Mappings to the Functions responsible for deleting data from the Database can be found in this <a style={x} href="https://github.com/NineSixFourteen/Lol-Personal-Stat-Checker/blob/main/Lol-Personal-Stats/src/main/java/Stats/FrontEnd/DeleteController.java">file</a>.
        <br/>
        The Functions for deleting are
        <HeadB color={this.state.color} bg={this.state.bg}> Match</HeadB>
        Takes MatchID(String)<br/>
        This Function is for deleting all records related to a match it does this by 
        <ul style={x}>
          <li style={x}> By searching each of tables for records that match the MatchId</li>
            <li style={x}> For every result record
              <ul style={x}> 
                <li style={x}> Get the id of that record </li>
                <li style={x}> Use the deleteByID function of the repositorys</li>
              </ul>
            </li>
          <li style={x}> Send "Deleted Match {"{MatchId}"}"" message</li>
        </ul>
        <HeadB color={this.state.color} bg={this.state.bg}> Player</HeadB>
        Takes PlayerName(String)<br/>
        This function will delete all games that a player was in. It does this by 
        <ul style={x}>
          <li style={x}> Search all Match Overall 1 Records that have name feild equal {"{PlayerName}"}</li>
          <li style={x}> Extract a list of MatchIds that the player apears in from the MatchOverall 1 List</li>
          <li style={x}> Delete the matches same as in the deleteMatch Function using the MatchID list </li>
        </ul>
        <HeadB color={this.state.color} bg={this.state.bg}> Clean</HeadB>
        Takes Nothing <br/>
        This function is to delete any duplicate games or errors records from the database. It does this by
        <ul style={x}>
          <li style={x}> Search through every record in Match History
            <ul style={x}>
              <li style={x}> 
                Check if match had been previously seen
                <ul style={x}>
                  <li style={x}> If it has add to list of dups</li>
                  <li style={x}> If not add to seen list</li>
                </ul>
              </li>
            </ul>
          </li>
          <li style={x}>Delete all records of the duplicate matches same way as DeletMatch</li>
          <li style={x}>Add all games back once see Add Section</li>
        </ul>
      </TextB>
      </div>
    }
  } export default DeleteP
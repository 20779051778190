import React from "react";
import { useEffect, useState } from "react";
import { motion } from "framer-motion";
import { Row, Col } from "react-bootstrap";
import Typewriter from 'typewriter-effect';
import "./Home.css"
import { Link } from "react-router-dom";

class Home extends React.Component{

    render(){
        return <motion.div 
                exit={{width:"0", transition:1.2, opacity:0}}
                style={{minHeight:"100vh", height:window.screen.height-100, background:"rgb(9, 1, 20)", cursor:"url"}}>
                {<Component string={"yes"} />}
                <div>
                    <span className="b-0 p-0 m-0" style={{
                        fontWeight:"bold",
                        display:"flex",
                        fontSize:"9.5vh",
                        fontFamily:"Verdana",
                        color:"white", margin:"0",
                        alignItems:"center",
                        justifyContent:"center",
                        }}> 
                        <span className="py-1"> 
                            <span style={{position:"relative", top:"14.2vh",zIndex:"5"}}>
                                <Typewriter
                                onInit={(typewriter) => {
                                    typewriter.typeString("Hi")
                                    .pauseFor(1500)
                                    .typeString(", I'm ")
                                    .typeString("<bold class='as'>Thomas</bold>")
                                    .pauseFor(30000)
                                    .deleteAll()
                                    .typeString("Click a button Already!")
                                    .pauseFor(10000)
                                    .deleteAll()
                                    .typeString("I would recomend Projects")
                                    .pauseFor(10000)
                                    .deleteAll()
                                    .typeString("Are you there??")
                                    .pauseFor(6000)
                                    .deleteAll()
                                    .typeString("Hi")
                                    .typeString(", I'm ")
                                    .typeString("<bold class='as'>Thomas</bold>")
                                    .start();
                                }}
                                />
                            </span> 
                        </span>
                    </span>
                    <span style={{
                        display:"flex",
                        fontSize:"6vh",
                        fontFamily:"Helvetica",
                        margin:"0",
                        alignItems:"center",
                        justifyContent:"center",
                        color:"rgb(158, 90, 190)",
                        
                    }}> 
                        <span className="py-3"> 
                            <span style={{position:"relative", top:"14.2vh",zIndex:"5"}}>
                                <motion.p
                                initial={{ opacity:0}}
                                animate={{ opacity:1}}
                                transition={{ delay:5, duration: 1}}
                                >
                                    Computer Science Graduate</motion.p>
                            </span> 
                        </span>
                    </span>
                </div>
                <motion.div className="py-2" style={{position:"relative", top:"14.2vh"}}> 
                    <Buttonh Name={"About Me"} delay={0.6} link={"/about"}/>
                    <Buttonh Name={"CV"} delay={0.8} link={"/cv"}/>
                    <Buttonh Name={"Projects"} delay={1} link={"/projects/all"}/>
                    <Buttonh Name={"Github"} delay={1.2} link={"https://github.com/NineSixFourteen"}/>
                </motion.div>
            </motion.div  >
    }
} export default Home


class Buttonh extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            Name:props.Name,
            link:props.link,
            delay:props.delay,
            color:"white",
            background:"rgb(9, 1, 20)",
        }
    }

    render(){
        let x = window.screen.availWidth > window.screen.availHeight; 
        let z = <div className="py-3" style={{display:"flex", alignItems:"center", justifyContent:"center" }}>
        <motion.button
            initial={{ opacity:0, height:0,width:0, fontSize:0}}
            animate={{ opacity:1, height:"100%", width:"35vw", fontSize: x ? "4.7vh" : "3vh"}}
            transition={{
                width:{
                    duration:0.4,
                    delay:5.2 + this.state.delay
                },
                height:{
                    duration:0.4,
                    delay:5.2 + this.state.delay
                },
                opacity:{
                    duration:0.4,
                    delay:5.2 + this.state.delay
                },
                fontSize:{
                    duration:0.4,
                    delay:5.2 + this.state.delay
                }
              
            }}
            whileHover={{scale:1.2, backgroundColor:"cyan", color:"black"}}
            whileTap={{scale:1.1, backgroundColor:"cyan", color:"grey"}}
                className="py-1"  
                style={
                    {border:"4px solid rgb(212, 122, 122)", borderRadius:"2em", textAlign:"center",color:this.state.color, 
                    fontFamily:"fantasy",fontWeight:"700", background:this.state.background, cursor:"pointer", position:"relative", zIndex:"5"}} 
            >
            {this.state.Name}
            </motion.button>
        </div>;
        if(this.state.link.charAt(0) === '/'){
            return <Link style={{textDecoration:"none"}} to={this.state.link}>
                {z}
            </Link>
        } else{
            return <div onClick={() => window.location.href=this.state.link}>
                {z}
            </div>
        }

    }

}

class Square extends React.Component{

    constructor(props){
        super(props)
        this.state = {
            color: "transparent"
        }   
    }

    async changeColor(){
        this.setState({color:"red"})
        await this.timeout(140);
        this.setState({color:"blue"});
        await this.timeout(100);
        this.setState({color:"green"});
        await this.timeout(100);
        this.setState({color:"yellow"});
        await this.timeout(100);
        this.setState({color:"transparent"});
    }

    timeout(delay) {
        return new Promise( res => setTimeout(res, delay) );
    }

    render(){
        return <div onMouseEnter={() => this.setState({color:"red"})} onMouseLeave={() => this.changeColor()}
            style={{background:this.state.color, width:"100%", height:"100%"}} 
        ></div >
    }
}

function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height
    };
  }
  
function useWindowDimensions() {
const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
);
  
useEffect(() => {
    function handleResize() {
    setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
}, []);

return windowDimensions;
}
  
const Component = (string) => {
    console.log(string)
    const { height, width } = useWindowDimensions(); 
    let cubeSide = height / 30;
    let perRow = width / cubeSide - 2;
    let grid = [];
    let i = 0; 
    while(i < 30){
        let l = 0; 
        let row = [];
        while(l < perRow){
            row.push(<Col className="p-0 m-0 b-0" style={{minWidth:cubeSide, minHeight:cubeSide, background:""}}>
                <Square />    
            </Col>)
            l++;
        }
        grid.push(<Row className="p-0 m-0 b-0" >{row}</Row>)
        i++;
    }

    return (
        <motion.div 
        
            style={{position:"absolute", top:0, left:0, zIndex:3}}>
            {grid}
        </motion.div>
    );
    
};
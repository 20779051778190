import React from "react";
import { Container, Row, Button } from "react-bootstrap";

class Sections extends React.Component{

    constructor(props){
        super(props);
        this.state ={
            Sections: props.Sections,
            Buttons: "temper",
            body: "temp"
        };
    }

    componentDidMount(){
        this.makeButtons();
        this.update(0);
    }

    makeButtons(){
        let secs = this.state.Sections;
        let wid = secs.length  > 6 ? 15 : 96 / secs.length ;
        let x = []
        secs.forEach(sec => {
            x.push(
                <>
                    <Button className="mx-1 my-1" key={sec.Title}style={{ width:wid+"%", fontSize:"125%", fontWeight:"600" }}
                    variant="outline-dark" onClick={() => this.update(sec.val)}> {sec.Title} </Button>
                </>
            )
        });
        this.setState({Buttons: x})
    }

    update(num){
        let x = this.state.Sections[num].Body;
        this.setState({body:x})
    }

    render(){
        return <>
                <Container>
                    <Row className="my-2" style={{marginLeft:"2%"}}>
                        {this.state.Buttons}
                    </Row>
                    <Row >
                        {this.state.body}
                    </Row>
                </Container>
            </>
    }

} export default Sections
import React from "react"
import HeadB from "../../../Shared/Text/HeadB"
import TextB from "../../../Shared/Text/textB"
import Img from "../../../Shared/Photos/WAM/Mobile.png"

class MobP extends React.Component{

    constructor(props){
        super(props)
        this.state = {
            body: "",
            color: props.color ? props.color: "black",
            bg: props.bg ? props.bg : "white"
        }
    }

    render(){
        let x = {color:this.state.color, background: this.state.bg}
        return <div className="mx-3" style={x}>
            <HeadB color={this.state.color} bg={this.state.bg}> Mobile</HeadB>
            <TextB color={this.state.color} bg={this.state.bg}>
                The notable mobile apps I created during this class
                <ul style={x}>
                    <li style={x}> Currency Calculator</li>
                    <li style={x}> COVID relif app</li>
                </ul>
                <HeadB color={this.state.color} bg={this.state.bg}> Currency Calculator</HeadB>
                <div style={{textAlign:"center", background:this.state.bg}}><img src={Img} height="40%"/> <br/></div>
                The Currency Calculator Features are 
                <ul style={x}>
                    <li style={x}> designed for mobile portrait and landscape use</li>
                    <li style={x}> The app is usable offline.</li>
                    <li style={x}> Using AJAX, it retrieves JSON containing all currency converted to Euros.</li>
                    <li style={x}> Can translate between 32 different currencies</li>
                    <li style={x}> Can also include a bank fee in the calculation. </li>
                    <li style={x}> Uses Local Storage to remember the last used options</li>
                </ul>
                <HeadB olor={this.state.color} bg={this.state.bg}> COVID refif app</HeadB>
                The Covids Relif app features
                <ul style={x}>
                    <li style={x}> designed for mobile portrait and landscape use</li>
                    <li style={x}> Forum chats using MySql Database</li>
                    <li style={x}> Pedometer to track steps each a day</li>
                    <li style={x}> Daily To-Do List </li>
                    <li style={x}> Page of charities and organisations to contact if struggling</li> 
                </ul>
            </TextB>
        </div>
    }
} export default MobP
import React from "react";
import {Col, Row } from "react-bootstrap";
import ImageViewer from 'react-simple-image-viewer';

import Champs from "../../Shared/Photos/Discord/champsC.png"
import stats from "../../Shared/Photos/Discord/stat.png"
import kills from "../../Shared/Photos/Discord/kills.png"
import modal from "../../Shared/Photos/Discord/modal.png"


class DicordGal extends React.Component{

    constructor(props){
        super(props)
        this.state = {
            body: "",
            color: props.color ? props.color: "black",
            bg: props.bg ? props.bg : "white"
        }
    }

    onClose(){
        this.setState({body:""})
    }

    show(num){
        console.log(this.state.ind)
        let x =             
            <ImageViewer
                src={[
                    Champs, stats, kills, modal
                ] }
                currentIndex={ num}
                disableScroll={ false }
                closeOnClickOutside={ true }
                onClose={ () => this.onClose()}
            />
        this.setState({body:x})
    }

    render(){
        let x = {color:this.state.color, background:this.state.bg}
        return <div className="mx-3" style={x}>
            <Row style={x} className="py-3 px-3">
                <Col style={x}  >
                    <div 
                    style={{height:"50vh", width:"30vw",
                        backgroundImage:  "url(" + Champs + ")", 
                        backgroundRepeat: 'no-repeat',backgroundSize:"cover"}}
                        onClick={() => this.show(0)}
                        />
                </Col>

                <Col style={x} >
                    <div 
                    style={{height:"50vh", width:"30vw",
                        backgroundImage:  "url(" + kills + ")", 
                        backgroundRepeat: 'no-repeat',backgroundSize:"cover"}}
                        onClick={() => this.show(2)}
                        />
                </Col>
            </Row>
            <Row style={x}  className="py-3 px-3">
                <Col style={x} >
                    <div 
                    style={{height:"70vh", width:"30vw",
                        backgroundImage:  "url(" + modal + ")", 
                        backgroundRepeat: 'no-repeat',backgroundSize:"cover"}}
                        onClick={() => this.show(3)}
                        />
               </Col>
               <Col style={x} >
                    <div 
                    style={{height:"70vh", width:"30vw",
                        backgroundImage:  "url(" + stats + ")", 
                        backgroundRepeat: 'no-repeat',backgroundSize:"cover"}}
                        onClick={() => this.show(1)}
                        />
                </Col>
            </Row>
            <br/><br/><br/>
            {this.state.body}
        </div>
    }

} export default DicordGal 
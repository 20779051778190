import React from "react";
import HeadB from "../../../../Shared/Text/HeadB";
import HeadC from "../../../../Shared/Text/HeadC";
import TextB from "../../../../Shared/Text/textB";


class Winterface extends React.Component{

    constructor(props){
        super(props);
        this.state = {
            body: "",
            color: props.color ? props.color: "black",
            bg: props.bg ? props.bg : "white"
        } 
    }

    render(){
        let x = {color:this.state.color, background:this.state.bg}
        return <div className="mx-3" style={x}>
            <HeadC color={this.state.color} bg={this.state.bg} > Interfaces</HeadC>
            <HeadB color={this.state.color} bg={this.state.bg} > Description</HeadB>
            <TextB color={this.state.color} bg={this.state.bg} >
            The interfaces are the front end that a user will interact with in order to create or edit a page.
            The interfaces are split up into three different sections, and the main interface is where the program starts from on launch.
            The main interface is responsible for asking you if you want to view/edit your HTML, CSS, or JS, as well as asking when you are done so it can build the site.
            </TextB>
        </div>
    }

} export default Winterface

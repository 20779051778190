import React from "react";

import NewProjPage from "../../Shared/Util/newProjPage";
import Holder from "../../Shared/Util/Holder";
import Whtml from "./Descriptions/Whtml";
import Wcss from "./Descriptions/Wcss";
import Winterface from "./Descriptions/Interface/Winterface";
import HTMLInt from "./Descriptions/Interface/HTMLI";
import CSSInt from "./Descriptions/Interface/CssI";
import JSInt from "./Descriptions/Interface/JSI";
import WebpageBuild from "./WebpageBuild";
import WebSpec from "./WebSpec";
import WebGal from "./WebGal";
import WebOver from "./WebOver";
import WebPlan from "./WebPlan";
import WebNav from "./WebNav";

class StatsPage extends React.Component{

    constructor(props){
        super(props);
        this.state ={
            color: "white",
            bg: "rgb(20,20,20)"
        }
    }

    flip(){
        if(this.state.color == "white"){
            this.setState({
                color: "rgb(20,20,20)", bg:"white"
            })
        } else {
            this.setState({
                color: "white", bg:"rgb(20,20,20)"
            })
        }

    }

    render(){
        let x = {color:this.state.color, bg:this.state.bg}
        return <NewProjPage 
            func={this.flip.bind(this)}
            childs={
                [
                    {Title: "Overview", body:new Holder(<WebOver/>,<WebOver color={"white"} bg={"rgb(20,20,20)"}/>), childs:[
                        {Title: "Future Goals",body:new Holder(<WebPlan/>,<WebPlan color={"white"} bg={"rgb(20,20,20)"}/>), childs:[]},
                    ]},
                    {Title: "Specification", body:new Holder(<WebSpec/>,<WebSpec color={"white"} bg={"rgb(20,20,20)"}/>), childs:[]},
                    {Title: "Description", body:new Holder(<WebpageBuild/>,<WebpageBuild color={"white"} bg={"rgb(20,20,20)"}/>), childs:[
                        {Title: "HTML", body:new Holder(<Whtml/>,<Whtml color={"white"} bg={"rgb(20,20,20)"}/>), childs:[]},
                        {Title: "CSS/JS", body:new Holder(<Wcss/>,<Wcss color={"white"} bg={"rgb(20,20,20)"}/>), childs:[]},
                        {Title: "Interface", body:new Holder(<Winterface/>,<Winterface color={"white"} bg={"rgb(20,20,20)"}/>), childs:[
                            {Title: "HTML Interface", body:new Holder(<HTMLInt/>,<HTMLInt color={"white"} bg={"rgb(20,20,20)"}/>), childs:[]},
                            {Title: "CSS Interface", body:new Holder(<CSSInt/>,<CSSInt color={"white"} bg={"rgb(20,20,20)"}/>), childs:[]},
                            {Title: "JS Interface", body:new Holder(<JSInt/>,<JSInt color={"white"} bg={"rgb(20,20,20)"}/>), childs:[]},
                        ]},
                    ]},
                    {Title: "Gallary", body:new Holder(<WebGal/>,<WebGal color={"white"} bg={"rgb(20,20,20)"}/>), childs:[]},
                    {Title: "Links", body:new Holder(<WebNav/>,<WebNav color={"white"} bg={"rgb(20,20,20)"}/>),childs:[]}
                ]
            }
        />
    }
} export default StatsPage
import React from "react";
import Navibar from "../../Shared/Util/NavBar";
import Sections from "../../Shared/Util/Sections";
import TextB from "../../Shared/Text/textB";
import HeadA from "../../Shared/Text/HeadA";
import HeadB from "../../Shared/Text/HeadB";
import HeadC from "../../Shared/Text/HeadC";
import { Container, Row, Card} from "react-bootstrap";

class ConAndNet extends React.Component{

    constructor(props){
        super(props)
        this.state = {
            body: "", 
            color: props.color ? props.color: "black",
            bg: props.bg ? props.bg : "white",
        }
    }

    render(){
        let x = {color:this.state.color, background: this.state.bg}
        return <div className="mx-3" style={x}>
                <HeadC color={this.state.color} bg={this.state.bg}> Description</HeadC>
                <TextB color={this.state.color} bg={this.state.bg}>  
                This was a class during my third year of university where the first half was focused on networking and the second half on concurrency.
                In the networking section, we researched the different types of connections in networking and learned how to use sockets to send packets of information across them.
                In the concurrency section, we learned how to write concurrent code using threads and synchronised methods. We also learned about common issues in concurrency such as deadlocking and race conditions.
                </TextB>
        </div>
    }

} export default ConAndNet

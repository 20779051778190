class Holder {

    constructor(item1, item2){
        this.item1 = item1;
        this.item2 = item2;
    }

    get(bool){
        if(bool){
            return this.item1;
        } else {
            return this.item2;
        }
    }
}export default Holder
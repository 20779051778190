import React from "react";
import { CodeBlock, CopyBlock, a11yLight} from "react-code-blocks";
import out from "../../../Shared/Photos/Hons/out.png"
import HeadC from "../../../Shared/Text/HeadC";
import TextB from "../../../Shared/Text/textB";

class Compiler extends React.Component{

    constructor(props){
        super(props);
        this.state ={
            color: props.color ? props.color: "black",
            bg: props.bg ? props.bg : "white"
        }
    }
    render(){
        let x = {color:this.state.color, background: this.state.bg };
        return <div className="mx-3">
        <HeadC color={this.state.color} bg={this.state.bg}> Compiler</HeadC>
        <TextB color={this.state.color} bg={this.state.bg}>
            The compiler is the part that takes the parsed class and converts it into the Java Byte Code .class file to do this. 
            I use a library called ASM that has special classes to make this easier, such as method builders that allow me to just add bytecode 
            instructions to it and it will create the file at the end. For example, if we took the early example of<br/><br/>
            <CodeBlock
                text={
                    "Line{\n" 
                    + "  type: IntAssign\n"
                    + "  exprs: [\n"
                    + "   Expr(type:Var, Left: \"i\", right:null)\n"
                    + "   Expr(type:Val, Left: 0, right:null)\n"
                    + "]}"     
                    }
                language={"Java"}
                showLineNumbers={false}
                theme={a11yLight}
                wrapLines
            /><br/>
            Then we look through this object and add the correct instructions to the method builder, 
            so we add the values, then we store them, so we would place 0 on the stack, so it would look like<br/>
            | ICONST_0 | <br/>
            Next, we would add the instruction for storing an int variable that would be 
            | ISTORE 0 | <br/>
            Add this to the instructions for creating a variable at value place 0. In the compiler, 
            there is a list of what variables are stored in what position, 
            so later, if we use "i," it will look at its position and then add the instruction to the method <br/>
            | ILoad 0 | <br/>
            Here is an example function and what its instruction set would look like. <br/><br/>
            <CodeBlock
                text={
                        "public class vars {\n" + 
                        "   main(){\n" + 
                        "       int i = 10 ;\n" + 
                        "       char c = 'd';\n" +
                        "       boolean b = false ;\n" + 
                        "       double d = 100 ;\n" + 
                        "       float f = 10.2;\n" + 
                        "       long l  = 1000 ;\n" + 
                        "       int[][] a = [ [ 2 , 2 ] , [2, 2] , [2 , 2] ] ;" + 
                        "       float[] fs = [ 2.4 , 3 , 5 ] ;" + 
                        "       String s = \"sds\";\n" + 
                        "       BigInteger BI = BigInteger.valueOf(5);" +
                        "       Print i ;\n" + 
                        "       Print c ;\n" + 
                        "       Print d ;\n" + 
                        "       Print f ;\n" + 
                        "       Print l ;\n" + 
                        "       Print s ;\n" + 
                        "   }" + 
                        "}"
                    }
                language={"Java"}
                showLineNumbers={false}
                theme={a11yLight}
                wrapLines
            /><br/>
            <a style={x} href="https://en.wikipedia.org/wiki/List_of_Java_bytecode_instructions"> More info on the different instructions</a> <br/>
            An example instruction set<br/>
            <img
            height={"60%"}
            src={out}
            alt="Instruction set"
            ></img><br/><br/><br/><br/><br/>
        </TextB>
    </div>
    }

}export default Compiler
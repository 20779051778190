import React from "react";
import { Row } from "react-bootstrap";
import HeadC from "../../../../Shared/Text/HeadC";
import HeadB from "../../../../Shared/Text/HeadB";
import TextB from "../../../../Shared/Text/textB";
class ChampD extends React.Component{

    constructor(props){
        super(props);
        this.state = {
            body: "",
            color: props.color ? props.color: "black",
            bg: props.bg ? props.bg : "white"
        } 
    }

    render(){
        let x = {color:this.state.color, background:this.state.bg}
        return <div style={x} className="mx-3">
            <HeadC color={this.state.color} bg={this.state.bg}> Champion Data</HeadC>
            <TextB color={this.state.color} bg={this.state.bg}>
            The champion data is all taken from a page such as <a style={x} href="https://lol.fandom.com/wiki/2022_Season_World_Championship/Main_Event/Champion_Statistics">this</a> where there is a table with fields and rows for each entry, so extracting the data is rather simple.
            <ol style={x} type="1">
                <li style={x}> Find the node for the table</li> 
                <li style={x}> Navigate until I find the first and last row of champions</li>
                <li style={x}> Navigate through each row until I find the field I want</li>
                <li style={x}> Go through all of the rows and create an object from the extracted information for each champion </li>
            </ol>
        </TextB>
        </div>
    }
} export default ChampD

import React from "react";
import {Col, Row } from "react-bootstrap";
import { Player } from "video-react";
import ImageViewer from 'react-simple-image-viewer';
import "../../../node_modules/video-react/dist/video-react.css";
import GalleryFolder from "../../Shared/Util/GalleryFolder"; 

// Desktop Game Pictures
import LoginPage from "../../Shared/Photos/GameServer/LoginPage.png"
import LoginPage2 from "../../Shared/Photos/GameServer/LoginPage2.png"
import LoginSucces from "../../Shared/Photos/GameServer/LoginSucces.png"
import LoginFail from "../../Shared/Photos/GameServer/LoginFail.png"
import LoginSuccesZoom from "../../Shared/Photos/GameServer/LoginSuccesZoom.png"

import Display1 from "../../Shared/Photos/GameServer/Display1.png"
import NewGame from "../../Shared/Photos/GameServer/NewGame.png"
import NewGameNew from "../../Shared/Photos/GameServer/NewGameNew.png"
import Display2 from "../../Shared/Photos/GameServer/display2.png"
import Display3 from "../../Shared/Photos/GameServer/Display3.png"
import Display4 from "../../Shared/Photos/GameServer/Display4.png"

import TicTacToe from "../../Shared/Photos/GameServer/TicTacToe.png"
import Connect4 from "../../Shared/Photos/GameServer/Connect4.png"

// Desktop Videos
import PlayTic from "../../Shared/Videos/GameServer/1.mp4"
import PlayConnect from "../../Shared/Videos/GameServer/2.mp4"
import Login from "../../Shared/Videos/GameServer/3.mp4"
import New from "../../Shared/Videos/GameServer/4.mp4"

// Mobile Game Pictures
import m_Display1 from "../../Shared/Photos/GameServer/ph_Display1.png"
import m_Display2 from "../../Shared/Photos/GameServer/ph_Display2.png"
import m_Display3 from "../../Shared/Photos/GameServer/ph_Display3.png"
import m_Display4 from "../../Shared/Photos/GameServer/ph_Display4.png"
import m_Display5 from "../../Shared/Photos/GameServer/ph_Display5.png"

import m_login from "../../Shared/Photos/GameServer/ph_login.png"
import m_newGame from "../../Shared/Photos/GameServer/ph_newGame.png"

import m_TicTac from "../../Shared/Photos/GameServer/ph_TicTac.png"
import m_Con4 from "../../Shared/Photos/GameServer/ph_Con4.png"
import m_Con5 from "../../Shared/Photos/GameServer/ph_Con5.png"

//Shared Videos
import sharedTic from "../../Shared/Videos/GameServer/s1.mp4"
import sharedCon from "../../Shared/Videos/GameServer/s2.mp4"


class SerGal extends React.Component{

    constructor(props){
        super(props)
        this.state = {
            body: "",    
            Sizes: [
                ["40vh","40vh","40vh","15vh","15vh","40vh","40vh","40vh","50vh","50vh","60vh","40vh","50vh"],
                ["83vh","83vh","83vh","83vh","83vh","83vh","83vh","83vh","83vh","83vh","83vh","83vh","83vh"]
            ],
            color:props.color,
            bg: props.bg
        }
    }

    onClose(){
        this.setState({body:""})
    }

    show(num){
        console.log(this.state.ind)
        let x =             
            <ImageViewer
                src={[
                    LoginPage, LoginPage2, LoginSucces, LoginFail,LoginSuccesZoom,
                    Display1,Display2,Display3,Display4,
                    NewGame,NewGameNew,TicTacToe,Connect4,
                    m_Display1,m_Display2,m_Display3,m_Display4,m_Display5,
                    m_login,m_newGame,
                    m_TicTac,m_Con4,m_Con5
                ] }
                currentIndex={ num}
                disableScroll={ false }
                closeOnClickOutside={ true }
                onClose={ () => this.onClose()}
            />
        this.setState({body:x})
    }

    render(){
        return <>
            <GalleryFolder Title= {"Desktop"} child={
                <Col style={{color: this.state.color, background:this.state.bg}}>
                    <GalleryFolder Title={"Photos"} child={this.ImageRows([
                        LoginPage, LoginPage2, LoginSucces, LoginFail,LoginSuccesZoom,
                        Display1,Display2,Display3,Display4,
                        NewGame,NewGameNew,TicTacToe,Connect4
                    ] ,0,0, "28vw", 2)} />
                    <GalleryFolder Title={"Videos"} child={this.VideoRows([
                        PlayTic,PlayConnect,Login,New
                    ])} />
                </Col>
            } />
            <GalleryFolder Title= {"Mobile"} child={
                <Col style={{color: this.state.color, background:this.state.bg}}>
                    <GalleryFolder Title={"Photos"} child={this.ImageRows([
                        m_Display1,m_Display2,m_Display3,m_Display4,m_Display5,
                        m_login,m_newGame,
                        m_TicTac,m_Con4,m_Con5
                    ] ,1,13, "20vw", 3)} />
                </Col>
            } />
            <GalleryFolder Title= {"Mixture"} child={
                <Col style={{color: this.state.color, background:this.state.bg}}>
                    <GalleryFolder Title={"Videos"} child={this.VideoRows([
                        sharedTic,sharedCon
                    ])} />
                </Col>
            } />
            <br/><br/><br/>
            {this.state.body}
        </>
    }

    MakeImage(Photo, ind,startPoint, row, width ){
        return <div className="mx-2" style={{height:this.state.Sizes[row][ind], width:width,
        backgroundImage:  "url(" + Photo + ")", 
        backgroundRepeat: 'no-repeat',backgroundSize:"cover"}}
        onClick={() => this.show(ind + startPoint)}
        />
    }

    MakeVideo(Video){
        return <Player
            playsInline
            src={Video}
            />
    }

    ImageRows(Photos, row, startPoint, width, amountPerRow){
        let ret = [];
        let i = 0;
        while(i < Photos.length){
            if(i + amountPerRow <= Photos.length){
                let temp = [];
                Array.from(Array(amountPerRow ).keys()).map(
                   (y) => temp.push(this.MakeImage(Photos[i + y], i + y, startPoint,row,width))
                )
                ret.push(
                    <Row className="py-3 mx-2 " style={{color: this.state.color, background:this.state.bg}}>
                        {temp}
                    </Row>
                )
                i+= amountPerRow;
            } else {
                let temp = [];
                while(i < Photos.length){
                    temp.push(this.MakeImage(Photos[i], i++, startPoint,row,width));
                }
                ret.push(<Row className="py-3 mx-3 " style={{color: this.state.color, background:this.state.bg}}> 
                    {temp}
                </Row>);
            }
        }
        return <Col style={{color: this.state.color, background:this.state.bg}}>{ret}</Col>;
    }

    VideoRows(Videos){
        let ret = [];
        let i = 0;
        while(i < Videos.length){
            if(i + 2 <= Videos.length){
                ret.push(
                <Row className="py-3 mx-2" style={{color: this.state.color, background:this.state.bg}}>
                    <Col style={{color: this.state.color, background:this.state.bg}}>{this.MakeVideo(Videos[i])}</Col>
                    <Col style={{color: this.state.color, background:this.state.bg}}>{this.MakeVideo(Videos[i + 1])}</Col>
                </Row>);
                i+= 2;
            } else {
                ret.push(<Row className="py-3 mx-2" style={{color: this.state.color, background:this.state.bg}}> 
                    {this.MakeVideo(Videos[i])}
                </Row>);
                i+= 1;
            }
        }
        return <Col style={{color: this.state.color, background:this.state.bg}}>{ret}</Col>;
    }
    


} export default SerGal 
import React from "react";
import { Card, Col, Row, Image } from "react-bootstrap";
import Img from "../../Shared/Photos/ConANet/Img.png";
import ImageViewer from 'react-simple-image-viewer';

class ConGal extends React.Component{

    constructor(props){
        super(props)
        this.state = {
            body: "",
            color: props.color ? props.color: "black",
            bg: props.bg ? props.bg : "white",
        }
    }

    onClose(){
        this.setState({body:""})
    }

    show(num){
        console.log(this.state.ind)
        let x =             
            <ImageViewer
                src={[Img ] }
                currentIndex={ num}
                disableScroll={ false }
                closeOnClickOutside={ true }
                onClose={ () => this.onClose()}
            />
        this.setState({body:x})
    }

    render(){
        let b = this.state.ind;
        let x = {color:this.state.color, background: this.state.bg}
        return <>
            <Row className="py-3 px-3" style={x}>
                <Col style={x}>
                    <div 
                    style={{height:"40vh", width:"50vw",
                        backgroundImage:"url(" + Img + ")", 
                        backgroundRepeat: 'no-repeat',backgroundSize:"cover"}}
                        onClick={() => this.show(0)}
                        />
                </Col>
            </Row>
            <br/><br/><br/>
            {this.state.body}
        </>
    }

} export default ConGal 
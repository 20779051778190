import React from "react";
import HeadB from "../../../../Shared/Text/HeadB";
import HeadC from "../../../../Shared/Text/HeadC";
import TextB from "../../../../Shared/Text/textB";


class JSInt extends React.Component{

    constructor(props){
        super(props);
        this.state = {
            body: "",
            color: props.color ? props.color: "black",
            bg: props.bg ? props.bg : "white"
        } 
    }

    render(){
        let x = {color:this.state.color, background:this.state.bg}
        return <div className="mx-3" style={x}><HeadC color={this.state.color} bg={this.state.bg}> JavaScript Interface</HeadC><TextB color={this.state.color} bg={this.state.bg} >
        The interfaces used for creating and editing JS functions
        <ul style={x}>
            <li style={x}>JS Interface: The main interface and starting point when in the JS Section</li>
            <li style={x}>CF Interface: The interface responsible for creating any functions you want to use</li>
            <li style={x}>PF Interface: The interface responsible for adding lines and expressions to your function</li>
        </ul>
        </TextB>
        </div>
    }
} export default JSInt
import React from "react";
import { Col, Row } from "react-bootstrap";
import HeadC from "../../Shared/Text/HeadC";
import HeadS from "../../Shared/Text/HeadS";
import TextB from "../../Shared/Text/textB";

class StatsNav extends React.Component{


    constructor(props){
        super(props);
        this.state = {
            body: "",
            color: props.color ? props.color: "black",
            bg: props.bg ? props.bg : "white"
        } 
    }

    render(){
        let x = {color:this.state.color, background: this.state.bg}
        return <div style={{textAlign:"center"}}>
        <TextB color={this.state.color} bg={this.state.bg} >
            <HeadS color={this.state.color} bg={this.state.bg}> Project on <a style={x} href="https://github.com/NineSixFourteen/Lol-Personal-Stat-Checker">Github</a></HeadS>
            <Row style={x}>
                <Col style={x}>
                    <HeadS color={this.state.color} bg={this.state.bg}> Fetcher</HeadS>
                        <a style={x} href="https://github.com/NineSixFourteen/Lol-Personal-Stat-Checker/tree/main/Lol-Personal-Stats/src/main/java/Stats/InfoFetcher"> Fetchers </a> <br/>
                        <a style={x} href="https://github.com/NineSixFourteen/Lol-Personal-Stat-Checker/blob/main/Lol-Personal-Stats/src/main/java/Stats/InfoFetcher/Fetcher.java"> Fetcher  </a> <br/>
                        <a style={x} href="https://github.com/NineSixFourteen/Lol-Personal-Stat-Checker/blob/main/Lol-Personal-Stats/src/main/java/Stats/InfoFetcher/FetchOverall.java"> Fetch Match Histary </a> <br/>
                        <a style={x} href="https://github.com/NineSixFourteen/Lol-Personal-Stat-Checker/blob/main/Lol-Personal-Stats/src/main/java/Stats/InfoFetcher/FetchOverall.java"> Fetch Match Overall </a> <br/>
                        <a style={x} href="https://github.com/NineSixFourteen/Lol-Personal-Stat-Checker/blob/main/Lol-Personal-Stats/src/main/java/Stats/InfoFetcher/FetchOverall.java"> Fetch Match Intervals </a> <br/>
                    </Col>
                    <Col style={x}>
                        <HeadS color={this.state.color} bg={this.state.bg}> API</HeadS>
                            <a style={x} href="https://github.com/NineSixFourteen/Lol-Personal-Stat-Checker/tree/main/Lol-Personal-Stats/src/main/java/Stats/BackEnd"> Back End of API </a> <br/>
                            <a style={x} href="https://github.com/NineSixFourteen/Lol-Personal-Stat-Checker/tree/main/Lol-Personal-Stats/src/main/java/Stats/FrontEnd"> Front End of API </a> <br/>
                    </Col>
                    <Col style={x}>
                    <HeadS color={this.state.color} bg={this.state.bg}> Website</HeadS>
                        <a style={x} href="https://github.com/NineSixFourteen/Lol-Personal-Stat-Checker/tree/main/Lol-Personal-Stats/frontend/src/Admin"> Admin</a> <br/>
                        <a style={x} href="https://github.com/NineSixFourteen/Lol-Personal-Stat-Checker/tree/main/Lol-Personal-Stats/frontend/src/Home"> Home</a> <br/>
                        <a style={x} href="https://github.com/NineSixFourteen/Lol-Personal-Stat-Checker/tree/main/Lol-Personal-Stats/frontend/src/Match"> Match</a> <br/>
                        <a style={x} href="https://github.com/NineSixFourteen/Lol-Personal-Stat-Checker/tree/main/Lol-Personal-Stats/frontend/src/Overall"> Overall</a> <br/>
                        <a style={x} href="https://github.com/NineSixFourteen/Lol-Personal-Stat-Checker/tree/main/Lol-Personal-Stats/frontend/src/Player"> Player</a> <br/>
                    </Col>
                    <Col style={x}>
                    <HeadS color={this.state.color} bg={this.state.bg}> Documentation (Incomplete) </HeadS>
                        <a style={x} href="https://github.com/NineSixFourteen/Lol-Personal-Stat-Checker/blob/main/Lol-Personal-Stats/Docs/Setup.md"> Setup Guide </a> <br/>
                        <a style={x} href="https://github.com/NineSixFourteen/Lol-Personal-Stat-Checker/tree/main/Lol-Personal-Stats/Docs/API"> API </a> <br/>
                        <a style={x} href="https://github.com/NineSixFourteen/Lol-Personal-Stat-Checker/tree/main/Lol-Personal-Stats/Docs/Frontend"> Website </a> <br/>
                    </Col>
            </Row>
            </TextB>
        
        </div>
    }
} export default StatsNav
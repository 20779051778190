import React from "react";
import { CodeBlock, CopyBlock, dracula, a11yLight} from "react-code-blocks";
import TextB from "../../../Shared/Text/textB";
import HeadB from "../../../Shared/Text/HeadB";
import HeadC from "../../../Shared/Text/HeadC";
class Precompiler extends React.Component{

    constructor(props){
        super(props);
        this.state ={
            color: props.color ? props.color: "black",
            bg: props.bg ? props.bg : "white"
        }
    }

    render(){
        let x = {color:this.state.color, background: this.state.bg };
        return <div className="mx-3" >
            <HeadC color={this.state.color} bg={this.state.bg}> Pre Compiler Stage</HeadC>
            <TextB color={this.state.color} bg={this.state.bg} >
            The precompiler is the stage where we check for things such as errors, and then we might optimise the code or we might perform "code switching". <br/>
            <HeadB color={this.state.color} bg={this.state.bg} > Error Checking  </HeadB>
            We do a number of things for error checking, such as ensuring that all functions have a unique pattern, i.e., 
            there are no two functions called Add(int a, int b).Functions with the same name are allowed,
            but they must have different parameters so the compiler knows which function you want to use. 
            We also look through all expressions and check things such as whether all variables used are defined and that all
            function calls have a function that is defined. There are many other checks, such as checking that all braces {"({})"} are 
            closed as well as whether quotes (") have an ending quote.
            <HeadB color={this.state.color} bg={this.state.bg} > Optimisation </HeadB>
            During this stage, we might find an expression such as "9 * 9," in which case we will replace this with just 81 as we already know the answer,
            and 9 * 9 takes many more instructions than just 81.
            We never do this with things such as variables, as we cannot always know their value at the time of running the program.
            <HeadB color={this.state.color} bg={this.state.bg} >  Code Switching  </HeadB>
            Code Switching occurs as a result of a feature in the language I call ConstExpression, which allows you to define syntax patterns that
            you want to use, for example. <br/><br/>
            <CodeBlock
                text={
                    "ConstExp a `+ b : BigInterger.valueOf(a).plus(BigInteger.valueOf(b); \n" + 
                    "ConstExp a +` b : a.plus(BigInteger.valueOf(b); \n" 
                    }
                language={"Java"}
                showLineNumbers={false}
                theme={a11yLight}
                wrapLines
                /><br/>
            It will then look for this pattern in the rest of the code and replace it with the pattern after the : <br/>
            This is useful for writing much more readable code, as you can later write<br/><br/>
            <CodeBlock
                text={
                    "return 100 '+ 200 +' 300 +' 900;"
                    }
                language={"Java"}
                showLineNumbers={false}
                theme={a11yLight}
                wrapLines
                /><br/>
            instead of <br/><br/>
            <CodeBlock
                text={
                    "return BigInteger.valueOf(100).plus(BigInteger.valueOf(200)).plus(BigInteger.valueOf(300)).plus(BigInteger.valueOf(900));"
                    }
                language={"Java"}
                showLineNumbers={false}
                theme={a11yLight}
                wrapLines
                /><br/>
                This can also massively speed up development time, but if overused, it can lead to very messy code that no one could read, so it should be used carefully. <br/>
                Below is a full program using this feature.<br/><br/>
            <CodeBlock
                text={
                    "constFunc +` : +` b : .add(BigInteger.valueOf(b));\n " + 
                    "constFunc ` : ` b : BigInteger.valueOf(b) ;\n " + 
                    "constFunc +B : a +B b : a.add(b);\n " + 
                    "constFunc ^ : a ^ b : Math.pow(a , b) ;\n " + 
                    "constFunc ** : a ** b : Math.pow(a, b) ;\n " + 
                    "constFunc cout : cout << a : System.out.println(a)  ;\n " + 
                    "constFunc P : P a : Print a;\n " + 
                    "constFunc √ : √ a : Math.sqrt(a) ;\n\n " + 
                    "public class syntax {\n\n" + 
                    "    main(){\n " + 
                    "       BigInteger bi =  8 `+` 2 +` 10 +` 2 +` 3 ;\n " + 
                    "       Print bi ;\n " + 
                    "       Print fib(1000);\n " + 
                    "       Print CirOfCir 3 ;\n " + 
                    "       Print 2 ^ 3 ;\n " + 
                    "       cout << \"Hello\" ;\n " + 
                    "       System.out.println(10);\n " + 
                    "       Print √ ( 3 ^ 2 + 4 ^ 2) ;\n " + 
                    "   }\n\n " + 
                    "   public static BigInteger fib(int n){\n " + 
                    "       BigInteger a = ` 0;\n " + 
                    "       BigInteger b = ` 1;\n " + 
                    "       BigInteger c = ` 1;\n " + 
                    "       for (int j = 2 , j <= n , 1){\n " + 
                    "           c =  a +B b;\n " + 
                    "           a = b;\n " + 
                    "            b = c;\n " + 
                    "       }\n " + 
                    "       return (b);\n " + 
                    "    }\n " +
                    "}\n "
                }
                language={"C++"}
                showLineNumbers={false}
                theme={a11yLight}
                wrapLines
                /><br/>
        </TextB>
    </div>
    }

}export default Precompiler
import React from "react";
import BotInter from "./Description/Bot/BotInteraction";
import Commands from "./Description/Bot/Commands";
import Interfaces from "./Description/Bot/Interfaces";
import QueryS from "./Description/Bot/Query";
import ChampD from "./Description/Scraper/ChampD";
import InfoScrape from "./Description/Scraper/InfoSrape";
import EventD from "./Description/Scraper/OtherD";
import PlayerD from "./Description/Scraper/PlayerD";
import ChampT from "./Description/Storage/ChampTable";
import EventT from "./Description/Storage/EventTable";
import IStorage from "./Description/Storage/InfoStorage";
import PlayerT from "./Description/Storage/PlayerTable";
import DiscordBot from "./DiscordBot";
import DicordGal from "./DiscordGal";
import DiscordSpec from "./DiscordSpec";
import Holder from "../../Shared/Util/Holder";
import NewProjPage from "../../Shared/Util/newProjPage";
import DisPlan from "./DiscordPlan";
import DisOver from "./DisordOver";
import DisNav from "./DiscordNav";



class DisPage extends React.Component{

    constructor(props){
        super(props);
        this.state ={
            color: "white",
            bg: "rgb(20,20,20)"
        }
    }

    flip(){
        if(this.state.color == "white"){
            this.setState({
                color: "rgb(20,20,20)", bg:"white"
            })
        } else {
            this.setState({
                color: "white", bg:"rgb(20,20,20)"
            })
        }

    }

    render(){
        let x = {color:this.state.color, background: this.state.bg}
        return <NewProjPage 
            func={this.flip.bind(this)}
            childs={
                [
                    {Title: "Overview", body:new Holder(<DisOver/>,<DisOver color={"white"} bg={"rgb(20,20,20)"}/>), childs:[
                        {Title: "Future Goals",body:new Holder(<DisPlan/>,<DisPlan color={"white"} bg={"rgb(20,20,20)"}/>), childs:[]},
                    ]},
                    {Title: "Specification", body:new Holder(<DiscordSpec/>,<DiscordSpec color={"white"} bg={"rgb(20,20,20)"}/>), childs:[]},
                    {Title: "Description", body:new Holder(<DiscordBot/>,<DiscordBot color={"white"} bg={"rgb(20,20,20)"}/>), childs:[
                        {Title: "Interface/Bot", body:new Holder(<BotInter/>,<BotInter color={"white"} bg={"rgb(20,20,20)"}/>), childs:[
                            {Title: "Commands", body:new Holder(<Commands/>,<Commands color={"white"} bg={"rgb(20,20,20)"}/>), childs:[]},
                            {Title: "Query System", body:new Holder(<QueryS/>,<QueryS color={"white"} bg={"rgb(20,20,20)"}/>), childs:[]},
                            {Title: "Interaction", body:new Holder(<Interfaces/>,<Interfaces color={"white"} bg={"rgb(20,20,20)"}/>), childs:[]},
                        ]},
                        {Title: "Web Scraper", body:new Holder(<InfoScrape/>,<InfoScrape color={"white"} bg={"rgb(20,20,20)"}/>), childs:[
                            {Title: "Champion Data", body:new Holder(<ChampD/>,<ChampD color={"white"} bg={"rgb(20,20,20)"}/>), childs:[]},
                            {Title: "Player Data", body:new Holder(<PlayerD/>,<PlayerD color={"white"} bg={"rgb(20,20,20)"}/>), childs:[]},
                            {Title: "Event Data", body:new Holder(<EventD/>,<EventD color={"white"} bg={"rgb(20,20,20)"}/>), childs:[]},
                        ]},
                        {Title: "Storage", body:new Holder(<IStorage/>,<IStorage color={"white"} bg={"rgb(20,20,20)"}/>), childs:[
                            {Title: "Champion Table", body:new Holder(<ChampT/>,<ChampT color={"white"} bg={"rgb(20,20,20)"}/>), childs:[]},
                            {Title: "Player Table", body:new Holder(<PlayerT/>,<PlayerT color={"white"} bg={"rgb(20,20,20)"}/>), childs:[]},
                            {Title: "Event Table", body:new Holder(<EventT/>,<EventT color={"white"} bg={"rgb(20,20,20)"}/>), childs:[]},
                        ]},
                    ]},
                    {Title: "Gallary", body:new Holder(<DicordGal/>,<DicordGal color={"white"} bg={"rgb(20,20,20)"}/>), childs:[]},
                    {Title: "Links", body:new Holder(<DisNav/>,<DisNav color={"white"} bg={"rgb(20,20,20)"}/>),childs:[]}
                ]
            }
        />
    }
} export default DisPage
import React from "react";
import { Card, Container, Row } from "react-bootstrap";
import TextB from "../../Shared/Text/textB";
import HeadC from "../../Shared/Text/HeadC";

class WAM extends React.Component{

    constructor(props){
        super(props)
        this.state = {
            body: "",
            color: props.color ? props.color: "black",
            bg: props.bg ? props.bg : "white"
        }
    }

    render(){
        let x = {color:this.state.color, background: this.state.bg}
        return <div className="mx-3">
            <Row style={x}>
                <HeadC color={this.state.color} bg={this.state.bg}> Description</HeadC>
                <TextB color={this.state.color} bg={this.state.bg}>
                    These classes were undertaken during my third year of university.
                    In these classes, I created a variety of websites and mobile apps, primarily using web development tools such as HTML, CSS, Bootstrap, PHP, JavaScript, and React.
                    We learned about a variety of methods for improving our websites in the web development class, from how to use AJAX to how to properly interact with MySql databases.
                    While in mobile development, we learned about creating mobile-friendly interfaces for both landscape and portrait mode, as well as exploring other tools to make this task easier, such as Android Studio, React Native, and Flutter.
                </TextB>
            </Row>
        </div>
    }

} export default WAM




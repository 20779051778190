import React from "react";

import NewProjPage from "../../Shared/Util/newProjPage";
import Holder from "../../Shared/Util/Holder";
import StackSpec from "./StatsSpec";
import StatsWebsite from "./StatsWebsite";
import API from "./Descriptions/API/API";
import Fetcher from "./Descriptions/Fetcher/Fetcher";
import DB from "./Descriptions/Database/Database";
import StatsGal from "./StatsGal";
import Website from "./Descriptions/Website/Website";
import MatchP from "./Descriptions/Website/MatchP";
import AddP from "./Descriptions/API/AddP";
import GetP from "./Descriptions/API/GetP";
import DeleteP from "./Descriptions/API/DeleteP";
import MatchOverall from "./Descriptions/Database/MatchOverall";
import MatchHistory from "./Descriptions/Database/MatchHis";
import MatchInterval from "./Descriptions/Database/MatchInterval";
import RiotAPI from "./Descriptions/Fetcher/RiotApi";
import FetchingInfo from "./Descriptions/Fetcher/FetchingInfo";
import PlayerP from "./Descriptions/Website/PlayerP";
import OverP from "./Descriptions/Website/OverP";
import StatsOver from "./StatsOver";
import StatsPlan from "./StasPlan";
import StatsNav from "./StatsNav";

class StatsPage extends React.Component{

    constructor(props){
        super(props);
        this.state ={
            color: "white",
            bg: "rgb(20,20,20)"
        }
    }

    flip(){
        if(this.state.color == "white"){
            this.setState({
                color: "rgb(20,20,20)", bg:"white"
            })
        } else {
            this.setState({
                color: "white", bg:"rgb(20,20,20)"
            })
        }

    }

    render(){
        let x = {color:this.state.color, bg:this.state.bg}
        return <NewProjPage 
            func={this.flip.bind(this)}
            childs={
                [
                    {Title: "Overview", body:new Holder(<StatsOver/>,<StatsOver color={"white"} bg={"rgb(20,20,20)"}/>), childs:[
                        {Title: "Future Goals",body:new Holder(<StatsPlan/>,<StatsPlan color={"white"} bg={"rgb(20,20,20)"}/>), childs:[]},
                    ]},
                    {Title: "Specification", body:new Holder(<StackSpec/>,<StackSpec color={"white"} bg={"rgb(20,20,20)"}/>), childs:[]},
                    {Title: "Description", body:new Holder(<StatsWebsite/>,<StatsWebsite color={"white"} bg={"rgb(20,20,20)"}/>), childs:[
                        {Title: "Website", body:new Holder(<Website/>,<Website color={"white"} bg={"rgb(20,20,20)"}/>), childs:[
                            {Title: "Match Page", body:new Holder(<MatchP/>,<MatchP color={"white"} bg={"rgb(20,20,20)"}/>), childs:[]},
                            {Title: "Player Page", body:new Holder(<PlayerP/>,<PlayerP color={"white"} bg={"rgb(20,20,20)"}/>), childs:[]},
                            {Title: "Overall Page", body:new Holder(<OverP/>,<OverP color={"white"} bg={"rgb(20,20,20)"}/>), childs:[]},
                        ]},
                        {Title: "API", body:new Holder(<API/>,<API color={"white"} bg={"rgb(20,20,20)"}/>), childs:[
                            {Title: "Add Route", body:new Holder(<AddP/>,<AddP color={"white"} bg={"rgb(20,20,20)"}/>), childs:[]},
                            {Title: "Get Route", body:new Holder(<GetP/>,<GetP color={"white"} bg={"rgb(20,20,20)"}/>), childs:[]},
                            {Title: "Delete Route", body:new Holder(<DeleteP/>,<DeleteP color={"white"} bg={"rgb(20,20,20)"}/>), childs:[]},
                        ]},
                        {Title: "Database", body:new Holder(<DB/>,<DB color={"white"} bg={"rgb(20,20,20)"}/>), childs:[
                            {Title: "Match Histroy", body:new Holder(<MatchHistory/>,<MatchHistory color={"white"} bg={"rgb(20,20,20)"}/>), childs:[]},
                            {Title: "Match Interval", body:new Holder(<MatchInterval/>,<MatchInterval color={"white"} bg={"rgb(20,20,20)"}/>), childs:[]},
                            {Title: "Match Overall", body:new Holder(<MatchOverall/>,<API color={"white"} bg={"rgb(20,20,20)"}/>), childs:[]},
                        ]},
                        {Title: "Fetcher", body:new Holder(<Fetcher/>,<Fetcher color={"white"} bg={"rgb(20,20,20)"}/>), childs:[
                            {Title: "Riot API", body:new Holder(<RiotAPI/>,<RiotAPI color={"white"} bg={"rgb(20,20,20)"}/>), childs:[]},
                            {Title: "Fetching Info", body:new Holder(<FetchingInfo/>,<FetchingInfo color={"white"} bg={"rgb(20,20,20)"}/>), childs:[]},
                        ]},
                    ]},
                    {Title: "Gallary", body:new Holder(<StatsGal/>,<StatsGal color={"white"} bg={"rgb(20,20,20)"}/>), childs:[]},
                    {Title: "Links", body:new Holder(<StatsNav/>,<StatsNav color={"white"} bg={"rgb(20,20,20)"}/>),childs:[]}
                ]
            }
        />
    }
} export default StatsPage
import React from "react";
import HeadB from "../../../../Shared/Text/HeadB";
import HeadC from "../../../../Shared/Text/HeadC";
import TextB from "../../../../Shared/Text/textB";

class HTMLInt extends React.Component{

    constructor(props){
        super(props);
        this.state = {
            body: "",
            color: props.color ? props.color: "black",
            bg: props.bg ? props.bg : "white"
        } 
    }

    render(){
        let x = {color:this.state.color, background:this.state.bg}
        return <div className="mx-3" style={x}><HeadC color={this.state.color} bg={this.state.bg}> HTML Interface</HeadC><TextB color={this.state.color} bg={this.state.bg} >
            The interfaces used for creating and editing HTML where
            <ul style={x}>
                <li style={x}> HTML Interface: The main interface and starting point when in the HTML Section</li>
                <li style={x}> CE Interface: The interface responsible for creating new HTML elements</li>
                <li style={x}> EE Interface: The interface responsible for editing any HTML elements that have been made</li>
                <li style={x}> RE Interface: This interface is used when you want to move the location of an HTML element on the page</li>
                <li style={x}> VE Interface: This interface is used when you want to view information about an HTML element.</li>
            </ul>
        </TextB>
        </div>
    }
}export default HTMLInt

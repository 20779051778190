import React from "react";
import { Row } from "react-bootstrap";
import HeadC from "../../../../Shared/Text/HeadC";
import HeadB from "../../../../Shared/Text/HeadB";
import TextB from "../../../../Shared/Text/textB";

class EventD extends React.Component{

    constructor(props){
        super(props);
        this.state = {
            body: "",
            color: props.color ? props.color: "black",
            bg: props.bg ? props.bg : "white"
        } 
    }

    render(){
        let x = {color:this.state.color, background:this.state.bg}
        return <div style={x} className="mx-3"><HeadC color={this.state.color} bg={this.state.bg}> Event Data</HeadC><TextB color={this.state.color} bg={this.state.bg}>
            The "other" data is data that usally refers to the tournament itself things such as fastest and slowest games or the number barons stolen.
            The data for this come for a variety or differn't pages including some that is hard code as I couldn't find the info online and the data doesn't change often.
            The data I got from Game of Legends is 
            <ul style={x}>
                <li style={x}> Longest and Shortest Game</li>
                <li style={x}> Most Kills in a single Game</li>
                <li style={x}> Players who get Penta Kills</li>
                <li style={x}> The number of each drake taken</li>
            </ul>
            This is done similar to the other data where I just traverse the nodes on the page till I find the node I want where I then extract the data.
            <br/> The rest of there "other" data is either sourced from the other data sets or is hard codded.
        </TextB>
        </div>
    }
} export default EventD


import React from "react";
import {Col, Row } from "react-bootstrap";
import { Player } from "video-react";
import ImageViewer from 'react-simple-image-viewer';

import Home from "../../Shared/Photos/2048/Game.png"
import GU from "../../Shared/Photos/2048/GUI.png"

import Board from "../../Shared/Photos/2048/GameC.png";
import Compl from "../../Shared/Photos/2048/GameCover.png";

import "../../../node_modules/video-react/dist/video-react.css";


import CL  from "../../Shared/Videos/2048/CL.mp4" 
import Vis from "../../Shared/Videos/2048/Vis.mp4" 


class GamGal extends React.Component{


    constructor(props){
        super(props)
        this.state = {
            body: "",
            color: props.color ? props.color: "black",
            bg: props.bg ? props.bg : "white"
        }
    }

    onClose(){
        this.setState({body:""})
    }

    show(num){
        console.log(this.state.ind)
        let x =             
            <ImageViewer
                src={[
                     GU, Home
                ] }
                currentIndex={ num}
                disableScroll={ false }
                closeOnClickOutside={ true }
                onClose={ () => this.onClose()}
            />
        this.setState({body:x})
    }

    render(){
        let x = {color:this.state.color, background:this.state.bg}
        return <>
            <Row style={x} className="py-3 px-3">
                <Col style={x}>
                    <div 
                    style={{height:"40vh", width:"30vw",
                        backgroundImage:  "url(" + Home + ")", 
                        backgroundRepeat: 'no-repeat',backgroundSize:"cover"}}
                        onClick={() => this.show(1)}
                        />
                </Col>

                <Col style={x}>
                    <div 
                    style={{height:"40vh", width:"30vw",
                        backgroundImage:  "url(" + GU + ")", 
                        backgroundRepeat: 'no-repeat',backgroundSize:"cover"}}
                        onClick={() => this.show(0)}
                        />
                </Col>
            </Row>
            <Row style={x} className="py-3 px-3">
                <Col style={x}>
                    <div 
                    style={{height:"40vh", width:"30vw",
                        backgroundImage:  "url(" + Board + ")", 
                        backgroundRepeat: 'no-repeat',backgroundSize:"cover"}}
                        onClick={() => this.show(0)}
                        />
                </Col>
                <Col style={x}>
                    <div 
                    style={{height:"40vh", width:"30vw",
                        backgroundImage:  "url(" + Compl + ")", 
                        backgroundRepeat: 'no-repeat',backgroundSize:"cover"}}
                        onClick={() => this.show(0)}
                        />
                </Col>
            </Row>
            <Row style={x} className="py-3 px-3">
                <Col style={x}>
                    <Player
                        playsInline
                        src={CL}
                    />
                </Col>
                <Col style={x}>
                    <Player
                    playsInline
                    src={Vis}
                />
                </Col>
            </Row>
            <br/><br/><br/>
            {this.state.body}
        </>
    }

} export default GamGal 
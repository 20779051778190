import React from "react";
import HeadC from "../../../Shared/Text/HeadC";
import TextB from "../../../Shared/Text/textB";
class BackE extends React.Component{

    constructor(props){
        super(props);
        this.state = {
            body: "",
            color: props.color ? props.color: "black",
            bg: props.bg ? props.bg : "white"
        } 
    }

    render(){
        let x = {color:this.state.color, background: this.state.bg}
        return <div style={x} className="mx-3">
            <HeadC color={this.state.color} bg={this.state.bg}> Back End</HeadC>
            <TextB color={this.state.color} bg={this.state.bg}>
            The back end of the system was required.
            <ul style={x}>
                <li style={x}> Read in text file that contained
                    <ul style={x}> 
                        <li style={x}> A list of 116 stations and a number assosiated for each station</li>
                        <li style={x}> List of all "movements" </li>
                        <li style={x}> A movement would be like "Orange 0 101" meaning you could go from Station 0 to 101 via the orange line.</li>
                    </ul>
                </li>
                <li style={x}> Implement Dijkstra's algorithm to find the shortest path between two stations with the smallest number of line crossings.</li>
            </ul>
        </TextB>
        </div>
    }
} export default BackE



import React from "react";
import { Col, Row } from "react-bootstrap";
import HeadB from "../../../../Shared/Text/HeadB";
import HeadC from "../../../../Shared/Text/HeadC";
import TextB from "../../../../Shared/Text/textB";


class RiotAPI extends React.Component{

    constructor(props){
        super(props);
        this.state = {
            body: "",
            color: props.color ? props.color: "black",
            bg: props.bg ? props.bg : "white"
        } 
    }

    render(){
        let x = {color:this.state.color, background:this.state.bg}
        return <div className="mx-3" style={x}>
            <HeadC color={this.state.color} bg={this.state.bg}> Riot API</HeadC>
            <TextB color={this.state.color} bg={this.state.bg}>
            The <a href="https://developer.riotgames.com/"> Riot API</a> provides information about League of Legends matches.
            There are multiple APIs that are provided; the one I used is called <a href="https://developer.riotgames.com/apis#match-v5"> Match-v5</a> 
            , and the two access points that I used where
            <ul style={x}>
                <li style={x}>
                    /lol/match/v5/matches/{"{matchId}"}
                </li>
                <li style={x}>
                    /lol/match/v5/matches/{"{matchId}"}/timeline
                </li>
            </ul>
            I also used the European servers, so the final URL will look similar to  "https://europe.api.riotgames.com/lol/match/v5/matches/EUW1_{"{matchid}"}/timeline"
           </TextB>
           </div>
    }
} export default RiotAPI
import React from "react";
import HeadB from "../../../../Shared/Text/HeadB";
import TextB from "../../../../Shared/Text/textB";
import Player2 from "../../../../Shared/Photos/Stats/Player2.png";
import Player3 from "../../../../Shared/Photos/Stats/Player3.png";
import Player4 from "../../../../Shared/Photos/Stats/Player4.png";
import Player5 from "../../../../Shared/Photos/Stats/Player5.png";
import Player7 from "../../../../Shared/Photos/Stats/Player7.png";
import HeadC from "../../../../Shared/Text/HeadC";

class PlayerP extends React.Component{

  constructor(props){
    super(props);
    this.state = {
        body: "",
        color: props.color ? props.color: "black",
        bg: props.bg ? props.bg : "white"
    } 
}

    render(){
      let x = {color:this.state.color, background:this.state.bg}
      return<div style={x} className="mx-3"><HeadC color={this.state.color} bg={this.state.bg}> Player Page</HeadC> <TextB color={this.state.color} bg={this.state.bg}>
          The Player Page is for viewing all the stats about a player.
          When the page loads, there will be a box with a text box where you can type the player's name, then hit the search button,
          which will load the rest of the page. <br/>
          When loaded, the page looks like <br/><br/>
          <img
          src={Player7}
          width="60%"
          style={{marginLeft:"20%"}}
          alt="Player Load" 
          /><br/><br/>
          The match page has three main features
          <ul style={x}>
            <li style={x}> Match History</li>
            <li style={x}> Champion Statistics</li>
            <li style={x}> Statistics</li>
          </ul>
          <HeadB color={this.state.color} bg={this.state.bg}> Match History</HeadB>
          <div style={{textAlign:"center", color:this.state.color, background:this.state.bg}}>
          The Match History Section shows an overview of the players' recent games, five at a time. <br/> 
          It also has previous and next buttons to show traverse previous games. <br/>
          It can also filter the games based on Champion and Postion Played to help you find the games you want.<br/>
          </div><br/>
          <img
          src={Player2}
          width="60%"
          style={{marginLeft:"20%"}}
          alt="Player Load" 
          /><br/><br/>
          <HeadB color={this.state.color} bg={this.state.bg}> Champion Stats</HeadB>
          <div style={{textAlign:"center", color:this.state.color, background:this.state.bg}}>
          The Champion Stats section allows you to see the average stats of the player on different champions. <br/>
          It will display the player's top ten champions, and you can view that champion's stats by clicking on the icon. <br/>
          If you wish to see a different champion, there is a search section below the icons where you can type the champion's name and then hit search.
          </div>
          <br/>
          <img
          src={Player3}
          width="60%"
          style={{marginLeft:"20%"}}
          alt="Player Champ" 
          /><br/><br/>
           <div style={{textAlign:"center", color:this.state.color, background:this.state.bg}}>
          There is also a section to see what champions they have played and how many games they have played with that champion, 
          which you can see via the button that says "Show champion list." 
          </div>
          <br/>
          <img
          src={Player4}
          width="60%"
          style={{marginLeft:"20%"}}
          alt="Player Champ played" 
          /><br/><br/>
          <HeadB color={this.state.color} bg={this.state.bg}> Stats</HeadB>
          <div style={{textAlign:"center", color:this.state.color, background:this.state.bg}}>
          The stats section is where you can see the players' average stats as well as the average player and the players' average teammate.<br/>
          You can see the average statistics for Overall, SR, ARAM, Top, Jun, Mid, Adc, and Sup.
          </div>
          <br/><img
          src={Player5}
          width="60%"
          style={{marginLeft:"20%"}}
          alt="Player Champ played" 
          /><br/><br/><br/>
      </TextB>
      </div>
    }
  } export default PlayerP
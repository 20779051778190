import React from "react";

class HeadC extends React.Component{

    constructor(props){
        super(props);
        this.state ={
            color: props.color ? props.color: "white",
            bg: props.bg ? props.bg : "black"
        }
    }

    render(){
        return <div className="py-3" style={{textAlign:"center", color:this.state.color, background: this.state.bg}}>
            <span style={{fontSize:"250%", fontWeight:"700", color:this.state.color, background: this.state.bg}}>
                {this.props.children}
                <br/>
            </span>
        </div>
    }

}export default HeadC
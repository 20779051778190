import React from "react";
import HeadB from "../../../../Shared/Text/HeadB";
import HeadC from "../../../../Shared/Text/HeadC";
import TextB from "../../../../Shared/Text/textB";

class Fetcher extends React.Component{

    constructor(props){
        super(props);
        this.state = {
            body: "",
            color: props.color ? props.color: "black",
            bg: props.bg ? props.bg : "white"
        } 
    }

    render(){
        let x = {color:this.state.color, background:this.state.bg}
        return <div className="mx-3" style={x}>
            <HeadC color={this.state.color} bg={this.state.bg}>
            Fetcher  
            </HeadC>
            <HeadB color={this.state.color} bg={this.state.bg}>
            Overview
            </HeadB>
            <TextB color={this.state.color} bg={this.state.bg}>
            The Fetcher is the part of the program that is responsible for fetching data from the API developed by Riot Games, the creators of League of Legends.
            It also puts the information into different objects for storage purposes.
            </TextB>
            <HeadB color={this.state.color} bg={this.state.bg}>
            Additional Info
            </HeadB>
        </div>
    }
} export default Fetcher



import React from "react";
import { Card } from "react-bootstrap";

class Tag extends React.Component{

    constructor(props){
        super(props);
        this.state = {
            message: props.message,
            colour: props.colour,
            bgcolour: props.bgColour,
            key: props.key
        }
    }

    render(){
        return <Card className="px-0 my-2 mx-2 bx-0" 
            key={this.state.key} 
            style={{textAlign:"center", color:this.state.colour, 
                    width:"130px" , lineHeight:"0.5",
                    background:this.state.bgcolour}}>
            <Card.Header style={{textAlign:"center", color:this.state.colour, 
                    width:"130px" , lineHeight:"0.5",
                    background:this.state.bgcolour}}>
                {this.state.message}
            </Card.Header>
        </Card>
    }
} export default Tag 
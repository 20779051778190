import React from "react";
import Sections from "../../../../Shared/Util/Sections";
import HeadC from "../../../../Shared/Text/HeadC";
import HeadB from "../../../../Shared/Text/HeadB";
import TextB from "../../../../Shared/Text/textB";
import { Row, Col } from "react-bootstrap";

class IStorage extends React.Component{

    constructor(props){
        super(props);
        this.state = {
            color: props.color ? props.color: "black",
            bg: props.bg ? props.bg : "white"
        } 
    }

    render(){
        let x = {color:this.state.color, background:this.state.bg}
        return <div style={x} className="mx-3">
            <HeadC color={this.state.color} bg={this.state.bg}> Storage </HeadC>
            <HeadB color={this.state.color} bg={this.state.bg}> Overview</HeadB>
            <TextB color={this.state.color} bg={this.state.bg}> 
                The Storage Section is for the objects that will be storing the information and be given to the bot. 
                There is storage type for champion, player and other<br/> 
                There is also other classes for using these such as Filter, Sort and Lookup
            </TextB>
        </div>
    }
} export default IStorage


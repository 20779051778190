import React from "react";
import HeadB from "../../../../Shared/Text/HeadB";
import HeadC from "../../../../Shared/Text/HeadC";
import TextB from "../../../../Shared/Text/textB";
import { Container } from "react-bootstrap";

class API extends React.Component{

  constructor(props){
    super(props);
    this.state = {
        body: "",
        color: props.color ? props.color: "black",
        bg: props.bg ? props.bg : "white"
    } 
}
    render(){
      let x = {color:this.state.color, background:this.state.bg}
        return <div style={x} className="mx-3">
          <HeadC color={this.state.color} bg={this.state.bg}>
            API  
          </HeadC>
          <TextB color={this.state.color} bg={this.state.bg}>
          The API for this project exists for interacting with the MySQL database. 
          It is built using Java Spring Boot and I choose to use repositories that are CRUD, i.e., create, read, update and delete.
          Because we will not be updating or editing any records we will only be create/adding, read/retrieving, and deleting records.
          To interact with the API, you send requests to certain URLs that have been mapped to functions that will either return data if it is a get request or a message about if the function was succesful.
          </TextB>
          </div>
    }
} export default API


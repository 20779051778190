import React from "react";
import { Row, Col} from "react-bootstrap";
import HeadB from "../../../../Shared/Text/HeadB";
import HeadC from "../../../../Shared/Text/HeadC";
import TextB from "../../../../Shared/Text/textB";
class RInstr extends React.Component{

    constructor(props){
        super(props);
        this.state = {
            body: "",
            color: props.color ? props.color: "black",
            bg: props.bg ? props.bg : "white"
        } 
    }

    render(){
        let x = {color:this.state.color, background: this.state.bg}
        return <div style={x} className="mx-3">
            <HeadC color={this.state.color} bg={this.state.bg}> Instruction Set</HeadC>
            <TextB color={this.state.color} bg={this.state.bg}>
            The instruction set is a large list of commands that tell the stack machine what to do.
            They are broken up into different categories.           
             <Row style={x}>
                <Col style={x}>
                    <ul style={x}>
                        <li style={x}> Binary Operations</li>
                        <li style={x}> Jump Commands</li> 
                    </ul>
                </Col>
                <Col style={x}>
                    <ul style={x}>
                        <li style={x}> Other Commands</li>
                        <li style={x}> Variable Commands</li>
                    </ul>
                </Col>
            </Row>
            <Row style={x}>
                <Col style={x}>
                    The binary operations are operations that take two values.
                    <ul style={x}>
                        <li style={x}> Add</li>
                        <li style={x}> Minus</li>
                        <li style={x}> Multiple</li>
                        <li style={x}> Divide</li>
                        <li style={x}> Less Than</li>
                        <li style={x}> Greater Than</li>
                        <li style={x}> Less Than or Equal To</li>
                        <li style={x}> Greater Than or Equal To</li>
                        <li style={x}> Equals(Compare)</li>
                        <li style={x}> Not Equals</li>
                        <li style={x}> And </li>
                        <li style={x}> Or </li>
                        <li style={x}> Bitwise And</li>
                        <li style={x}> Bitwise Or</li> 
                    </ul>
                </Col>
                <Col style={x}>
                    The other commands are for everything else, such as working with the stack, throwing errors, calling functions, printing, 
                    and using arrays.                    
                    <ul style={x}>
                        <li style={x}> Pop</li>
                        <li style={x}> Push</li>
                        <li style={x}> Print</li>
                        <li style={x}> Return</li>
                        <li style={x}> Funcion</li>
                        <li style={x}> Throw Error</li>
                        <li style={x}> MakeArray</li>
                        <li style={x}> Get Element</li>
                        <li style={x}> Set Element</li> 
                    </ul>
                </Col>
            </Row>
            <Row style={x}>
                <Col style={x}>
                    The jump commands are for jumping to an instruction; they are used for things like ifs or for loops.      
                    <ul style={x}>
                        <li style={x}> GOTO</li>
                        <li style={x}> If True</li>
                        <li style={x}> If Fals</li>
                    </ul>
                </Col>
                <Col style={x}>
                    The variable commands are used for making and editing variables.        
                    <ul style={x}>
                        <li style={x}> Set variable</li>
                        <li style={x}> Get variable</li>
                        <li style={x}> Increment variable</li>
                        <li style={x}> Decrement variable</li>
                    </ul>
                </Col>
            </Row>
        </TextB>
        </div>
    }
} export default RInstr

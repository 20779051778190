import React from "react";
import HeadB from "../../../../Shared/Text/HeadB";
import HeadC from "../../../../Shared/Text/HeadC";
import TextB from "../../../../Shared/Text/textB";

class CBoard extends React.Component{

    constructor(props){
        super(props);
        this.state = {
            body: "",
            color: props.color ? props.color: "black",
            bg: props.bg ? props.bg : "white"
        } 
    }
    
    render(){
        let x = {color:this.state.color, background:this.state.bg}
        return <div style={x} className="mx-3">
            <HeadC color={this.state.color} bg={this.state.bg}> C# Board</HeadC>
            <TextB color={this.state.color} bg={this.state.bg}>
            The board is the same as the Python version, as in
            <ul style={x}>
                <li style={x}> Has same rules and logic as the officail version.</li>
                <li style={x}> Ability to move in any of the 4 directions.</li>
                <li style={x}> Merging of tiles on colision.</li> 
            </ul>
            <HeadB color={this.state.color} bg={this.state.bg}> AI</HeadB>
            The AI is also kept the same as the Python version to keep them comparable however, as this will be faster,
            I could easily add to the heuristic function without seeing performance issues. 
            Things such as
            <ul style={x}>
                <li style={x}> Smoothness</li>
                <li style={x}> Accounting for empty tiles</li>
                <li style={x}> Monotoncity </li> 
            </ul>
            These could increase the average score by a noticeable amount.
            <HeadB color={this.state.color} bg={this.state.bg}> Concurrency</HeadB>
            To add concurrency, i.e., parrarell programming, to this project, I used the C# parrarell library to 
            replace many of the for and foreach loops in the program with parrarell's version of each.
            This allows for the use of multiple threads to do multiple of the many calculations, 
            and with there being a maximum of (4 x 16 x 4 x 16 x 4) 16,384 calculations for every move made, 
            this should save a large amount of time.
            <br/><br/>
        </TextB>
        </div>
    }


} export default CBoard
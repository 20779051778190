import React from "react";

import NewProjPage from "../../Shared/Util/newProjPage";
import Holder from "../../Shared/Util/Holder";
import GameSpec from "./SerSpec";
import GameServer from "./GameServer";
import SerBack from "./Descriptions/Back/SerBack";
import Classs from "./Descriptions/Back/SerClass";
import DBss from "./Descriptions/Back/SerDB";
import Routess from "./Descriptions/Back/SerRoute";
import SerFront from "./Descriptions/Front/SerFront";
import Lpage from "./Descriptions/Front/SerLogin";
import GDis from "./Descriptions/Front/SerDisplay";
import Gpage from "./Descriptions/Front/SerPages";
import SerGList from "./Descriptions/Games/SerGame";
import SerGal from "./SerGal";
import SerOver from "./SerOver";
import SerPlan from "./SerPlan";
import SerNav from "./SerNav";

class StatsPage extends React.Component{

    constructor(props){
        super(props);
        this.state ={
            color: "white",
            bg: "rgb(20,20,20)"
        }
    }

    flip(){
        if(this.state.color == "white"){
            this.setState({
                color: "rgb(20,20,20)", bg:"white"
            })
        } else {
            this.setState({
                color: "white", bg:"rgb(20,20,20)"
            })
        }

    }

    render(){
        let x = {color:this.state.color, bg:this.state.bg}
        return <NewProjPage 
            func={this.flip.bind(this)}
            childs={
                [
                    {Title: "Overview", body:new Holder(<SerOver/>,<SerOver color={"white"} bg={"rgb(20,20,20)"}/>), childs:[
                        {Title: "Future Goals",body:new Holder(<SerPlan/>,<SerPlan color={"white"} bg={"rgb(20,20,20)"}/>), childs:[]},
                    ]},
                    {Title: "Specification", body:new Holder(<GameSpec/>,<GameSpec color={"white"} bg={"rgb(20,20,20)"}/>), childs:[]},
                    {Title: "Description", body:new Holder(<GameServer/>,<GameServer color={"white"} bg={"rgb(20,20,20)"}/>), childs:[
                        {Title: "Backend/Server", body:new Holder(<SerBack/>,<SerBack color={"white"} bg={"rgb(20,20,20)"}/>), childs:[
                            {Title: "Database", body:new Holder(<DBss/>,<DBss color={"white"} bg={"rgb(20,20,20)"}/>), childs:[]},
                            {Title: "Classes", body:new Holder(<Classs/>,<Classs color={"white"} bg={"rgb(20,20,20)"}/>), childs:[]},
                            {Title: "Routes", body:new Holder(<Routess/>,<Routess color={"white"} bg={"rgb(20,20,20)"}/>), childs:[]},
                        ]},
                        {Title: "Front End/Flutter", body:new Holder(<SerFront/>,<SerFront color={"white"} bg={"rgb(20,20,20)"}/>), childs:[
                            {Title: "Login Page", body:new Holder(<Lpage/>,<Lpage color={"white"} bg={"rgb(20,20,20)"}/>), childs:[]},
                            {Title: "Game Displays", body:new Holder(<GDis/>,<GDis color={"white"} bg={"rgb(20,20,20)"}/>), childs:[]},
                            {Title: "Game Pages", body:new Holder(<Gpage/>,<Gpage color={"white"} bg={"rgb(20,20,20)"}/>), childs:[]},
                        ]},
                        {Title: "Games", body:new Holder(<SerGList/>,<SerGList color={"white"} bg={"rgb(20,20,20)"}/>), childs:[]},
                    ]},
                    {Title: "Gallary", body:new Holder(<SerGal/>,<SerGal color={"white"} bg={"rgb(20,20,20)"}/>), childs:[]},
                    {Title: "Links", body:new Holder(<SerNav/>,<SerNav color={"white"} bg={"rgb(20,20,20)"}/>),childs:[]}
                ]
            }
        />
    }
} export default StatsPage
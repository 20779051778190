import React from "react";
import { Col, Row } from "react-bootstrap";
import HeadS from "../../Shared/Text/HeadS";
import TextB from "../../Shared/Text/textB";

class HonsGit extends React.Component{

    constructor(props){
        super(props);
        this.state ={
            color: props.color ? props.color: "black",
            bg: props.bg ? props.bg : "white",
        }
    }

    render(){
        let x = {color:this.state.color, background:this.state.bg}
        return <div style={{textAlign:"center"}}>
        <TextB color={this.state.color} bg={this.state.bg}>
            <HeadS color={this.state.color} bg={this.state.bg}> Project on <a style={x} href="https://github.com/NineSixFourteen/Hons/">Github</a></HeadS>
            <Row style={x}>
                <Col style={x}>
                    <HeadS color={this.state.color} bg={this.state.bg}> Parser</HeadS>
                        <a  style={x}  href="https://github.com/NineSixFourteen/Hons/tree/main/src/main/java/src/Tokens"> Tokens </a> <br/>
                        <a  style={x}  href="https://github.com/NineSixFourteen/Hons/blob/main/src/main/java/src/Parser/Validate.java"> Validator </a> <br/>
                        <a  style={x}  href="https://github.com/NineSixFourteen/Hons/blob/main/src/main/java/src/Parser/Parser.java"> Parser </a> <br/>
                </Col>
                <Col style={x}>
                    <HeadS color={this.state.color} bg={this.state.bg}> Compiler</HeadS>
                        <a  style={x} href="https://github.com/NineSixFourteen/Hons/blob/main/src/main/java/src/Compiler/Compiler.java"> Compiler </a> <br/>
                </Col>
                <Col style={x}>
                    <HeadS color={this.state.color} bg={this.state.bg}> Code Examples</HeadS>
                        <a  style={x} href="https://github.com/NineSixFourteen/Hons/tree/main/src/main/java/tests"> Test Suite </a> <br/>
                        <a  style={x} href="https://github.com/NineSixFourteen/Hons/tree/main/src/main/java/Benchmarks"> Test for benchmarks </a> <br/>
                </Col>
            </Row>
            </TextB>
        </div>
    }
} export default HonsGit
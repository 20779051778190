import React from "react";
import { Row } from "react-bootstrap";
import HeadC from "../../../../Shared/Text/HeadC";
import HeadB from "../../../../Shared/Text/HeadB";
import TextB from "../../../../Shared/Text/textB";
class PlayerD extends React.Component{

    constructor(props){
        super(props);
        this.state = {
            body: "",
            color: props.color ? props.color: "black",
            bg: props.bg ? props.bg : "white"
        } 
    }

    render(){
        let x = {color:this.state.color, background:this.state.bg}
        return <div style={x} className="mx-3">
            <HeadC color={this.state.color} bg={this.state.bg}> Player Data</HeadC>
            <TextB color={this.state.color} bg={this.state.bg}>
        The majority of the player data is extracted from a page like <a style={x} href="https://lol.fandom.com/wiki/2022_Season_World_Championship/Main_Event/Player_Statistics">this</a> , where there is a table with fields and rows for each entry, so the process is the same as champion.
        <br/>
        Some of the player data comes from the website rotowire, where we can get a JSON object for every player. From here, we get info such as team, first bloods, and position. 
        We get the data by
        <ul style={x}>
            <li style={x}> Parse the JSON String into to a JSON object</li>
            <li style={x}> Retrieve data we want by getting the field</li> 
        </ul> 
        <br/> 
        We also get data on champions played from LOL Fandom, but this time from a page like <a style={x} href="https://lol.fandom.com/wiki/2022_Season_World_Championship/Main_Event/Match_History">this</a>.
        <ul style={x}>
            <li style={x}> Find Every Matches Node </li>
            <li style={x}> Grab the particapnts list and the champions Played</li>
            <li style={x}> From this, we have a list of every champion a player has played and how many times they have played it</li>
        </ul>
        </TextB>
        </div>
    }
} export default PlayerD
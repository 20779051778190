import React from "react";
import {Col, Row } from "react-bootstrap";
import ImageViewer from 'react-simple-image-viewer';

import Home from "../../Shared/Photos/Stats/Home.png"

import Match1 from "../../Shared/Photos/Stats//Match1.png";
import Match2 from "../../Shared/Photos/Stats/Match2.png";
import Match3 from "../../Shared/Photos/Stats/Match3.png";
import Match5 from "../../Shared/Photos/Stats/match5.png";

import Player2 from "../../Shared/Photos/Stats/Player2.png";
import Player3 from "../../Shared/Photos/Stats/Player3.png";
import Player4 from "../../Shared/Photos/Stats/Player4.png";
import Player5 from "../../Shared/Photos/Stats/Player5.png";
import Player7 from "../../Shared/Photos/Stats/Player7.png";

import Overall1 from "../../Shared/Photos/Stats/overall1.png";
import Overall2 from "../../Shared/Photos/Stats/overall2.png";
import Overall3 from "../../Shared/Photos/Stats/Overall3.png";
import Overall4 from "../../Shared/Photos/Stats/overall4.png";

class StatsGal extends React.Component{

    constructor(props){
        super(props)
        this.state = {
            body: "",
            color: props.color ? props.color: "black",
            bg: props.bg ? props.bg : "white"
        }
    }

    onClose(){
        this.setState({body:""})
    }

    show(num){
        console.log(this.state.ind)
        let x =             
            <ImageViewer
                src={[
                    Home,
                    Match1,Match2,Match3,Match5,
                    Player2,Player3,Player4,Player5,Player7,
                    Overall1,Overall2,Overall3,Overall4
                ] }
                currentIndex={ num}
                disableScroll={ false }
                closeOnClickOutside={ true }
                onClose={ () => this.onClose()}
            />
        this.setState({body:x})
    }

    render(){
        let x = {color:this.state.color, background: this.state.bg}
        return <div style={x} className="mx-3">
            <Row style={x} className="py-3 px-3">
                <Col style={x} >
                    <div 
                    style={{height:"28vh", width:"20vw",
                        backgroundImage:  "url(" + Home + ")", 
                        backgroundRepeat: 'no-repeat',backgroundSize:"cover"}}
                        onClick={() => this.show(0)}
                        />
                </Col>
                <Col style={x}>
                    <div 
                    style={{height:"28vh", width:"20vw",
                        backgroundImage:  "url(" + Match1 + ")", 
                        backgroundRepeat: 'no-repeat',backgroundSize:"cover"}}
                        onClick={() => this.show(1)}
                        />
                </Col>
                <Col style={x}>
                    <div 
                    style={{height:"28vh", width:"20vw",
                        backgroundImage:  "url(" + Match2 + ")", 
                        backgroundRepeat: 'no-repeat',backgroundSize:"cover"}}
                        onClick={() => this.show(2)}
                        />
                </Col>
            </Row >
            <Row style={x} className="py-3 px-3">
                <Col style={x}>
                    <div 
                    style={{height:"40vh", width:"20vw",
                        backgroundImage:  "url(" + Match3 + ")", 
                        backgroundRepeat: 'no-repeat',backgroundSize:"cover"}}
                        onClick={() => this.show(3)}
                        />
                </Col>
                <Col style={x}>
                    <div 
                    style={{height:"40vh", width:"20vw",
                        backgroundImage:  "url(" + Match5 + ")", 
                        backgroundRepeat: 'no-repeat',backgroundSize:"cover"}}
                        onClick={() => this.show(4)}
                        />
                </Col>
                <Col style={x}>
                    <div 
                    style={{height:"40vh", width:"20vw",
                        backgroundImage:  "url(" + Player2 + ")", 
                        backgroundRepeat: 'no-repeat',backgroundSize:"cover"}}
                        onClick={() => this.show(5)}
                        />
                </Col>
            </Row>
            <Row style={x} className="py-3 px-3">
                <Col style={x}>
                    <div 
                    style={{height:"40vh", width:"20vw",
                        backgroundImage:  "url(" + Player3 + ")", 
                        backgroundRepeat: 'no-repeat',backgroundSize:"cover"}}
                        onClick={() => this.show(6)}
                        />
                </Col>
                <Col style={x}>
                    <div 
                    style={{height:"40vh", width:"20vw",
                        backgroundImage:  "url(" + Player4 + ")", 
                        backgroundRepeat: 'no-repeat',backgroundSize:"cover"}}
                        onClick={() => this.show(7)}
                        />
                </Col>
                <Col style={x}>
                    <div 
                    style={{height:"40vh", width:"20vw",
                        backgroundImage:  "url(" + Player5 + ")", 
                        backgroundRepeat: 'no-repeat',backgroundSize:"cover"}}
                        onClick={() => this.show(8)}
                        />
                </Col>
            </Row>
            <Row style={x} className="py-3 px-3">
                <Col style={x}>
                    <div 
                    style={{height:"40vh", width:"20vw",
                        backgroundImage:  "url(" + Player7 + ")", 
                        backgroundRepeat: 'no-repeat',backgroundSize:"cover"}}
                        onClick={() => this.show(9)}
                        />
                </Col>
                <Col style={x}>
                    <div 
                    style={{height:"40vh", width:"20vw",
                        backgroundImage:  "url(" + Overall1 + ")", 
                        backgroundRepeat: 'no-repeat',backgroundSize:"cover"}}
                        onClick={() => this.show(10)}
                        />
                </Col>
                <Col style={x}>
                    <div 
                    style={{height:"40vh", width:"20vw",
                        backgroundImage:  "url(" + Overall2 + ")", 
                        backgroundRepeat: 'no-repeat',backgroundSize:"cover"}}
                        onClick={() => this.show(11)}
                        />
                </Col>
            </Row>
            <Row style={x} className="py-3 px-3">
                <Col style={x}>
                    <div 
                    style={{height:"80vh", width:"30vw",
                        backgroundImage:  "url(" + Overall3 + ")", 
                        backgroundRepeat: 'no-repeat',backgroundSize:"cover"}}
                        onClick={() => this.show(12)}
                        />
                </Col>
                <Col style={x} >
                    <div 
                    style={{height:"40vh", width:"25vw",
                        backgroundImage:  "url(" + Overall4 + ")", 
                        backgroundRepeat: 'no-repeat',backgroundSize:"cover"}}
                        onClick={() => this.show(13)}
                        />
                </Col>
            </Row>
            <br/><br/><br/>
            {this.state.body}
        </div>
    }

} export default StatsGal 
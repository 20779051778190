import React from "react";
import HeadC from "../../Shared/Text/HeadC";
import HeadZ from "../../Shared/Text/HeadZ";
import TextB from "../../Shared/Text/textB";
import toyL from "../../Shared/Photos/Toy/TN.png"


class ToyOver extends React.Component{

    constructor(props){
        super(props);
        this.state ={
            color: props.color ? props.color: "black",
            bg: props.bg ? props.bg : "white"
        }
    }

    render(){
        return <div className="mx-3" style={{color:this.state.color, background:this.state.bg}}>
            <HeadZ color={this.state.color} bg={this.state.bg}> Toy Language</HeadZ>
            <div style={{display:"grid", justifyContent:"center", alignContent:"center", color:this.state.color, background:this.state.bg}}>
            <img src={toyL} style={{width:"30vw"}}/>
            </div>
            <HeadC color={this.state.color} bg={this.state.bg}> Project Goal </HeadC>
            <TextB color={this.state.color} bg={this.state.bg}>
            The goal of this project was to create a simple compiler and interperter to gain a better understanding of Programming language and to do this in rust to familiarize with rust.
            </TextB>
            <HeadC color={this.state.color} bg={this.state.bg}> Project Outcome </HeadC>
            <TextB color={this.state.color} bg={this.state.bg}>
            This project resulted in the creation of a simple compiler that successfully compiled to an instruction set that I created, 
            which could then be interpeter by the interperter that I built as part of the project.
            However, the language I created was really only useful for demonstrating compiler design and was not very efficient or feature-rich.
            </TextB>
        </div>
    }
} export default ToyOver
import React from "react";
import { Col, Row } from "react-bootstrap";
import { CodeBlock, tomorrowNightBlue, a11yLight, dracula} from "react-code-blocks";
import HeadB from "../../../Shared/Text/HeadB";
import HeadC from "../../../Shared/Text/HeadC";
import TextB from "../../../Shared/Text/textB";

class Features extends React.Component{

    constructor(props){
        super(props);
        this.state ={
            color: props.color ? props.color: "black",
            bg: props.bg ? props.bg : "white",
        }
    }


    render(){
        let x = {color:this.state.color, background: this.state.bg };
        return <div style={{x}}>
            <HeadC color={this.state.color} bg={this.state.bg}>
                Features
            </HeadC>
            <TextB color={this.state.color} bg={this.state.bg}>
            <Row style={x}>
            <Col style={x}>
                <HeadB color={this.state.color} bg={this.state.bg}> Common Features</HeadB>
                <ul style={x}>
                    <li style={x}> Basic Arthemtic +, -, /, *, %</li>
                    <li style={x}> Boolean Type: True, False</li>
                    <li style={x}> Boolean Logic: If, {"<, >, =<, =>, !, &&, || "}</li>
                    <li style={x}> 
                        Primitive Types:
                            <ul style={x}>
                                <li style={x}> Integer</li>
                                <li style={x}> Flaot</li>
                                <li style={x}> Char</li>
                                <li style={x}> Double</li>
                                <li style={x}> String</li>
                                <li style={x}> Arrays</li>
                            </ul>
                    </li>
                    <li style={x}> Access to all Java built-in classes, such as List</li>            
                </ul>
            </Col>
            <Col style={x}>
                <HeadB color={this.state.color} bg={this.state.bg}> Less Common Features</HeadB>
                <ul style={x}>
                    <li style={x}> ConstExpr allows you to define your own syntax.
                        <ul style={x}><li style={x}>See Pre Compiler for more info.</li></ul></li>
                    <li style={x}> Flat Array is a new built-in type of array.
                            <ul style={x}>
                                <li style={x}> Two versions one for int and one for float</li>
                                <li style={x}> Designed to speed up math problems that require arrays</li> 
                                    <ul style={x}>
                                        <li style={x}> Multi-dimensional arrays, such as a 2D array,
                                             are not stored in parallel in Java, which causes reading data in multi-dimensional arrays to be slow.
                                        </li>
                                        <li style={x}> Flat arrays are one big array that you can treat like a multi-dimensional array.</li>
                                        <li style={x}> is used the same as regular arrays but without the drop in speed that occurs when reading lots of data in multi-dimensional arrays. </li>
                                        <li style={x}> When declaring the array, place a * after the last {"]"}.</li>
                                        <li style={x}> Below is an example of using them</li>
                                    </ul>
                            </ul>
                    </li>
                </ul>
                <div style={{background:this.state.bg}}>
                <CodeBlock
                        text={
                            "public class vars {\n" + 
                            "   main(){\n" + 
                            "       int[][]* i = new int[2][2];\n" + 
                            "       i[0][1] = 10 ;\n" + 
                            "       Print i[0][1] ;\n" + 
                            "       double[][]* d = new double[2][3] ;\n" + 
                            "       d[0][2] = 1.4 ;\n" + 
                            "       Print d[0][2] ;\n" +
                            "       int[][][]* is = new int[4][10][2];\n" +
                            "       is[2][1][0] = 14 ;\n"  +
                            "       Print is[2][1][0] ;\n" +
                            "       double[][][]* ds = new double[3][2][6] ;\n" +
                            "       ds[1][1][1] = 4.4 ;\n" + 
                            "       Print ds[1][1][1] ;\n" +
                            "   }\n" + 
                            "}"
                            }
                        language={"Java"}
                        showLineNumbers={false}
                        theme={a11yLight}
                        wrapLines
                    />
                    </div><br/>
                    </Col>
                </Row>
            </TextB>
            </div>
    }

} export default Features
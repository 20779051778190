import React from "react";
import { Col, Row } from "react-bootstrap";
import HeadC from "../../../../Shared/Text/HeadC";
import HeadB from "../../../../Shared/Text/HeadB";
import TextB from "../../../../Shared/Text/textB";
class ChampT extends React.Component{

    constructor(props){
        super(props);
        this.state = {
            body: "",
            color: props.color ? props.color: "black",
            bg: props.bg ? props.bg : "white"
        } 
    }

    render(){
        let x = {color:this.state.color, background:this.state.bg}
        return <div style={x} className="mx-3">
            <HeadC color={this.state.color} bg={this.state.bg}> Champion Table</HeadC>
            <TextB color={this.state.color} bg={this.state.bg}>
            The champion data stores information about champions and their stats during the tournament.Its fields are
            <Row style={x}>
                <Col style={x}>
                    <ul style={x}>
                        <li style={x}> Presence</li>
                        <li style={x}> Wins</li>
                        <li style={x}> Average Kills</li>
                        <li style={x}> KDA</li>
                        <li style={x}> Gold</li>
                        <li style={x}> Kill Share </li>
                    </ul>
                </Col>
                <Col style={x}>
                    <ul style={x}>
                        <li style={x}> Banned</li>
                        <li style={x}> Losses</li>
                        <li style={x}> Average Deaths</li>
                        <li style={x}> Average CS</li>
                        <li style={x}> Gold per minute</li>
                        <li style={x}> Gold Share</li>
                    </ul>
                </Col>
                <Col style={x}>
                    <ul style={x}>
                        <li style={x}> GamesPlayed</li>
                        <li style={x}> WinRate</li>
                        <li style={x}> Average Assists</li>
                        <li style={x}> CS per minute</li>
                        <li style={x}> Kill particapnts</li>
                        <li style={x}> Postitions Played</li> 
                    </ul>
                </Col>
            </Row>
        </TextB>
        </div>
    }
} export default ChampT
  
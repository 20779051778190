import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Checklist from "../../Shared/Util/CheckList";
import HeadB from "../../Shared/Text/HeadB";
import HeadC from "../../Shared/Text/HeadC";
import HeadS from "../../Shared/Text/HeadS";
import Tags from "../../Shared/Util/Tags";
import TextB from "../../Shared/Text/textB";
import TagFactory from "../../Shared/Util/TagFactory";

class FunSpec extends React.Component{

    constructor(props){
        super(props)
        this.state = {
            body: "",
            color: props.color ? props.color: "black",
            bg: props.bg ? props.bg : "white"
        }
    }

    render(){
        let x = {color: this.state.color, background:this.state.bg}
        return <div style={x} className="mx-3">
                <TextB color={this.state.color} bg={this.state.bg} >
                    <HeadC color={this.state.color} bg={this.state.bg}> Specifiation</HeadC>
                    <HeadS color={this.state.color} bg={this.state.bg}> Final Goal</HeadS>
                    To build projects in Haskell and Agda.
                    <HeadS color={this.state.color} bg={this.state.bg}> Technoloogy Used</HeadS>
                </TextB> 
                <Row style={x}> 
                    <Col style={x} sm={2}><TextB color={this.state.color} bg={this.state.bg}> Project Type : </TextB></Col>
                    <Col style={x}><Row style={x}>{TagFactory.makeTags("t", "University").concat(TagFactory.makeTags("ts", "Solo"))} </Row> </Col>
                </Row>
                <Row style={x}> 
                    <Col style={x} sm={2}><TextB color={this.state.color} bg={this.state.bg}> Language : </TextB></Col>
                    <Col style={x}><Row style={x}>{TagFactory.makeTags("l", "Agda, Haskell")} </Row></Col>
                </Row>
                <Row style={x}> 
                    <Col style={x} sm={2}><TextB color={this.state.color} bg={this.state.bg}> Technoloogy used : </TextB></Col>
                    <Col><Row style={x}>{TagFactory.makeTags("to", "Git")} </Row></Col>
                </Row>
                    <TextB  color={this.state.color} bg={this.state.bg}>
                    <HeadS  color={this.state.color} bg={this.state.bg}> Goals</HeadS>
                    <Checklist 
                    name = "Project Goals"
                    items={
                        [
                            {done:true, message:"Build a simple text editor."},
                            {done:true, message:"Build a simple image libary."},
                            {done:true, message:"Build a database with a CLI."},
                            {done:true, message:"Build a simple image editor."},
                            {done:true, message:"Build a small programming language."},
                        ]
                    }/>
                    <br/>
                    <Checklist 
                    name = "Text Editor"
                    items={
                        [
                            {done:true, message:"Can move around a line of text using keys."},
                            {done:true, message:"Can Insert and delete letters."},
                        ]
                    }/>
                    <br/>
                    <Checklist 
                    name = "Image Libary"
                    items={
                        [
                            {done:true, message:"Can generate simple shapes."},
                            {done:true, message:"Can combine more complicated images using logic to interact with images."},
                        ]
                    }/><br/>
                    <Checklist 
                    name = "Database with CLI"
                    items={
                        [
                            {done:true, message:"Can read a CSV file and create a table of records."},
                            {done:true, message:"Make a query language for interacting with tables."},
                            {done:true, message:"Build the CLI to use query language and other features such as display options."},
                        ]
                    }/><br/>
                    <Checklist 
                    name = "Image Editor"
                    items={
                        [
                            {done:true, message:"Can read in images."},
                            {done:true, message:"Can perform transformations on images."},
                        ]
                    }/><br/>
                    <Checklist 
                    name = "Programming language"
                    items={
                        [
                            {done:true, message:"Can parse expressions into a tree structure."},
                            {done:true, message:"Can compile the expressions into instructions for an interpreter."},
                            {done:true, message:"Build an interpreter to run instructions."},
                        ]
                    }/><br/>
                </TextB>
        </div>
    }
} export default FunSpec 
import React from "react";
import Sections from "../../../../Shared/Util/Sections";
import TextB from "../../../../Shared/Text/textB";
import HeadC from "../../../../Shared/Text/HeadC";

class Classs extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            body: "",
            color: props.color ? props.color: "black",
            bg: props.bg ? props.bg : "white"
        } 
    }

    render(){
        let x = {color:this.state.color, background:this.state.bg}
        return <div style={x} className="mx-3">
        <HeadC color={this.state.color} bg={this.state.bg}> Classes </HeadC><TextB color={this.state.color} bg={this.state.bg}>
            The important Classes in the web api are 
            <HeadC color={this.state.color} bg={this.state.bg}> Game</HeadC>
            This is the class representation that is used in the database, it has no methods, and its fields are 
            <ul style={x}>
                <li style={x}> Id - The match ID</li>
                <li style={x}> GameType - The gamemode of the game</li>
                <li style={x}> State - The state of the game i.e. the board</li>
                <li style={x}> players - The players in the game</li>
                <li style={x}> playerAuths - The tokens the player uses to make the move</li>
                <li style={x}> turn - Whos turn it is</li>  
            </ul>
            <HeadC color={this.state.color} bg={this.state.bg}> PlayableGame</HeadC>
            This is the class that the webapi uses for "active games". These are games where players are currently playing.
            This is also the base class for each of the different games that can be played on the Web API. 
            Its only field is ID which is the match ID of the game, its methods are
            <ul style={x}>
                <li style={x}> fromGame - Load a instance of PlayableGame from an object of the Game class used for loading games from the database</li>
                <li style={x}> toGame - Convert the current object into a Game object so it can be given to the database to be stored </li>
                <li style={x}> makeMove - For when you want to make a move in the game you give the move and your auth/token</li>
                <li style={x}> isGameComplete - Returns a boolean of if the game has been completed and there are no more moves or a player has won</li> 
                <li style={x}> getWinner - Returns a number of which player one if it returns -1 the game is either not complete or it was a draw if isGameComplete is also true</li>
                <li style={x}> display - Prints the current game to console (used for debugging)</li>
                <li style={x}> getState - returns the string version of the games state </li>
            </ul>
            <HeadC color={this.state.color} bg={this.state.bg}> Board</HeadC>
            This is the class for sending information about loaded games. It has no methods, and its fields are
            <ul style={x}>
                <li style={x}> Type - The type of the game</li>
                <li style={x}> State - The string representation of the game</li>
                <li style={x}> Turn - Whos turn it is</li>
                <li style={x}> Winner - an int representation of who won -1 if no winner</li>
                <li style={x}> isGameDone - Bool if game is complete i.e. player has won or there are no more moves </li>  
                <li style={x}> players - an array with each player's name in it </li> 
                <li style={x}> photos - an array of the which photo each player is using</li>
            </ul>
        </TextB>
        </div>
    }
} export default Classs

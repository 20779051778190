import React from "react";
import Sections from "../../Shared/Util/Sections";
import TextB from "../../Shared/Text/textB";
import { Row} from "react-bootstrap";
import HeadC from "../../Shared/Text/HeadC";

class FunctionalP extends React.Component{

    constructor(props){
        super(props)
        this.state = {
            body: "",
            color: props.color ? props.color: "black",
            bg: props.bg ? props.bg : "white"
        }
    }

    render(){
        let x = {color: this.state.color, background:this.state.bg}
        return <div className="mx-3" style={x}>
                <Row style={x}>
                    <HeadC color={this.state.color} bg={this.state.bg}> Description</HeadC>
                </Row>
                <Row style={x}>
                    <TextB color={this.state.color} bg={this.state.bg}>
                    I did Functional Programming in my third year of university, where we used Haskell, and I did Advanced Functional Programming in my fourth year, where we used Agda. 
                    In Functional Programming, we learned the basics of functional programming in Haskell, where we learned how to use patterns such as maps, folds, and monads, as well as types such as maybe.
                    In Advanced Functional Programming, we dove more deeply into things such as category theory and how to incorporate proof into our code to ensure there were no errors.
                    During these classes, I built a number of projects to demonstrate the skills I learned. 
                    Details of these projects are below.
                    </TextB>
                </Row>
            </div>
    }

} export default FunctionalP



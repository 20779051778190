import React from "react";
import { Col, Row } from "react-bootstrap";
import HeadS from "../../Shared/Text/HeadS";
import TextB from "../../Shared/Text/textB";

class GamNav extends React.Component{

    constructor(props){
        super(props);
        this.state = {
            body: "",
            color: props.color ? props.color: "black",
            bg: props.bg ? props.bg : "white"
        } 
    }

    render(){
        let x = {color:this.state.color, background:this.state.bg}
        return <div style={{textAlign:"center"}}>
        <TextB color={this.state.color} bg={this.state.bg}>
            <HeadS color={this.state.color} bg={this.state.bg}> Project on <a style={x} href="https://github.com/NineSixFourteen/2048_gui_and_ai">Github</a></HeadS>
            <Row style={x}>
                <Col style={x}>
                    <HeadS color={this.state.color} bg={this.state.bg}> Game </HeadS>
                        <a style={x} href="https://github.com/NineSixFourteen/2048_gui_and_ai/blob/main/Board_class.py"> Board </a> <br/>
                        <a style={x} href="https://github.com/NineSixFourteen/2048_gui_and_ai/blob/main/Board_test.py"> Tests  </a> <br/>
                        <a style={x} href="https://github.com/NineSixFourteen/2048_gui_and_ai/blob/main/Player.py"> Player</a> <br/>
                    </Col>
                    <Col style={x}>
                        <HeadS color={this.state.color} bg={this.state.bg}> AI</HeadS>
                            <a style={x} href="https://github.com/NineSixFourteen/2048_gui_and_ai/blob/main/PlayerAI.py"> AI </a> <br/>

                    </Col>
                    <Col style={x}>
                    <HeadS color={this.state.color} bg={this.state.bg}> GUI </HeadS>
                        <a style={x} href="https://github.com/NineSixFourteen/2048_gui_and_ai/blob/main/2048BoardAIPLAY.py"> GUI </a> <br/>
                    </Col>
            </Row>
            </TextB>
        
        </div>
    }
} export default GamNav
import React from "react";
import { Row } from "react-bootstrap";
import HeadC from "../../../../Shared/Text/HeadC";
import HeadB from "../../../../Shared/Text/HeadB";
import TextB from "../../../../Shared/Text/textB";
import stats from "../../../../Shared/Photos/Discord/stat.png"
import kills from "../../../../Shared/Photos/Discord/kills.png"
import modal from "../../../../Shared/Photos/Discord/modal.png"

class Commands extends React.Component{

    constructor(props){
        super(props);
        this.state = {
            body: "",
            color: props.color ? props.color: "black",
            bg: props.bg ? props.bg : "white"
        } 
    }

    render(){
        let x = {color:this.state.color, background:this.state.bg}
        return <div className="mx-3" style={x}><TextB color={this.state.color} bg={this.state.bg}>
            <HeadB color={this.state.color} bg={this.state.bg}> Chat Commands</HeadB>
            The majority of the commands for the bot are about displaying information with the exceptions being the refresh and help commands.
            The only command that requires you to give parameters is the =champs command as this is intended to be used for quering the data on champions.
            The =champs command is used for querying see Query System. <br/>
            The other commands do not require paramaters ane they are
            <ul style={x}> 
                <li style={x}> =commands: Displays a list of buttons one for each command</li>
                <li style={x}> =help champs : Displays help for using =champs</li>
                <li style={x}> =stats   - Displays stats for champions</li>
                <li style={x}> =pstats  - Displays stats for players </li> 
                <li style={x}> =event   - Displays stats for Worlds 2022</li>
                <li style={x}> =kills   - Displays a list of highest kills</li>
                <li style={x}> =deaths  - Displays a list of highest deaths</li>
                <li style={x}> =wr      - Displays a list of highest winrates with {">"} 5 games</li> 
                <li style={x}> =picks   - Displays a list of most picked</li>
                <li style={x}> =bans    - Displays a list of most banned</li>
                <li style={x}> =pos     - Displays a list of postions played</li>
                <li style={x}> =pkills  - Displays player kills</li>
                <li style={x}> =pdeaths - Displays player deaths</li>
                <li style={x}> =pkda    - Displays player kda</li>
                <li style={x}> =pgp     - Displays games played</li>
                <li style={x}> =pchamp  - Displays champs players played</li>
                <li style={x}> =fb      - Displays players with fb</li>
                <li style={x}> =pentas  - Displays players with pentas</li>
                <li style={x}> =unique  - Displays number of unique champs</li>
            </ul>
            <div style={{textAlign:"center", color:this.state.color, background:this.state.bg}}>
            The Overview commands would apear like<br/><br/>
            </div>
            <img
            src={stats}
            width="60%"
            style={{marginLeft:"20%"}}
            alt="result"
            />
            <br/><br/>
            <div style={{textAlign:"center", color:this.state.color, background:this.state.bg}}>
            While the stats one like like<br/><br/>
            </div>
            <img
            src={kills}
            width="60%"
            style={{marginLeft:"20%"}}
            alt="result"
            />
            <br/><br/>

            <HeadB color={this.state.color} bg={this.state.bg}> Modal Commands</HeadB>
            The Modal Command "/champs" is the same as "=champs" however now you get a GUI for writing out your querys to help avoid making mistakes.
            <div style={{textAlign:"center", color:this.state.color, background:this.state.bg}}>
            The Modal look like <br/><br/>
            </div>
            <img
            src={modal}
            width="60%"
            style={{marginLeft:"20%"}}
            alt="result"
            />
            <br/><br/>
        </TextB>
        </div>
    }
} export default Commands

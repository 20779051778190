import React from "react";
import { Col, Row } from "react-bootstrap";
import HeadS from "../../Shared/Text/HeadS";
import TextB from "../../Shared/Text/textB";

class WebNav extends React.Component{

    constructor(props){
        super(props);
        this.state = {
            body: "",
            color: props.color ? props.color: "black",
            bg: props.bg ? props.bg : "white"
        } 
    }

    render(){
        let x = {color:this.state.color, background:this.state.bg}
        return <div style={{textAlign:"center"}}>
        <TextB color={this.state.color} bg={this.state.bg} >
            <HeadS color={this.state.color} bg={this.state.bg} > Project on <a style={x} href="https://github.com/NineSixFourteen/WebPage-Builder-CLI">Github</a></HeadS>
            <Row style={x}>
                <Col style={x}>
                    <HeadS color={this.state.color} bg={this.state.bg} > HTML</HeadS>
                        <a style={x} href="https://github.com/NineSixFourteen/WebPage-Builder-CLI/blob/main/s/src/main/java/s/s/HTML/ElementFactory.java"> Element Factory </a> <br/>
                        <a style={x} href="https://github.com/NineSixFourteen/WebPage-Builder-CLI/blob/main/s/src/main/java/s/s/HTML/PageMaker.java"> Page Maker  </a> <br/>
                    </Col>
                    <Col style={x}>
                        <HeadS color={this.state.color} bg={this.state.bg} > CSS</HeadS>
                            <a style={x} href="https://github.com/NineSixFourteen/WebPage-Builder-CLI/blob/main/s/src/main/java/s/s/CSS/StyleFactory.java"> Style Factory </a> <br/>
                            <a style={x} href="https://github.com/NineSixFourteen/WebPage-Builder-CLI/blob/main/s/src/main/java/s/s/CSS/CSSMaker.java"> Page Maker  </a> <br/>
                    </Col>
                    <Col style={x}>
                    <HeadS color={this.state.color} bg={this.state.bg} > JS </HeadS>
                        <a style={x} href="https://github.com/NineSixFourteen/WebPage-Builder-CLI/blob/main/s/src/main/java/s/s/JS/FunctionBuilder.java"> Function Builder </a> <br/>
                        <a style={x} href="https://github.com/NineSixFourteen/WebPage-Builder-CLI/tree/main/s/src/main/java/s/s/JS/Lines"> Lines</a> <br/>
                        <a style={x} href="https://github.com/NineSixFourteen/WebPage-Builder-CLI/tree/main/s/src/main/java/s/s/JS/Expression"> Expressions</a> <br/>
                        <a style={x} href="https://github.com/NineSixFourteen/WebPage-Builder-CLI/blob/main/s/src/main/java/s/s/JS/JSPageMaker.java"> Page Maker</a> <br/>
                    </Col>
                    <Col style={x}>
                    <HeadS color={this.state.color} bg={this.state.bg} > Interfaces </HeadS>
                        <a style={x} href="https://github.com/NineSixFourteen/WebPage-Builder-CLI/blob/main/s/src/main/java/s/s/Interface/MainInterface.java"> Main Interface </a> <br/>
                        <a style={x} href="https://github.com/NineSixFourteen/WebPage-Builder-CLI/tree/main/s/src/main/java/s/s/Interface/Html_Interfaces"> HTML Interfaces </a> <br/>
                        <a style={x} href="https://github.com/NineSixFourteen/WebPage-Builder-CLI/tree/main/s/src/main/java/s/s/Interface/CSS_Interfaces"> CSS Interfaces </a> <br/>
                        <a style={x} href="https://github.com/NineSixFourteen/WebPage-Builder-CLI/tree/main/s/src/main/java/s/s/Interface/JS_Interfaces"> JS Interfaces </a> <br/>
                    </Col>
            </Row>
            </TextB>
        
        </div>
    }
} export default WebNav
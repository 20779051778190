import React from "react";
import { Row,Col } from "react-bootstrap";
import Sections from "../../../../Shared/Util/Sections";
import HeadB from "../../../../Shared/Text/HeadB";
import HeadC from "../../../../Shared/Text/HeadC";
import TextB from "../../../../Shared/Text/textB";
import { CopyBlock, dracula} from "react-code-blocks";

class RPars extends React.Component{

    constructor(props){
        super(props);
        this.state = {
            body: "",
            color: props.color ? props.color: "black",
            bg: props.bg ? props.bg : "white"
        } 
    }

    render(){
        let x = {color:this.state.color, background: this.state.bg}

        return <div style={x} className="mx-3">
            <Row style={x}>
                <HeadC color={this.state.color} bg={this.state.bg}> Parser</HeadC>
            </Row>
            <Row style={x}>
                <HeadB color={this.state.color} bg={this.state.bg}> Description</HeadB>
            </Row>
            <Row style={x}>
                <TextB color={this.state.color} bg={this.state.bg}>
                The parser's job is to parse regular text in the lexar of the language into structured expressions, which are easier to work with inside of code.
                The output of the parser will be in the form of a program.
                The lexer of the languages is determined by the tokens we create when parsing.
                The parser also has to look for errors in the text and report them back to the user.
                </TextB>
            </Row>
        </div>
    }

} export default RPars



import React from "react";
import { Row, Col} from "react-bootstrap";
import HeadB from "../../../../Shared/Text/HeadB";
import HeadC from "../../../../Shared/Text/HeadC";
import TextB from "../../../../Shared/Text/textB";
class Errors extends React.Component{

    constructor(props){
        super(props);
        this.state = {
            body: "",
            color: props.color ? props.color: "black",
            bg: props.bg ? props.bg : "white"
        } 
    }

    render(){
        let x = {color:this.state.color, background: this.state.bg}

        return <div className="mx-3"><HeadC color={this.state.color} bg={this.state.bg}> Errors</HeadC><TextB color={this.state.color} bg={this.state.bg}>
            The parser also has to look for errors when it is parsing the source code. The errors that the parser can return are
            <ul style={x}>
                <li style={x}>Expecting but got -
                    <ul style={x}>
                        <li style={x}>This is when you expect a token but find the wrong one.</li>
                        <li style={x}>An example would be that after the "def" token, you expect the type of the function,
                            but say the user forgot and wrote the name of the function, so you throw this error and say "expect type"; found value ("main").</li>
                    </ul>  
                </li>
                <li style={x}>No closing bracket
                    <ul style={x}>
                        <li style={x}> This is when there is no closing bracket for an opening bracket </li>
                        <li style={x}> An example would be {"func(func(100)"}, where they haven't closed the first bracket.</li>
                    </ul>  
                </li>
                <li style={x}>Not valid parameter
                    <ul style={x}>
                        <li style={x}> This occurs when a function is called, its name is recognized, but the value supplied does not match the types.</li>
                        <li style={x}> An example would be def func(int a), and if the user tried to do func("hi"), you would throw this error.</li>
                    </ul>  
                </li>
                <li style={x}>CantFindToken
                    <ul style={x}>
                        <li style={x}> This happens when you are wanting to find all tokens before a certain token, but the parser can't find it </li>
                        <li style={x}> An example of when this would trigger is if the writer missed a semicolon.</li>
                    </ul>  
                </li>
                <li style={x}>No main function found
                <ul style={x}>
                        <li style={x}> This is not a function called main, and as such there is no declared starting point.</li>
                    </ul>  
                </li>
            </ul>
        </TextB>
        </div>
    }
} export default Errors
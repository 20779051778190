import React from "react";
import Sections from "../../Shared/Util/Sections";
import TextB from "../../Shared/Text/textB";
import HeadC from "../../Shared/Text/HeadC";
import { Container} from "react-bootstrap";


class BMS extends React.Component{

    constructor(props){
        super(props);
        this.state = {
            body: "",
            color: props.color ? props.color: "black",
            bg: props.bg ? props.bg : "white"
        } 
    }

    render(){
        let x = {color:this.state.color, background: this.state.bg}
        return <div className="mx-3">
                <HeadC color={this.state.color} bg={this.state.bg}> Description</HeadC>
                <TextB color={this.state.color} bg={this.state.bg}>
                    This project was done in my third year of university.
                    It was a group project where we were to create a system that could find the shortest path between any two points on the metro system.
                    As part of the project, we were to create a GUI and do extensive testing of the algorithm used.
                </TextB>
        </div>
    }

} export default BMS


import React from "react";

class TextB extends React.Component{

    constructor(props){
        super(props);
        this.state ={
            color: props.color ? props.color: "black",
            bg: props.bg ? props.bg : "white"
        }
    }

    render(){
        return <div style={{color:this.state.color, background: this.state.bg}}>
            <span style={{fontSize:"130%", fontWeight:"500", color:this.state.color, background: this.state.bg}}>
                {this.props.children}
            </span>
        </div>
    }

}export default TextB
import React from "react";
import { Col, Row } from "react-bootstrap";
import ChildLess from "./childlessNode";
import Navibar from "./NavBar";
import ProjNode from "./ProjNode";
import light from "../Photos/Light.png"
import dark from "../Photos/dark.png"
import { motion } from "framer-motion";

class NewProjPage extends React.Component{

    constructor(props){
        super(props);
        this.state = {
            body:"",
            temp:"",
            temp2:"",
            child: props.childs,
            colorMode: false,
            func: props.func, 
            vis: false,
            nav: <></>
        }
    }

    componentDidMount(){
        let x = new NavMenuFac(this.state.child,this.setBody.bind(this))
        this.setState({temp2: x, nav: x.get(this.state.colorMode)});
        console.log(this.state.child)
        this.setBody(this.state.child[0].body);
        this.setState()
    }

    setBody(x){
        console.log(x)
        this.setState({temp:x, body:x.get(this.state.colorMode)})
    }

    async swap(){
        this.state.func();
        let temp = !this.state.colorMode;
        this.setState({colorMode :temp,body:"",nav:""})
        await this.timeout(100);
        console.log(this.state.temp);
        if(this.state.temp != ""){
            this.setState({body: this.state.temp.get(temp), nav: this.state.temp2.get(temp)})
        }
    }

    timeout(delay) {
        return new Promise( res => setTimeout(res, delay) );
    }

    render(){
        const spring = {
            type: "spring",
            stiffness: 700,
            damping: 30
        };
        let x = window.screen.availHeight < window.screen.availWidth;
        return <>
            <Navibar/>
            <motion.div exit={{width:"0", transition:1.2, opacity:0}} style={{}}>
            <Row>
                <Col sm={x ? 3 : 12} className={"p-0 mx-3"} style={{background:"rgb(9, 1, 20)", position:"absoulte", justifyContent:"center", }}>
                <Row className="mx-3" style={{position:"relative", height:"8vh", background:"rgb(9, 1, 20)"}}>
                    <Row style={{position:"relative", justifyContent:"flex-end" , alignItems:"center", position:"relative", top:"10.3vh"}}>
                        <img src={light} style={{width: x ? "3vw" : "20vw", height:"4vh"}}/>
                        <div style={{background:"rgb(9, 1, 20)",height:"2vh",position:"static",color:"white", fontSize:"2.4vh", fontWeight:"800", width:x ? "2vw" : "15vw"}}>
                            <Row className="p-0"  onClick={() => {this.swap()}} style={{width:x ? "2vw" : "15vw", height:"2.2vh", padding:"0.2vh", borderRadius:"5vh", background:"rgba(255,255,255,0.5)", zIndex:1, justifyContent: this.state.colorMode ? "flex-start": "flex-end"}}>
                                <motion.div className="p-0" layout transition={spring} style={{ width:x ? "1vw" : "7vw", height:"2vh", borderRadius:"10vh", background:"white"}}/>
                            </Row>
                        </div>
                        <img src={dark}style={{width:x ? "3vw" : "20vw", height:"3vh"}}/>
                    </Row>
                </Row>;
                <div style={{height:"80vh", overflowY:"scroll", position:"relative", top:"9vh"}}>
                    {this.state.nav}
                </div>
            </Col>
                <Col sn={x ? 9 : 12}>
                    <div className="mx-2" style={{maxWidth: x ? "72vw" : "99vw", background:this.state.colorMode ? "white" : "rgb(20,20,20)",height:"100vh", maxHeight:"88vh", overflowY:"scroll",top:"10vh", position:"relative", border:"6px solid black"}}>
                        {this.state.body}
                    </div>
                </Col>
            </Row>
            </motion.div>
        </>
    }
} export default NewProjPage

class NavMenuFac {

    constructor(options, func){
        this.options = options;
        this.func = func;
    }

    get(bool){
        if(bool){
            return <NavMenu func={this.func} color={"rgb(20,20,50)"} bg={"rgb(230,230,255)"} options ={this.options}/>
        } else {
            return  <NavMenu func={this.func} color={"white"} bg={"rgb(20,20,20)"} options ={this.options}/>
        }
    }
}

class NavMenu extends React.Component{

    constructor(props){
        super(props);
        this.state ={
            body:"",
            options: props.options,
            func: props.func,
            color: props.color ? props.color : "white",
            bg: props.bg ? props.bg : "rgb(129, 212, 235)"
        }
    }

    makeProj(proj){
        if(proj.childs.length != 0){ // Has roots
            let x = []; 
            for(let i = 0; i < proj.childs.length;i++){
                x.push(this.makeProj(proj.childs[i]));
            }
            return <ProjNode Title={proj.Title} func={this.state.func} childs={x} color={this.state.color} bg={this.state.bg} body={proj.body} key={proj.Title}/>
        } else { // No Roots
            return <ChildLess Title={proj.Title} func={this.state.func} color={this.state.color} bg={this.state.bg} body={proj.body} key={proj.Title}/>
        }
    }

    render(){
        let x = [];
        for(let i = 0; i < this.state.options.length;i++){
            x.push(this.makeProj(this.state.options[i]));
        }
        return <Row>{x}</Row>;
    }
}
import React from "react";
import Sections from "../../../../Shared/Util/Sections";
import HeadB from "../../../../Shared/Text/HeadB";
import HeadC from "../../../../Shared/Text/HeadC";
import TextB from "../../../../Shared/Text/textB";
import { Container, Col, Row} from "react-bootstrap";


class DB extends React.Component{

    constructor(props){
        super(props);
        this.state = {
            body: "",
            color: props.color ? props.color: "black",
            bg: props.bg ? props.bg : "white"
        } 
    }
    render(){
        let x = {color:this.state.color, background:this.state.bg}
        return <div style={x} className="mx-3"><HeadC color={this.state.color} bg={this.state.bg}>
            DataBase  
            </HeadC>
            <HeadB color={this.state.color} bg={this.state.bg}>
                Overview
            </HeadB>
            <TextB color={this.state.color} bg={this.state.bg}>
                The database is required to store all of the information for the API in a way that
                <ul style={x}>
                    <li style={x}> will remain even after the program is stopped. </li>
                    <li style={x}> Is it easy to integrate with Spring Boot</li>
                    <li style={x}> The data is labelled </li>
                    <li style={x}> The data is easily queried.</li>
                </ul>
                So I choose to use a MySQL server, as this does all of the above. <br/>
                Information about each of the tables is below.
            </TextB>
            <HeadB color={this.state.color} bg={this.state.bg}>
                Tables
            </HeadB>
            </div>
    }
} export default DB

import React from "react";
import Sections from "../../../../Shared/Util/Sections";
import HeadB from "../../../../Shared/Text/HeadB";
import HeadC from "../../../../Shared/Text/HeadC";
import TextB from "../../../../Shared/Text/textB";

class BotInter extends React.Component{

    constructor(props){
        super(props);
        this.state = {
            body: "",
            color: props.color ? props.color: "black",
            bg: props.bg ? props.bg : "white"
        } 
    }

    render(){
        let x = {color:this.state.color, background:this.state.bg}
        return <div className="mx-3" style={x}>
            <HeadC color={this.state.color} bg={this.state.bg}> Discord Bot</HeadC>
            <HeadB color={this.state.color} bg={this.state.bg}> Overview</HeadB>
            <TextB color={this.state.color} bg={this.state.bg}>
                The Discord Bot was built using the <a style={x} href="https://github.com/DV8FromTheWorld/JDA">JDA</a> that 
                is a wrapper in java that lets you easily interact with Discord Rest Api. 
                The bot is front end of the project so it is the point the user will interact with. 
            </TextB>
        </div>
    }
} export default BotInter


import React from "react";
import Navibar from "./Shared/Util/NavBar";
import HeadA from "./Shared/Text/HeadA";
import HeadB from "./Shared/Text/HeadB";

class Unknown extends React.Component{

    render(){
        return <>
            <Navibar/>
            <HeadA> Unknown Page </HeadA> 
            <HeadB> There is no known webpage at this address</HeadB>
            <HeadB> Please use the Navigation bar at top of page for traversing the website</HeadB>
        </>
    }
} export default Unknown
import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Checklist from "../../Shared/Util/CheckList";
import HeadC from "../../Shared/Text/HeadC";
import HeadS from "../../Shared/Text/HeadS";
import Tags from "../../Shared/Util/Tags";
import TextB from "../../Shared/Text/textB";
import TagFactory from "../../Shared/Util/TagFactory";

class StackSpec extends React.Component{

    constructor(props){
        super(props);
        this.state = {
            body: "",
            color: props.color ? props.color: "black",
            bg: props.bg ? props.bg : "white"
        } 
    }

    render(){
        let x = {color:this.state.color, background: this.state.bg}
        return <div style={x} className="mx-3">
                <TextB color={this.state.color} bg={this.state.bg}>
                    <HeadC color={this.state.color} bg={this.state.bg}> Specifiation</HeadC>
                    <HeadS color={this.state.color} bg={this.state.bg}> Final Goal</HeadS>
                    To build a website to view statistics about League of Legends.
                    <HeadS color={this.state.color} bg={this.state.bg}> Technoloogy Used</HeadS>
                </TextB> 
                <Row style={x}> 
                    <Col style={x} sm={2}><TextB color={this.state.color} bg={this.state.bg}> Project Type : </TextB></Col>
                    <Col style={x}><Row style={x}>{TagFactory.makeTags("t", "Personal").concat(TagFactory.makeTags("ts", "Solo"))} </Row> </Col>
                </Row>
                <Row style={x}> 
                    <Col style={x} sm={2}><TextB color={this.state.color} bg={this.state.bg}> Language : </TextB></Col>
                    <Col style={x}><Row style={x}>{TagFactory.makeTags("l", "Java, JavaScript")} </Row></Col>
                </Row>
                <Row style={x}> 
                    <Col style={x} sm={2}><TextB color={this.state.color} bg={this.state.bg}> Technoloogy used : </TextB></Col>
                    <Col><Row style={x}>{TagFactory.makeTags("to","Git, React, Spring Boot, MySql")} </Row></Col>
                </Row>
                    <TextB color={this.state.color} bg={this.state.bg}>
                    <HeadS color={this.state.color} bg={this.state.bg}> Goals</HeadS>
                    <Checklist 
                    name = "Project Goals"
                    items={
                        [
                            {done:true, message:"Build a tool to fetch data from the Riot API."},
                            {done:true, message:"Create an API for interacting with the MySQL database." },
                            {done:true, message:"Build a React frontend."},
                        ]
                    }/>
                    <br/>
                    <Checklist 
                    name = "Fetcher"
                    items={
                        [
                            {done:true, message:"Get a list of a player's previous game's match ids."},
                            {done:true, message:"Retrieve all information about a match."},
                        ]
                    }/>
                    <br/>
                    <Checklist 
                    name = "API"
                    items={
                        [
                            {done:true, message:"Link to the database."},
                            {done:true, message:"Create the Add mappings."},
                            {done:true, message:"Create and delete mappings."},
                            {done:true, message:"Create the Get mappings."},
                        ]
                    }/><br/>
                    <Checklist 
                    name = "Website"
                    items={
                        [
                            {done:true, message:"Create a home page."},
                            {done:true, message:"Create a match page."},
                            {done:true, message:"Create a player page."},
                            {done:true, message:"Create an overall page."},
                            {done:true, message:"Create an admin page."},
                        ]
                    }/>
                    <br/>
                    <Checklist 
                    name = "Match Page"
                    items={
                        [
                            {done:true, message:"Show an overview of each player's stats"},
                            {done:true, message:"Create a section that lets you view player stats at different intervals."},
                            {done:true, message:"Create a leaderboard to compare players' stats."},
                        ]
                    }/>
                    <br/>
                    <Checklist 
                    name = "Player page"
                    items={
                        [
                            {done:true, message:"Show players' match history."},
                            {done:true, message:"Show players' stats on different champions."},
                            {done:true, message:"Show players' average stats in different positions and gamemodes."},
                            {done:true, message:"Display the average team stats and the average player stats for each player."},
                        ]
                    }/>
                    <br/>
                    <Checklist 
                    name = "Overall page"
                    items={
                        [
                            {done:true, message:"Show the total and average stats for each position and game mode."},
                            {done:true, message:"Allow the player to filter and average all records."},
                        ]
                    }/>
                    <br/>
                    <Checklist 
                    name = "Admin page"
                    items={
                        [
                            {done:true, message:"Direct access to the API."},
                        ]}/><br/>
                </TextB>
        </div>
    }
} export default StackSpec 
import React from "react";
import HeadC from "../../../Shared/Text/HeadC";
import TextB from "../../../Shared/Text/textB";

class WAMPlans extends React.Component{

    constructor(props){
        super(props);
        this.state ={
            color: props.color ? props.color: "black",
            bg: props.bg ? props.bg : "white"
        }
    }

    render(){
        return <div className="mx-3">
            <HeadC color={this.state.color} bg={this.state.bg}> Additional Goals</HeadC>
            <TextB color={this.state.color} bg={this.state.bg}>
                There are no additional goals are of the projects as they were built to the requested Specifiation of the projects. 
                However I do play to use some of the Technoloogy such as react native and better learn as my experience with it was only small projects.
            </TextB>
        </div>
    }
} export default WAMPlans
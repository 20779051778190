import React from "react";

import NewProjPage from "../../Shared/Util/newProjPage";
import Holder from "../../Shared/Util/Holder";
import AgdaP from "./Description/Agda";
import HaskellP from "./Description/Hask";
import FunSpec from "./FunSpec";
import FunctionalP from "./FunctiomalP";
import FunGal from "./FunGallry";
import FunOver from "./Description/FunOver";
import FunPlans from "./Description/FunPlans";

class FunPage extends React.Component{

    constructor(props){
        super(props);
        this.state ={
            color: "white",
            bg: "rgb(20,20,20)"
        }
    }

    flip(){
        if(this.state.color == "white"){
            this.setState({
                color: "rgb(20,20,20)", bg:"white"
            })
        } else {
            this.setState({ 
                color: "white", bg:"rgb(20,20,20)"
            })
        }

    }

    render(){
        return <NewProjPage 
            func={this.flip.bind(this)}
            childs={
                [
                    {Title: "Overview", body:new Holder(<FunOver/>,<FunOver color={"white"} bg={"rgb(20,20,20)"}/>), childs:[
                        {Title: "Future Goals", body:new Holder(<FunPlans/>,<FunPlans color={"white"} bg={"rgb(20,20,20)"}/>), childs:[]},
                    ]},
                    {Title: "Specification", body:new Holder(<FunSpec/>,<FunSpec color={"white"} bg={"rgb(20,20,20)"}/>), childs:[]},
                    {Title: "Description", body:new Holder(<FunctionalP/>,<FunctionalP color={"white"} bg={"rgb(20,20,20)"}/>), childs:[
                        {Title: "Haskell", body:new Holder(<HaskellP/>,<HaskellP color={"white"} bg={"rgb(20,20,20)"}/>), childs:[]},
                        {Title: "Agda", body:new Holder(<AgdaP/>,<AgdaP color={"white"} bg={"rgb(20,20,20)"}/>), childs:[]},
                    ]},
                    {Title: "Gallary", body:new Holder(<FunGal/>,<FunGal color={"white"} bg={"rgb(20,20,20)"}/>), childs:[]},
                ]
            }
        />
    }
} export default FunPage
import React from "react";
import { Col, Row } from "react-bootstrap";
import { CopyBlock, dracula } from "react-code-blocks";
import HeadB from "../../../../Shared/Text/HeadB";
import HeadC from "../../../../Shared/Text/HeadC";
import TextB from "../../../../Shared/Text/textB";

class FetchingInfo extends React.Component{

    constructor(props){
        super(props);
        this.state = {
            body: "",
            color: props.color ? props.color: "black",
            bg: props.bg ? props.bg : "white"
        } 
    }

    render(){
        let x = {color:this.state.color, background:this.state.bg}
        return <div className="mx-3" style={x}>
            <HeadC color={this.state.color} bg={this.state.bg}> Fetching Info</HeadC>
            <TextB color={this.state.color} bg={this.state.bg}>
            I retrieved the information from the website by sending http requests like the ones below.<br/>
            <br/><CopyBlock
            text={
                "String matchUrl = \"https://europe.api.riotgames.com/lol/match/v5/matches/\";\n" + 
                "String URL = matchUrl + matchID;\n" + 
                "final HttpURLConnection con = (HttpURLConnection) new URL(URL).openConnection();\n" + 
                "is = con.getInputStream();"
            }
            language={"Java"}
            showLineNumbers={false}
            theme={dracula}
            wrapLines
            /><br/>
            From this I got a string that contains the JSON data for the match, so I used the Jackson JSON library to convert the string into a 
            JSON node, and from there I can easily get the information I want from the node by getting fields like below <br/>
            <br/><CopyBlock
            text={
                "node.get(\"kills\").asInt() \n"+
                "node.get(\"deaths\").asInt() \n" + 
                "node.get(\"assists\").asInt() \n" 
            }
            language={"Java"}
            showLineNumbers={false}
            theme={dracula}
            wrapLines
            /><br/>
            This information is then given to the builders who will build objects that will be added to the tables in the MySQL database.
            <br/><br/>
            </TextB>
            </div>
    }
} export default FetchingInfo
import React from "react";
import TextB from "../../../../Shared/Text/textB";
import HeadB from "../../../../Shared/Text/HeadB";
import board from "../../../../Shared/Photos/2048/GUI.png"
import HeadC from "../../../../Shared/Text/HeadC";

class Pui extends React.Component{

    constructor(props){
        super(props);
        this.state = {
            body: "",
            color: props.color ? props.color: "black",
            bg: props.bg ? props.bg : "white"
        } 
    }
    

    render(){
        let x = {color:this.state.color, background:this.state.bg}
        return <div style={x} className="mx-3">
            <HeadC color={this.state.color} bg={this.state.bg}> Python GUI</HeadC>
            <TextB color={this.state.color} bg={this.state.bg}>
            The GUI's main sections are
            <ul style={x}> 
                <li style={x}> Game Board</li>
                <li style={x}> Score Board</li>
                <li style={x}> Screen Options</li>
                <li style={x}> Undo/Redo </li>
                <li style={x}> Controller </li> 
            </ul>
            <img 
                src={board}
                width="60%"
                style={{marginLeft:"20%"}}
                alt="Board"  
                /><br
            />
            <HeadB color={this.state.color} bg={this.state.bg}> Game Board</HeadB>
            The Game Board is in the centre of the screen; it shows the current position of all tiles on the board,
            with each tile having its score on it and the background colour getting darker the higher the score is.  <br/>
            The game board will automatically update itself after every move.
            <HeadB color={this.state.color} bg={this.state.bg}> Score Board</HeadB>
            The scoreboard in the bottom left corner keeps track of the players' current stats, such as their total score, merges, and biggest tile. <br/> 
            It also updates after every turn.
            <HeadB color={this.state.color} bg={this.state.bg}> Screen Options</HeadB>
            The screen options allow the user to control the size of the application and things such as text size. <br/>
            It has three buttons: one for each recommended screen size, as well as a slider and a resize button to allow the user to make the application any size they want.
            <HeadB color={this.state.color} bg={this.state.bg}> Undo/Redo</HeadB>
            The undo/redo section allows the user to undo or redo as many moves as they want. Both button groups have four buttons to undo or redo one, five, ten, or thirty times.
            <HeadB color={this.state.color} bg={this.state.bg}> Controller</HeadB>
            The controller sections provide onscreen buttons a user can quickly press to choose their move in the game, 
            or if they want, the arrow keys on the keyboard also work for choosing your move.
            <HeadB color={this.state.color} bg={this.state.bg}> AI Button</HeadB>
            There is a button that says "AI" next to the "resize" button; if the user clicks this button,
            the AI will start playing the game and make the moves it determines are best, and the user can watch it play with the Game Board.
            <br/><br/><br/>
        </TextB>
        </div>
       
    }

} export default Pui